<main class="position-relative w-100 h-100 px-xxl-5">

  <div class="row gx-5 justify-content-center justify-content-lg-between h-100 align-items-center px-2 px-lg-5">
    <div class="col-4 col-xl-5 background-text d-none d-lg-flex">
      <div class="d-flex flex-column justify-content-center">
        <h1 class="text-white fw-semibold">
            Fortalecemos las democracias de America Latina
        </h1>
        <h3 class="text-white fs-3">
          Directorio Legislativo is a civil society organization based in Argentina and the United States that has been working for more than ten years to strengthen democratic institutions in Latin America and the Caribbean.
        </h3>
      </div>
    </div>
    <mat-card class="col-12 col-lg-6 py-5 px-5 mx-lg-5 d-flex flex-column">
      <mat-card-header class="d-flex flex-column">
        <mat-card-title>
          <h1 class="fw-semibold fs-1 text-center pb-4" *ngIf="!submitted">
            Olvidé mi contraseña
          </h1>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="form" *ngIf="!submitted; else emailSent">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput type="text" name="user" formControlName="email">
          </mat-form-field>
          <div class="d-flex justify-content-center pb-2">
            <mat-error *ngIf="form.hasError('invalidCredentials')">
              Ocurrio un error al enviar el email
            </mat-error>
          </div>
          <div class="d-flex justify-content-center">
            <button mat-raised-button color="accent" class="w-100" (click)="submit()" [disabled]='form.invalid'>
              Enviar email
            </button>
          </div>
        </form>
        <ng-template #emailSent>
          <div class="d-flex flex-column align-items-center">
            <h1 class="fw-semibold fs-1 text-center pb-4">
              Email enviado
            </h1>
            <h3 class="text-center">
              Se ha enviado un email a la dirección ingresada con las instrucciones para recuperar su contraseña.
            </h3>
          </div>
        </ng-template>
      </mat-card-content>
    </mat-card>
  </div>


</main>
