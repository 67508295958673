<div class="container">
  <div class="row mb-3">
    <mat-form-field appearance="outline" class="col-12">
      <input
        matInput
        placeholder="Busca por palabra clave en número de expediente, título, país, descripción, síntesis o autores"
        [(ngModel)]="searchbar"
        #search
      />
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </div>
  <div class="row">
    <div class="col-lg-3 border-right">
      <div class="pb-3 position-relative">
        <div class="d-md-none filter-menu">
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            aria-label="Filter button"
          >
            <fa-icon [icon]="faFilter"></fa-icon>
          </button>
          <mat-menu #menu="matMenu">
            <div class="row">
              <app-search-filter
                [title]="'PAISES'"
                [options]="countries"
                (onFilter)="filterByCountries()"
                [mobile]="true"
                [loading]="loadingPaises"
              ></app-search-filter>
              <app-search-filter
                [title]="'TEMAS'"
                [options]="themes"
                (onFilter)="filterByThemes()"
                [mobile]="true"
                [loading]="loadingTemas"
              ></app-search-filter>
              <app-search-filter
                [title]="'AUTORES'"
                [options]="authors"
                (onFilter)="filterByAuthors()"
                [mobile]="true"
                [loading]="loadingAutores"
              ></app-search-filter>
            </div>
          </mat-menu>
        </div>
        <span class="info-title">
          <fa-icon [icon]="faFilter"></fa-icon>
          Filtros aplicados
        </span>
        <mat-chip-list #chipList class="row pt-2">
          <ng-container *ngFor="let country of countries">
            <mat-chip class="selected-filter" *ngIf="country.selected">
              {{ country.name }}
              <button
                matChipRemove
                (click)="country.selected = false; this.filterByCountries()"
              >
                <mat-icon> cancel </mat-icon>
              </button>
            </mat-chip>
          </ng-container>
          <ng-container *ngFor="let theme of themes">
            <mat-chip class="selected-filter" *ngIf="theme.selected">
              {{ theme.name }}
              <button
                matChipRemove
                (click)="theme.selected = false; this.filterByThemes()"
              >
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
          </ng-container>
          <ng-container *ngFor="let author of authors">
            <mat-chip class="selected-filter" *ngIf="author.selected">
              {{ author.name }}
              <button
                matChipRemove
                (click)="author.selected = false; this.filterByAuthors()"
              >
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
          </ng-container>
          <mat-chip class="selected-filter" *ngIf="probabilidad">
            PROBABILIDAD: {{ probabilidad }}
            <button
              matChipRemove
              (click)="probabilidad = undefined; this.filterByProbabilidad()"
            >
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
          <mat-chip class="selected-filter" *ngIf="impacto">
            IMPACTO: {{ impacto }}
            <button
              matChipRemove
              (click)="impacto = undefined; this.filterByImpacto()"
            >
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
        </mat-chip-list>
      </div>
      <div class="d-none d-md-block">
        <div class="filter-container pb-4">
          <h4 class="filter-title pb-3">ORDENAR POR</h4>
          <mat-radio-group
            aria-label="Select an option"
            (ngModelChange)="sort()"
            [(ngModel)]="orderBy"
          >
            <mat-radio-button value="date" class="info-content pb-2"
              >FECHA DE MODIFICACIÓN</mat-radio-button
            >
            <mat-radio-button value="relevance" class="info-content"
              >FECHA DE INGRESO</mat-radio-button
            >
          </mat-radio-group>
        </div>
        <div class="filter-container py-4">
          <h4 class="filter-title pb-3">Probabilidad</h4>
          <mat-radio-group
            aria-label="Select an option"
            (change)="filterByProbabilidad()"
            [(ngModel)]="probabilidad"
          >
            <mat-radio-button
              *ngFor="let item of levels"
              [value]="item"
              class="info-content pb-2"
              >{{ item }}</mat-radio-button
            >
          </mat-radio-group>
        </div>
        <div class="filter-container py-4">
          <h4 class="filter-title pb-3">Impacto</h4>
          <mat-radio-group
            aria-label="Select an option"
            (change)="filterByImpacto()"
            [(ngModel)]="impacto"
          >
            <mat-radio-button
              *ngFor="let item of levels"
              [value]="item"
              class="info-content pb-2"
              >{{ item }}</mat-radio-button
            >
          </mat-radio-group>
        </div>
        <app-search-filter
          [title]="'PAISES'"
          [options]="countries"
          (onFilter)="filterByCountries()"
          [loading]="loadingPaises"
        ></app-search-filter>
        <app-search-filter
          [title]="'TEMAS'"
          [options]="themes"
          (onFilter)="filterByThemes()"
          [loading]="loadingTemas"
        ></app-search-filter>
        <app-search-filter
          [title]="'AUTORES'"
          [options]="visibleAuthors"
          (onFilter)="filterByAuthors()"
          [loading]="loadingAutores"
        ></app-search-filter>
      </div>
    </div>
    <div class="col-lg-9">
      <app-regulation-list
        #regulationList="regulationList"
        [showAll]="true"
        [(queryParams)]="regulationsQueryParams"
        [defaultRegulation]="defaultRegulation"
      ></app-regulation-list>
    </div>
  </div>
</div>
