<div class="col-12 regulation">
  <div class="d-flex justify-content-between flex-column flex-md-row">
    <span class="date" *ngIf="regulation?.fechaIngreso">
      Presentado:
      {{ regulation.fechaIngreso | date }}
    </span>
    <span class="date" *ngIf="regulation.fechaUltimaModificacion">
      Actualizado:
      {{ regulation.fechaUltimaModificacion | date }}
    </span>
  </div>
    <h5 class="regulation-country">{{ regulation.pais }}</h5>
    <a
      [routerLink]="['/show', regulation.type, regulation.id ]"
      routerLinkActive="router-link-active" class="regulation-title">
        {{regulation.titulo}}
    </a>
    <div
    class="description"
    [innerHtml]="((regulation.sintesis || '').length > 250) ? (regulation.sintesis | slice:0:250)+'...' : (regulation.sintesis)"
    ></div>
    <div class="row">
        <span class="info-title" *ngIf="regulation.probabilidadSancion">
          Probabilidad:
          <span [class]="regulation.probabilidadSancion">
            {{ regulation.probabilidadSancion }}
          </span>
        </span>
        <span class="info-title" *ngIf="regulation.impacto">
          Impacto:
          <span [class]="regulation.impacto">
            {{ regulation.impacto }}
          </span>
        </span>
        <span class="info-title" *ngIf="regulation?.estado">
          Estado:
          <span style="color: rgb(130, 130, 130)">
            {{ regulation.estado }}
          </span>
        </span>
    </div>
</div>
