import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RegulationType } from 'src/app/core/enums/regulation-type.enum';
import { EntityQueryParams } from 'src/app/core/models/query-params.model';
import { Regulacion } from 'src/app/core/models/regulacion.model';
import { RegulationsService } from 'src/app/core/services/regulations.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  news: Regulacion[] = [];
  regulationsQueryParams: EntityQueryParams = new EntityQueryParams();
  route: string = '';

  constructor(
    private regulationsService: RegulationsService,
    private router: Router
  ) {
    this.regulationsQueryParams.types = [
      RegulationType.PROYECTOS,
      RegulationType.LEYES,
      RegulationType.NORMATIVAS,
    ];
    this.route === '/csg' ? (this.regulationsQueryParams.csg = true) : null;
    this.regulationsQueryParams.probabilidadSancion = 1;
    this.regulationsQueryParams.impacto = 1;
  }

  ngOnInit(): void {
    this.route = this.router.url;
    const queryParams = new EntityQueryParams();
    queryParams.types = [RegulationType.NOTAS];
    queryParams.pageSize = 3;
    this.route === '/csg' ? (queryParams.csg = true) : null;
    this.regulationsService.fetchAll(queryParams).subscribe((regulaciones) => {
      this.news = regulaciones.items;
    });
  }
}
