<div>
    <div class="row mb-2">
      <ng-template #lastContent>
        <div class="col-12 pb-2">
          <button mat-button class="regulation-button" [ngClass]="checkIfActive()" (click)="changeRegulation()">Lo Ultimo</button>
        </div>
      </ng-template>
      <div *ngIf="showAll; else lastContent" class="col-lg-6 pb-2" [ngClass]="checkIfShowAll()">
          <button mat-button class="regulation-button" [ngClass]="checkIfActive()" (click)="changeRegulation()">Todos</button>
      </div>
      <div class="col-lg-6 pb-2" [ngClass]="checkIfShowAll()">
          <button mat-button class="regulation-button" [ngClass]="checkIfActive(0)" (click)="changeRegulation(0)">Anteproyectos</button>
      </div>
      <div class="col-lg-6 pb-2" [ngClass]="checkIfShowAll()">
          <button mat-button class="regulation-button" [ngClass]="checkIfActive(1)" (click)="changeRegulation(1)">Proyectos</button>
      </div>
      <div class="col-lg-6 pb-2" [ngClass]="checkIfShowAll()">
          <button mat-button class="regulation-button" [ngClass]="checkIfActive(3)" (click)="changeRegulation(3)">Leyes</button>
      </div>
      <div class="col-lg-6 pb-2" [ngClass]="checkIfShowAll()">
          <button mat-button class="regulation-button" [ngClass]="checkIfActive(2)" (click)="changeRegulation(2)">Normativas</button>
      </div>
      <div *ngIf="showAll" class="col-lg-6 pb-2" [ngClass]="checkIfShowAll()">
          <button mat-button class="regulation-button" [ngClass]="checkIfActive(4)" (click)="changeRegulation(4)">Notas</button>
      </div>
    </div>
    <div class="row mb-4" *ngIf="visibleSubtypes.length > 0">
        <mat-chip-list #chipList>
            <mat-chip class="sub-regulation" [ngClass]="checkIfActiveSubtype()" (click)="changeRegulationSubtype()">
                Todos
            </mat-chip>
            <mat-chip class="sub-regulation" *ngFor="let subtype of visibleSubtypes" [ngClass]="checkIfActiveSubtype(subtype)" (click)="changeRegulationSubtype(subtype)">
                {{ subtype.title }}
            </mat-chip>
        </mat-chip-list>
    </div>
    <ng-container *ngIf="loading; else loaded">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </ng-container>
    <ng-template #loaded>
      <div class="pt-3 row">
        <h4 class="found-results">
          Se encontraron {{ paginatorLength }} resultados
          {{ queryParams.title ? 'para "' + queryParams.title + '"' : '' }}
        </h4>
      </div>
      <div class="row regulation-row pt-3" *ngFor="let regulation of regulations">
        <app-regulation [regulation]="regulation"></app-regulation>
        <hr>
      </div>
      <mat-paginator *ngIf="showAll; else seeAll"
        #paginator
        hidePageSize="true"
        [length]="paginatorLength"
        [pageSize]="queryParams.pageSize"
        [pageIndex]="queryParams.pageNumber - 1"
        (page)="changePage($event)">
      </mat-paginator>
      <ng-template #seeAll>
        <div class="row">
          <div class="col-12">
            <button mat-button class="regulation-button" (click)="changeRegulation()" [routerLink]="['/search']">Ver todos los documentos</button>
          </div>
        </div>
      </ng-template>
    </ng-template>
</div>
