import { Component, Input, OnInit } from '@angular/core';
import { Regulacion } from 'src/app/core/models/regulacion.model';

@Component({
  selector: 'app-regulation',
  templateUrl: './regulation.component.html',
  styleUrls: ['./regulation.component.scss']
})
export class RegulationComponent implements OnInit {
  constructor() { }


  @Input() regulation: Regulacion;

  ngOnInit(): void {
  }

}
