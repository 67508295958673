import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PreviousUrlService {
  private previousUrl: string = '/dashboard';

  constructor(private router: Router) {
    if (!this.isAuthPage(this.router.url)) {
      this.previousUrl = this.router.url;
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (!this.isAuthPage(event.url))
          this.previousUrl = event.url;
      }
    });
  }

  getPreviousUrl(): string {
    return this.previousUrl;
  }

  private isAuthPage(url: string): boolean {
    return url === '/login' || url === '/register' || url === '/forgot-password' || url === '/reset-password';
  }
}
