<mat-sidenav-container>
  <mat-sidenav #sidenav position="end" class="py-5 px-2 px-sm-5">
    <div class="row pb-5">
      <div class="col">
        <div class="row justify-content-start social-header gap-3">
          <a href="https://directoriolegislativo.org/" target="_blank">
            <button mat-icon-button>
              <fa-icon [icon]="faLink"></fa-icon>
            </button>
          </a>
          <a
          href="https://x.com/DireLegislativo"
          target="_blank"
          >
            <button mat-icon-button>
              <fa-icon [icon]="faXTwitter"></fa-icon>
            </button>
          </a>
          <a
          href="https://www.linkedin.com/company/fundaci-n-directorio-legislativo/"
          target="_blank"
          >
            <button mat-icon-button>
                <fa-icon [icon]="faLinkedinIn"></fa-icon>
            </button>
          </a>
          <a href="https://www.instagram.com/direlegislativo/" target="_blank">
            <button mat-icon-button>
              <fa-icon [icon]="faInstagram"></fa-icon>
            </button>
          </a>
          <a
            href="https://www.tiktok.com/@directoriolegislativo"
            target="_blank"
          >
            <button mat-icon-button>
              <fa-icon [icon]="faTikTok"></fa-icon>
            </button>
          </a>
        </div>
      </div>
      <div class="col-3 d-flex justify-content-end">
        <button mat-icon-button (click)="sidenav.toggle()" class="close-button">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>

    <mat-nav-list>
      <a mat-list-item (click)="navigate('/')"> Inicio </a>
      <a mat-list-item (click)="navigate('/search', { regulationType: '0' })">
        Anteproyectos
      </a>
      <!-- <ul>
            <a mat-list-item [routerLink]="['/search', { regulationType: 1 }]"> - Del Poder Ejecutivo </a>
            <a mat-list-item [routerLink]="['/search', { regulationType: 1 }]"> - Del Poder Legislativo </a>
        </ul> -->

      <a mat-list-item (click)="navigate('/search', { regulationType: '1' })">
        Proyectos
      </a>
      <!-- <ul>
            <a mat-list-item [routerLink]="'/accounts'"> - En discusion </a>
            <a mat-list-item [routerLink]="'/accounts'"> - Sancionados </a>
            <a mat-list-item [routerLink]="'/accounts'"> - Archivados </a>
        </ul> -->
      <a mat-list-item (click)="navigate('/search', { regulationType: '3' })">
        Leyes
      </a>
      <a mat-list-item (click)="navigate('/search', { regulationType: '2' })">
        Normativas
      </a>
      <a mat-list-item (click)="navigate('/search', { regulationType: '4' })">
        Notas
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar class="navbar">
      <mat-toolbar-row class="container">
        <div class="row w-100 h-100 align-items-center justify-content-between">
          <div
            class="col-md-6 d-flex h-100 justify-content-md-start justify-content-between px-2 px-md-0"
          >
            <img
              [routerLink]="''"
              src="../../../assets/images/logo_dashboard.png"
              class="logo"
              alt="logo"
            />
            <!-- <img
              *ngIf="route === '/csg'"
              src="../../../assets/images/cv-logo.png"
              class="logo logo-client mx-md-5"
            /> -->
            <a
              href="http://www.taplat.org/"
              target="_blank"
              *ngIf="user?.clienteId === 59"
              ><img
                src="../../../assets/images/tap_logo.png"
                class="logo logo-client mx-md-5"
            /></a>
          </div>
          <div
            class="col-md-6 d-flex align-items-center justify-content-center justify-content-md-end header-buttons"
          >
            <!-- <mat-form-field class="searchbar" appearance="outline" [@searchbarVisible]="isSearchbarVisible ? 'open' : 'closed' ">
                        <mat-label>Buscar</mat-label>
                        <input matInput type="text" name="search" [(ngModel)]="searchbar">
                    </mat-form-field>

                    <button mat-icon-button class="search-button" (click)="toggleSearchbar()">
                        <mat-icon>search</mat-icon>
                    </button> -->

            <ng-container *ngIf="user.id">
              <button mat-icon-button [matMenuTriggerFor]="accountMenu">
                <img src="../../../assets/images/avatar.png" class="avatar" />
                <mat-menu #accountMenu="matMenu">
                  <button mat-menu-item [routerLink]="['/user']">
                    {{ user?.firstName }} {{ user?.lastName }}
                  </button>
                  <button mat-menu-item (click)="logout()">Cerrar sesion</button>
                </mat-menu>
              </button>
            </ng-container>
            <button mat-icon-button class="sidenav-button">
              <mat-icon (click)="sidenav.toggle()">menu</mat-icon>
            </button>
          </div>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
    <main style="min-height: calc(100vh - 152px)">
      <router-outlet></router-outlet>
    </main>
    <app-footer></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
