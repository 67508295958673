import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'src/app/core/services/login.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {

  submitted = false;
  token = '';
  form = this.formBuilder.group({
    //  La contraseña debe tener al menos una mayúscula, una minúscula y un número
    password: ['', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]/)]],
    repeatPassword: [''],
  })

  constructor(
    private loginService: LoginService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.route.params.subscribe((params) => {
      this.token = params.token;
    })
  }

  submit() {
    if (this.form.invalid) {
      return
    }
    if (this.form.value.password !== this.form.value.repeatPassword) {
      this.form.get('repeatPassword')?.setErrors({
        notEqual: true
      });
      return
    }
    this.loginService.resetPassword(this.token, this.form.value.password!).subscribe(() => {
      this.router.navigate(['/login']);
    }, (error) => {
      this.form.setErrors({
        invalidCredentials: true
      })
    })
  }

}
