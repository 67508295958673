import { ApiService } from "./api.service"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { Country } from '../models/country.model'
import { Theme } from '../models/theme.model'
import { Author } from '../models/author.model'

@Injectable({
    providedIn: "root"
})
export class FiltersService{
    constructor(private apiService: ApiService) { }

    private themesUrl = "Temas/"
    private autoresUrl = "Autores/"
    private paisesUrl = "Paises/"


    fetchThemes(): Observable<Theme[]> {
        return this.apiService.get(this.themesUrl)
    }

    fetchCountries(): Observable<Country[]> {
        return this.apiService.get(this.paisesUrl)
    }

    fetchCountry(id: string): Observable<any> {
        return this.apiService.get(this.paisesUrl + id)
    }

    fetchAuthors(): Observable<Author[]> {
        return this.apiService.get(this.autoresUrl)
    }

}
