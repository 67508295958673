import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/core/services/api.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
  availableCountries = [1, 3, 4, 5, 8, 15, 19, 25];
  route: string = '';
  constructor(private router: Router, private apiService: ApiService) {
    this.route = this.router.url;
  }

  ngOnInit(): void {
    const user = JSON.parse(localStorage.getItem('user')!);

    if (
      user &&
      !user.projects.some((proj: any) => proj.projectId == 1 && proj.role == 1)
    ) {
      this.apiService.get('Usuarios/' + user?.userId).subscribe((res: any) => {
        const servicioDashboard = res.serviciosContratados.find(
          (servicio: any) => servicio.servicioId === 2
        );
        this.availableCountries = servicioDashboard.paises.map(
          (country: any) => country.id
        );
      });
    }
  }

  redirectToSearch(countryId: number) {
    let searchRoute = '';
    if (this.router.url === '/csg') {
      searchRoute = 'csg/search'
    } else {
      searchRoute = '/search';
    }
    if (this.availableCountries.includes(countryId)) {
      this.router.navigate([searchRoute], {
        queryParams: { country: [countryId] },
      });
    }
  }

  isAvailable(countryId: number) {
    if (this.availableCountries.includes(countryId)) {
      return 'available';
    } else return '';
  }
}
