import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router) { }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const access_token = localStorage.getItem("access_token");
    if (access_token) {
      const cloned = req.clone({
        headers: req.headers.set("Authorization", "Bearer " + access_token)
      });
      return next.handle(cloned).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("user");
            localStorage.removeItem("access_token");
            this.router.navigate(["/login"]);
          }
          return throwError(error);
        })
      );
    }
    else return next.handle(req);
  }
}
