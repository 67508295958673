<div class="news-color-wrapper">
    <div class="container pt-3">
        <section class="row news-section">
            <ng-container *ngIf="related; else defaultTitle">
                <h2>Notas relacionadas</h2>
            </ng-container>
            <ng-template #defaultTitle>
                <h2>Notas</h2>
            </ng-template>
            <div class="row">
                <div class="col-md-6 col-lg-4" *ngFor="let news of visibleNews">
                    <a [routerLink]="['/show', newsType, news.id]" class="news-link">
                      <div class="news-container">
                        <div class="news-content">
                            <span *ngIf="news.fechaIngreso">{{ news.fechaIngreso | date }}</span>
                            <h4>{{ news?.pais }}</h4>
                            <h3>{{ news?.titulo }}</h3>
                            <p [innerHTML]="news?.sintesis"></p>
                        </div>
                        <!-- <div class="news-image">
                            <img src="https://wallpaperaccess.com/full/474873.jpg" alt="">
                        </div> -->
                      </div>
                    </a>

                </div>
            </div>
        </section>
        <div class="col-md-2 pb-2" *ngIf="!related">
            <button mat-button class="more-news-button" [routerLink]="['/search']" [queryParams]="{ regulationType: 4 }" routerLinkActive="router-link-active" >Más Notas</button>
        </div>
    </div>
</div>
