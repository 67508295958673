import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { anteproyectosStates, proyectosStates, SubtypeWhitelist } from 'src/app/core/data/state-whitelist';
import { RegulationType } from 'src/app/core/enums/regulation-type.enum';
import { EntityQueryParams } from 'src/app/core/models/query-params.model';
import { Regulacion } from 'src/app/core/models/regulacion.model';
import { Subtype } from 'src/app/core/models/subtype.model';
import { RegulationsService } from 'src/app/core/services/regulations.service';

@Component({
  selector: 'app-regulation-list',
  templateUrl: './regulation-list.component.html',
  styleUrls: ['./regulation-list.component.scss'],
  exportAs: 'regulationList',
})
export class RegulationListComponent implements OnChanges {
  @Input() showAll = false;
  @Input() queryParams: EntityQueryParams;
  @Input() defaultRegulation?: RegulationType;
  @Output() queryParamsChange = new EventEmitter<EntityQueryParams>();
  subtypes: Subtype[] = [];
  visibleSubtypes: SubtypeWhitelist[] = [];
  regulations: Regulacion[] = [];
  loading = false;

  @ViewChild('paginator') paginator: MatPaginator | undefined;
  paginatorLength = 0;

  constructor(
    private regulationsService: RegulationsService,
    private router: Router
  ) {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes.queryParams) {
      this.changeRegulation(this.defaultRegulation!);
    }
  }

  changeRegulation(position?: number) {
    if (position === undefined) {
      this.queryParams.types = [
        RegulationType.PROYECTOS,
        RegulationType.LEYES,
        RegulationType.NORMATIVAS,
        RegulationType.ANTEPROYECTOS,
      ];
      if (this.showAll) this.queryParams.types.push(RegulationType.NOTAS);
    } else {
      if (
        this.queryParams.types.includes(position) &&
        this.queryParams.types.length === 1
      )
        return;
      this.queryParams.types = [position];
    }
    if (this.router.url === '/csg') {
      this.queryParams.csg = true;
    }
    this.queryParams.subtypes = [];
    this.visibleSubtypes = [];
    this.loadRegulations();
  }

  changeRegulationSubtype(subtype?: SubtypeWhitelist) {
    if (!subtype) {
      this.queryParams.subtypes = [];
    } else {
      const currentSubtypes = this.subtypes.filter((s) => subtype.values.includes(s.descripcionEstado));
      const currentSubtypesIds = currentSubtypes.map((s) => s.id);
      if (this.queryParams.subtypes !== currentSubtypesIds) {
      this.queryParams.subtypes = currentSubtypesIds;
      }
    }
    if (this.router.url === '/csg') {
      this.queryParams.csg = true;
    }
    this.loadRegulations();
  }

  changePage($event: PageEvent) {
    this.queryParams.pageNumber = $event.pageIndex + 1;
    if (this.router.url === '/csg') {
      this.queryParams.csg = true;
    }
    this.loadRegulations(false);
  }

  public loadRegulations(pageReset = true) {
    if (pageReset) this.paginator?.firstPage();
    if (this.selectedRegulation === RegulationType.ANTEPROYECTOS || this.selectedRegulation === RegulationType.PROYECTOS) {
      this.regulationsService
        .fetchEstadosTipos(this.selectedRegulation)
        .subscribe((subtypes) => {
          if (this.selectedRegulation === RegulationType.PROYECTOS) {
            this.subtypes = subtypes.filter((subtype) => proyectosStates.filter((state) => state.values.includes(subtype.descripcionEstado)).length > 0);
            this.visibleSubtypes = proyectosStates.filter((state) => this.subtypes.filter((subtype) => state.values.includes(subtype.descripcionEstado)).length > 0);
          }
          if (this.selectedRegulation === RegulationType.ANTEPROYECTOS) {
            this.subtypes = subtypes.filter((subtype) => anteproyectosStates.filter((state) => state.values.includes(subtype.descripcionEstado)).length > 0);
            this.visibleSubtypes = anteproyectosStates.filter((state) => this.subtypes.filter((subtype) => state.values.includes(subtype.descripcionEstado)).length > 0);
          }
        });
    } else {
      this.subtypes = [];
    }
    this.loading = true;
    const currentQueryParams = this.queryParams;
    this.regulationsService
      .fetchAll(this.queryParams)
      .subscribe((regulations) => {
        if (currentQueryParams !== this.queryParams) return;
        if (this.selectedRegulation === undefined) {
          this.regulations = regulations.items.sort(
            (a, b) =>
              new Date(b.fechaUltimaModificacion ?? b.fechaIngreso).getTime() -
              new Date(a.fechaUltimaModificacion ?? a.fechaIngreso).getTime()
          );
          this.regulations = this.regulations.slice(
            0,
            this.queryParams.pageSize
          );
        } else {
          this.regulations = regulations.items;
        }
        this.paginatorLength = regulations.totalCount;
        this.loading = false;
      });
  }

  checkIfShowAll() {
    return this.showAll ? 'col-md-4 col-xl-4' : 'col-md-3 col-xl-3';
  }

  checkIfActive(position?: number) {
    return this.selectedRegulation == position ? 'active' : '';
  }

  checkIfActiveSubtype(subtype?: SubtypeWhitelist) {
    if (!this.queryParams.subtypes || !subtype) return '';
    const currentSubtype = this.subtypes.find((s) => subtype.values.includes(s.descripcionEstado));
    if (!currentSubtype) return '';
    return this.queryParams.subtypes?.includes(currentSubtype.id) ? 'active' : '';
  }

  get selectedRegulation() {
    return this.queryParams.types.length == 1
      ? this.queryParams.types[0]
      : undefined;
  }
}
