import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';


@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
})
export class SearchFilterComponent implements OnChanges {

  @Input()
  mobile: boolean;

  @Input()
  title: string;

  @Input()
  loading: boolean;

  @Input()
  options: { id: number; name: string; selected: boolean; }[] = [];

  @Output()
  optionsChange = new EventEmitter<{ id: number; name: string; selected: boolean; }[]>();

  @Input()
  searchbar: string = '';

  @Output()
  searchbarChange = new EventEmitter<string>();

  @Output()
  onFilter = new EventEmitter<void>();

  filteredOptions: { id: number; name: string; selected: boolean; }[] = [];

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.filteredOptions = this.options.slice(0, 25);
    }

  }

  filter() {
    this.onFilter.emit();
  }

  searchbarChanged() {
    const matches = this.options.filter(option => option.name.toLowerCase().includes(this.searchbar.toLowerCase()))
    this.filteredOptions = matches.slice(0, 25);
  }

}
