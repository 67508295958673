import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RegulationType } from 'src/app/core/enums/regulation-type.enum';
import { Client } from 'src/app/core/models/client.model';
import { Regulacion } from 'src/app/core/models/regulacion.model';
import { Theme } from 'src/app/core/models/theme.model';
import { ApiService } from 'src/app/core/services/api.service';
import { MovementsService } from 'src/app/core/services/movements.service';
import { RegulationsService } from 'src/app/core/services/regulations.service';

@Component({
  selector: 'app-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.scss'],
})
export class ShowComponent implements OnInit {
  regulation: Regulacion;
  movements: any;
  client: Client;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private regulationsService: RegulationsService,
    private apiService: ApiService,
    private movementsService: MovementsService
  ) {}

  ngOnInit(): void {
    const stored_user = localStorage.getItem('user');
    if (stored_user) {
      const user = JSON.parse(stored_user);
      this.apiService
        .get('Clientes/' + user?.clienteId)
        .subscribe((res: any) => {
          this.client = res;
        });
    }
    this.route.params.subscribe((params) => {
      const type = params.type as RegulationType;
      const id = params.id as string;

      if (!id || !type) this.router.navigate(['/']);
      else {
        this.regulationsService.fetchById(type, id!!).subscribe(
          (regulation) => {
            this.regulation = regulation;
            this.regulation.type = type;
          },
          (error) => this.router.navigate(['/'])
        );

        this.movements = [];
        if (type != RegulationType.NOTAS)
          this.movementsService.fetchById(type, id).subscribe((movements) => {
            // order by date
            this.movements = movements.sort((a: any, b: any) => {
              // order newest date first
              return (
                new Date(b.fechaMovimiento).getTime() -
                new Date(a.fechaMovimiento).getTime()
              );
            });
          });
      }
    });
  }

  filterThemesByRelated(themes: Theme[]) {
    if (this.client) {
      let temasRelacionados = this.client.temasRelacionados;
      return themes.filter((theme: Theme) => {
        return temasRelacionados.some(
          (relatedTema) => relatedTema.id === theme.id
        );
      });
    }
    return themes;
  }

  get type() {
    return RegulationType[this.regulation?.type ?? 0];
  }
}
