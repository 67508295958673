import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/core/services/login.service';
import { PreviousUrlService } from 'src/app/core/services/previous-url.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  isSubmiting: boolean = false;

  form = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]],
  })

  constructor(
    private loginService: LoginService,
    private previousUrlService: PreviousUrlService,
    private formBuilder: FormBuilder,
    private router: Router,
  ) { }

  submit() {
    this.isSubmiting = true;
    if (this.form.invalid) {
      return;
    }
    this.loginService.login(this.form.value.email!, this.form.value.password!).subscribe(() => {
      console.log(this.previousUrlService.getPreviousUrl());
      const decodedUrl = decodeURIComponent(this.previousUrlService.getPreviousUrl());
      this.router.navigateByUrl(decodedUrl);
    }, (error) => {
      this.isSubmiting = false;
      this.form.setErrors({
        invalidCredentials: true
      });
    });
  }

}
