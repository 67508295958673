<div class="col-12 mapdiv">
    <svg version="1.1" id="Capa_1" mapsvg:geoViewBox="-169.110266 83.600842 190.486279 -58.508473" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:mapsvg="http://mapsvg.com" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg"
        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 666 823.8" xml:space="preserve">

    <a xlink:title="Argentina" id="AR" (click)="redirectToSearch(1)" [ngClass]="isAvailable(1)">
        <path id="AR" d="M440.7,462l0.2,0.2l0,0.3l0.7,0.5l1.4,0.8l1.3,1.5l1.2,2.2l1.1,1.7l1,1.1l1,0.8l0.9,0.4l0.5,0.4l0.1,0.3l0.8,0.6
            l1.6,0.9l1,0.9l0.4,0.9l1.6,0.9l2.7,0.9l2,0.4l1.2,0l1.8,0.7l2.3,1.5l1.4,1.1l0.5,0.6l1.5,1l3.7,2l1.7,0.5l0.8,0.5l0.5,0.6l0.5,0.2
            l0.5-0.2l0.9,0.2l1.4,0.6l1.1,0.8l1.4,1.7l0.5,0.7l0.2,0.6l-0.1,0.5l-0.4,0.6l-0.8,0.6l-0.2,0.3l0,0.3l-0.2,0.5l-0.7,1.1l-0.2,0.5
            l0,0.4l-0.4,0.4l-1.1,0.8l-0.2,0.4l-0.1,0.4l-0.1,0.2l-0.2,0.1l-0.2,0.4l-0.2,0.7l0,0.9l0.1,1l0,0.3l-0.2,0.2l-0.1,0.2l0,0.5
            l-0.2,0.4l-0.4,0.2l-0.1,0.3l0.1,0.3l-0.3,0.3l-0.6,0.3l-0.4,0.4l-0.1,0.6l-0.4,0.5l-0.6,0.4l-0.2,0.6l0.3,1.1l3.5-0.4l2.9,0.4
            l3.4,1l2.3,0.4l1.1-0.3l0.8,0l0.5,0.4l0.7,0.1l0.9-0.2l0.8,0.2l0.6,0.6l0.5-0.1l0.5-0.9l0.6-0.7l0.6-0.4l0.8-0.1l0.9,0.2l0.7,0.3
            l0.6,0.5l0.6,0l0.7-0.5l0.3-0.6l0-0.7l0.3-0.5l0.6-0.3l0.4-0.4l0.2-0.5l0.7-0.3l1.1-0.1l0.6-0.3l0.1-0.4l0.3-0.4l0.6-0.3l0.4-0.4
            l0.2-0.5l0.4-0.3l0.5-0.1l0.6-1.1l0.6-2l0.4-2.7l0.1-3.8l0.6,0l0.3,0.3l0.5,0.1l0.5-0.3l0.4-0.1l0.7,0l0.4-0.4l0.4-0.1l0.3,0.2
            l0.3,0.2l0.6,0.1l0.5,0.6l0.5,0.2l0.2,0.7l0.3,1.9l0.6,1.1l0.6,1.3l0,0.6l-0.3,0.6l-0.1,0.8l-0.2,2l-0.1,0.7l0.2,0.5l0.1,0.7
            l-0.3,0.9l-0.6,1.3l-0.6,0.3l-0.2,0l-0.8,0.7l-0.6,0.3l-0.3-0.2l-0.4,0.3l-0.4,0.8l-0.5,0.4l-1,0.2l-0.3,0.1l-0.6,0l-0.5,0.2
            l-0.4,0.4l-0.4,0.2l-0.5-0.1l-0.4,0.2l-0.4,0.4l-0.2,0.5l-0.1,0.5l-0.4,0.4l-0.7,0.2l-0.2,0.3l0,0.4l-0.3,0.3l-1.2,0.3l-0.8,0.5
            l-0.5,0.7l-0.5,0.5l-0.9,0.3l-1.2,0.8l-0.2,0.5l0.4,0.4l0.2,0.4l-0.1,0.3l-0.4,0l-0.6-0.2l-0.4-0.1l-0.3,0.1l-0.1,0.3l0,0.4
            l-0.2,0.3l-0.4,0.1l-0.3,0.3l-0.1,0.5l-0.6,0.7l-1,0.8l-0.8,1.1l-0.6,1.3l-0.7,0.9l-0.8,0.4l-0.5,0.6l-0.3,0.8l-0.8,1.2l-1.3,1.6
            l-1.2,1.1l-1.1,0.6l-0.6,0.7l-0.1,0.8l-0.7,0.9l-1.3,1l-0.4,0.4l-0.3,0.4l0,0.6l-0.5,0.8l-1,1l-0.3,0.9l0.4,1.1l0.1,1.4l-0.2,0.6
            l-0.4,0.2l-0.1,0.4l0.2,0.5l0,0.7l-0.2,0.9l-0.4,1L478,545l-0.2,0.7l0.4,0.4l0.2,0.4l0,0.4l-0.1,0.6l-0.3,0.8l-0.4,0.6l-0.6,0.4
            l-0.2,0.5l0.2,0.6l0,0.6l-0.2,0.6l0.1,0.6l0.4,0.6l0,0.7l-0.6,1.4l-0.2,0.9l0.4,3.8l-0.2,0.5l-0.4,0.6l-0.5,0l-0.5-0.1l-0.4,0.4
            l-0.2,1.7l-0.7,3.6l0.1,0.9l0.6,1.4l0.2,0.9l0.2,0.7l0.1,1.3l-0.3,0.6l-0.3,0.1l-0.4,0.3l0.5,1.6l0.4,0.7l1.1,1.5l4.2,2.1l1.7,1.2
            l2,1.7l1.1,1.7l0.1,1.4l-1.6,2.1l-0.2,1.8l0.3,1.3l0.6,1.2l1.5,1.5l1.1,0.6l1.5-0.1l0.3,0.4l0.2,0.4l0.2,3.1l0,1.2l-0.4,1.1
            l-2.9,4.9l-2.5,3l-0.9,1.7l-0.3,1.8l-0.8,0.9l-4.3,2.7l-6.7,2.4l-5.4,1.3l-1.2,0.4l-8.7,1.4l-1.7,0.2l-2.2-0.1l-1.8,0.2l-2-0.4
            l-1.8-0.4l-1-1.1l-1.2-0.1l-0.3,0.5l0.6,1.4l-0.3,1.7l0.3,1l0.7,0.2l0.7,0.5l0.6,0.7l-1,0.1l0.4,0.5l0.4,0.3l0,1.1l-0.4,2.7l-1,0.6
            l-0.3,0.2l-0.3,0.6l-0.6,2.6l-0.2,1.7l0.3,1.1l1.2,2.3l-0.4,1.5l-0.8,0.8l-3.3,1.7l-1.3,0.7l-2.1,0.5l-3.3,0.1l-1.2-0.1l-2.8-1.5
            l-2.1-0.9l-1.9-0.7l-1.9-0.4l0.3-0.2l0.1-0.4l-0.5-0.2l-0.4,0l-1.2,0.8l-0.5,0.8l-0.1,0.7l0,1.7l0.2,1.4l0.9,3.5l0.1,1.9l-0.4,2.4
            l0.6,1.4l0.7,0.6l1.6,0.6l0.6,0.4l0.7-0.1l0.2,0.2l-0.1,0.3l-0.4,0.6l0,0.7l1.2,0.2l1.3-0.1l1.3-0.3l0.3-0.5l0-1l-1.6-0.2l0.2-0.4
            l1.2-0.4l1.5-0.6l0.8-0.1l0.5,0.4l0.4,0.4l0.4,1l0.3,1.4l0,1.6l-0.2,1.5l-0.2,0.5l-0.4,0.7l-2.8,0.8l-0.8-0.2l-0.7-1.2l-0.2-1.2
            l-0.6-0.8l-1.3-0.6l-1.3,0.2l-1.3,1.1l-1.3,0.4l-0.5,1l3.2,1.6l1.5,0.5l0.5,0l0.5,0.2l-0.5,0.6l-0.5,0.4L424,664l-1,0.6l-1.2,1.2
            l-1.7,2.5l-0.5,0.6l-0.3,0.6l-0.2,1.8l0.5,2.9l-0.6,1.2l0.3,1.4l-0.2,0.9l-0.6,1.3l-2.3,2.1l-0.4,1.5l0.8,0.9l-0.1,0.8l-0.3,0.7
            l-1,0l-3.5-0.5l-1.3,0.8l-1.2,1l-0.3,0.5l-0.4,0.3l-2.4,0.5l-0.5,0.3l-2.6,3.7l-1.1,2.3l-1.3,2.3l-0.3,1l-0.1,1.3l0.2,1.2l0.2,0.9
            l0.5,1.1l1,1.3l4.9,5.3l1,0.5l5.3,0.6l1.2,0.7l0.7,1.2l0.3,1l-0.3,2.7l-0.3,0.8l-0.6,0.8l-1.2,1l-1.5,0.5l0.4,0.4l0.6-0.1l1.3-0.3
            l0.6,0.3l0.4,1.1l-0.8,0.4l-0.3,0.5l-0.6,0.8l-3,3.1l-1.6,0.9l-1.5,1.3l-2,1.3l-0.8,0.7l-1.1,1.5l-1.6,1.7l-1.8,3.6l-0.1,0.7
            l0.3,0.5l-1,6.4l-0.3,0.8l-0.7,0.8l-1.9,1.3l-0.9,0.2l-1.2-0.8l-0.7-0.8l-0.7-1.4l-0.8-1.4l0,0.5l0.3,0.9l-0.2,0.9l-2,0.4l-0.5,0.4
            l1.8-0.2l1.3,0.3l0.5,0.3l0.5,0.7l0.4,0.8l-0.4,0.5l-1,0.4l-1.3,0.7l-1.5,1.3l-0.9,1.5l-0.4,1.1l-0.4,2.2l-0.1,1.4l-0.6,1.1l-1,1
            l0.1,0.2l0.7-0.5l0.5-0.2l0.5,1.3l0.6,2.7l0.3,1.9l0,0.5l-0.2,0.8l-1.3,0.2l-1.2,0l-0.8,0.3l0.5,0.3l0.8-0.1l1.1,0.8l1.2-0.3
            l0.5,0.5l0.4,0.5l1.8,3.9l1.6,2.4l0.8,1.4l-0.4,0.7l-0.1-0.9l-1-0.2l-1-0.2l-1.7-0.6l-2.3-0.9h-2.3l-1.8-0.8l-2-0.9l-4.2-0.1
            l-3.7-0.1l-3.8-0.1l-2.4,0l-1.6,0l-0.4-0.3l0.1-1.1l-0.6-0.8l-0.9-1l-1.1-0.7l-0.5-0.9l-0.6-1l0.3-0.9l0.5-2.2l0-1l-0.5-0.7l-0.1-1
            l0.1-0.5l0.4-0.3l0.3-1.6l-0.2-1.5l-0.3-1.4l-0.4-0.6l-0.5-0.3l-0.4-0.1l-0.9,0.5l-1.5-0.1L358,753l-0.7,0.6l-1,0.8l-0.6-0.3
            l-0.2-0.9l-0.4-0.8l-0.2-0.7l-0.2-1.1l-0.3-1.4l-0.6-1.7l-0.9-1.3l-0.1-1.2l-0.2-1.5l0.5-1.5l-0.3-1.2l-0.6-1.4l0.2-1.5l0.6-0.8
            l0.2-1l2.6-0.2l-0.1-1.4l0.4-1.1l0.5-1l0.4-0.5l0.9-0.4l1.1-0.6l0.6-0.7l0.3-0.6l0.2-0.8l0-0.8l-0.2-1.7l0.2-0.5l0.7-0.7l1.2-0.6
            l0.5-1.7l-0.3-1.4l-0.6-1.1l-0.8-0.5l-0.1-1.2l0.4-1.1l0.5-1.2l0.6-1.4l0-0.9l0.5-0.6l1.5-1.2l0.5-1.2l0.5-0.3l0.6-0.1l0-0.7
            l-0.4-0.7l-0.1-0.8l0.1-0.9l0.1-1.2l0.7-0.5l1-1l0.3-0.6l0-0.9l-0.3-1.8l-0.2-1.3l-0.1-0.5l-0.5-0.9l-0.3-0.5l0.5-0.7l1-0.7l0.4-1
            l-0.4-0.9l-0.6-0.5l-0.2-1.3l0.2-1.7l0.4-0.5l1.5-0.3l0.1-0.9l1.1-1.2l0-1.2l-0.7-0.7l-0.7-1.1l-0.5-1l-1.7-0.6l-1.9-0.3l-0.3-1
            l0.1-0.6l0.9,0.2l1.4-0.2l1.1,0l0.7-0.1l0.9-0.1l0.8,0.4l0.8-0.3l0.3-1.5l0.5-0.8l0.1-0.7l-0.5-0.6l-0.9-0.2l-4-0.5l-0.1-0.6l0-1
            l0.1-1v-0.5l0.4-0.4l0.4-0.9l0.3-0.6l-0.3-0.8l-0.6-1.2l0.5-0.5l0-0.6l-0.1-0.6l-0.7-0.7l-0.6-1v-1l0.7-0.3l0.5-0.3l0.1-0.6
            l-0.3-0.8l-0.9-0.2l-1.3-0.5l-0.4-0.4l-0.4-0.8l0.3-2.3l-0.1-1.4l-0.1-0.8l0.3-0.6l0.4-0.5l-0.2-1.3l-0.4-0.7l0.1-0.5l0.4-0.5
            l0.3-0.6l0.3-0.1l0.4,0.4l0.7-0.2l0.8-0.5l0.1-0.6l-0.2-0.8l-0.6-2.1l-0.5-1.3l0.1-0.5l0.2-0.5l-0.2-1.8l0.1-1.1l0-3.2l0.1-1.1
            l-0.6-1.1l0.1-1l0.4-0.8l0.4-1l0.3-0.9l0.3-0.4l0.5-0.2l0.1-0.5l-0.2-0.4l-0.6-0.6l-0.1-0.7l0.1-0.5l0.3-0.3l0.5,0l0.4-0.8l0.1-1
            l0.1-0.4l-0.3-0.6l-0.2-1.3l-0.2-0.7l0.2-0.3l0.3-0.1l0.5,0.2l0.4-0.1l0.1-0.4l0-0.4l0.1-0.3l0.3-1l0.4-1.2l0.1-0.8l-0.1-2.3
            l0.2-0.5l0.4-0.5l0.5-0.4l0.7-0.4l0.9-0.5l1.1-0.2l0.4-0.6l0.3-0.8l0.1-0.6l-0.4-0.4l-0.6-0.5l-0.3-1.3l-0.1-1.2l-0.1-1.6l-0.6-1.3
            l-0.6-1.5l-0.2-1.3l0.2-0.7l0.2-1.2l-0.2-0.5l-0.3-0.9l0.3-0.7l0.4-1.1l0-0.6l-0.3-1.4l-0.3-0.8l0.3-0.8l0.4-0.8l0.3-0.4l-0.1-0.7
            l0.1-0.5l0.6-0.4l0.6-0.7l0.4-0.1h0.5l0.3-0.2l0.1-0.5l0.1-0.6l0.8-0.7l0.5-0.7l0.9-0.1l0.4-0.7l0-0.9l-0.1-0.9l0.3-1.1l-0.3-1.6
            l0-0.9l-0.3-0.7l0.1-0.8l-0.2-0.5l-0.5-0.2l-0.2-0.6l0.2-0.3l0.5-0.2l0.6-0.5l0.4-2.2l0.2-0.7l0.2-0.8l0-0.4l0.3-0.6l0.4-0.9
            l0.6-0.9l0.3-0.6l0.3-0.8l0.1-0.5l0.4-0.2l0.5-0.1l0.5-0.1l0.2-0.3l0-0.4l0-0.9l-0.2-1.5l-0.1-1.9l0.1-1.3l0.4-1.3l0.3-0.7l-0.1-0.5
            l-0.1-0.6l-0.6-0.3l-0.6,0.3l-0.4-0.1l-0.5-0.7l-0.2-0.7l0.1-1l0.4-0.6l0.2-0.8l-0.2-0.2l-0.5-0.5l-0.5-1.7l0.1-1.5l-0.5-0.4
            l-0.2-1.2l-0.5-0.4l-0.2-0.9l-0.2-0.9l0.1-0.4l0.5-0.1l0.3-0.7l-0.2-0.4l-0.4-0.3l-0.5,0l-0.5-0.4l-0.6-1.7l-0.5-0.9l0.2-1.3l0.1-1
            l0.2-0.9l0.1-0.7l0.4-0.3l0.4,0.2l0.3-0.1l0.3-0.6l0.3-0.4l0-0.3l-0.2-0.3l-0.1-0.5l0.2-0.6l0.4-1.5l0.6-1.6l0.3-0.6l-0.1-0.5
            l0.1-0.2l0.4,0.3l1.2-0.3l0.4-0.7l0.2-0.6l0.4-0.4l-0.2-0.5l-0.5-0.2l-0.3-0.2l0.1-0.6l0.2-1.3l0-1l-0.4-2.1l-0.4-2l0.3-0.7l0.8-0.9
            l0.6-0.4l0.1-0.5l0.6-2.4l0.1-1.3l0.4-0.7l0.3-1.4l1-1.2l0.3-0.8l0.4-0.1l0.2-0.3l0.6-0.9l0.7-0.9l0.6-0.4l0.2-0.7l0.3-1l0.6-1.6
            l0.3-1.1l0.5-0.4l0.5-1.4l0.2-0.8l0.6-0.3l0.5-0.1l0.5,0.4l0.5-0.1l0.4-0.5l1.1-0.3l0.5-0.2l0.2-0.5v-0.9l-0.4-0.6l-0.9-1.2
            l-0.8-1.4l-0.1-0.4l0-0.5l0.1-0.6l0.4-0.7l0.9-1.1l-0.1-0.8l-0.7-2.9l-0.3-0.8l-0.5-1.5l0.1-0.6l0.4-1.6l0.4-0.7l0.5-0.1l0.3-0.2
            l0.1-0.3l-0.4-0.4l-0.2-0.5l-0.2-0.7l-0.5-0.2l-0.3-0.5v-0.8l0.4-1l0.5-0.3l0.2-0.5l0.5-0.4l0.5-0.3l0.4-0.6l1.6-0.7l1.3-0.6
            l2.5-1.1l1.7-0.8l0.2-0.5l0.1-0.4l0.8-2.6l1-3.4l0.7-2.1l-1.5-1.6l0.3-0.4l0.9-1.1l0.2-0.9l0.3-0.4l1.5-0.9l0.3-0.6l0.1-0.6l0.3-0.5
            l0.6-0.1l1.1-0.4l1.1-0.4l0.3-0.5l0.3-0.9l0.3-1l0.2-0.2l0.4,0l0.6,0.3l0.3,0.4l1.6,1.2l0.7,0.7l0.7,0.1l1.4-0.1l0.3,0l3.5,0
            l0.5,0.1l1.2,0.3l0.7,0.2l0.5,0.1l0.8,0.4l0.7,1.2l0.4,1l0.3,0.9l0.6,1.5l0.4,0.6l0.1-0.3l0.3-1.7l0.5-1l0.6-1.1l1.3-2.5l0.4-0.4
            l0.5-0.2l0.3,0l0.3,0.2h0.5l0.3-0.2l3.3,0l3.4,0l0.1,0l0.2,0.4l0.6,1l0.6,0.5L440.7,462L440.7,462z M447,621.6l0.1,0.7l-0.4-0.1
            l-1-0.6l-0.3-0.6l-0.1-0.2l1,0.3l0.5,0.2L447,621.6L447,621.6z M392.1,809l0-3.2l0.1-4.2l0-3.7l0-3.6l0-3.7l0-3.7l0-3.9l0-4l0.5,0.6
            l1.9,2.7l0.5,1.1l0.3,1.3l-0.8-0.8l-0.8,0.5l-0.4,0.8l-0.3,0.9v0.6l0.3,0.5l0.8,0.5l1.9,0.2l0.1,0.2l1.1,3.3l0.6,0.7l0.6,0.6
            l1.5,1.7l1.4,1.8l1.7,1.8l1.8,1.4l1.7,1l1.6,1.3l1.7,1.8l1.8,1.3l2,0.9l2,0.8l3.1-0.3l0.9,0.1l0.6,0.6l-0.6,1.5l-0.8,1.2l-1,0.5
            l-1.1,0.2l-1,0l-1-0.2l-0.9,0.1l-0.9,0.5l-0.9,0.3l-0.9,0l-0.9,0.4l-0.9,0.3l-0.9-0.3l-2.4-1.2l-1.6-0.3l-5.4-0.5l-1.7-0.3l-1.7-0.4
            l-0.9,0l-1.3,0.3l-1,0L392.1,809L392.1,809z M425.3,807.1l0.9,0.3l1.8-0.2l0.9,0l0.4,0.1l0.2,0.2l1.2-0.3l0.5,0l-0.1,0.6l-1.1,0.6
            l-0.5-0.3l-2.4,0.1l-1.1,0.6l-0.4,0l-1,0.9l-0.8-0.6l-0.2-0.5l0.6-0.7l0.5,0l0.4-0.3L425.3,807.1L425.3,807.1z"/>
    </a>
    <a xlink:title="Bolivia" (click)="redirectToSearch(2)" [ngClass]="isAvailable(2)">
        <path id="BO" d="M477,444.2l0.2-1.4l-0.2-1.2l-0.2-0.3l-2.4-1.5l-2.2-1.3l-2.8-1.7l-3.6,0l-3.8,0l-3.6,0.8l-3.5,0.8l-1.7,0.4
            l-3.4,0.7l-2,0.3l-0.5,1.4l-0.8,2.1l-0.8,1.2l-0.9,1.3l-1.3,1.8l0,2.2v2.1l-0.9,3l-0.7,2.5l-0.7,2.5l-0.5,1.7l-0.2,0.4l-0.1-0.1
            l-0.6-0.5l-0.6-1l-0.2-0.4l-0.1,0l-3.4,0l-3.3,0l-0.3,0.2l-0.5,0l-0.3-0.2l-0.3,0l-0.5,0.2l-0.4,0.4l-1.3,2.5l-0.6,1.1l-0.5,1
            l-0.3,1.7l-0.1,0.3l-0.4-0.6l-0.6-1.5l-0.3-0.9l-0.4-1l-0.7-1.2l-0.8-0.4l-0.5-0.1l-0.7-0.2l-1.2-0.3l-0.5-0.1l-3.5,0l-0.3,0
            l-1.4,0.1l-0.7-0.1l-0.7-0.7l-1.6-1.2l-0.3-0.4l-0.6-0.3l-0.4,0l-0.2,0.2l-0.3,1l-0.3,0.9l-0.3,0.5l-1.1,0.4l-1.1,0.4l-0.6,0.1
            l-0.3,0.5l-0.1,0.6l-0.3,0.6l-1.5,0.9l-0.3,0.4l-0.2,0.9l-0.9,1.1l-0.3,0.4l-1.4,0.3l-1.8,0.3l-1,0l-0.7-0.1l-0.2-0.2l-0.5-0.3
            l-0.1-0.3l0-0.5l0.1-0.9l-0.1-1.2l-0.6-1.4l0-0.4l-0.1-0.7l-0.3-1.3l-0.7-0.6l-0.2-1.1l-0.1-0.9l-0.6-1.2l-0.1-1.5l0-1.3l-0.9-1.5
            l-1-1.6l-0.8-0.2l-0.2-0.2l-0.1-0.4l0-0.7l0.1-0.4l0.6-0.7l0-0.1l-0.1-0.1l-1.6-1l-0.4-0.3l-0.1-0.4l0-0.3l0.4-0.3l0.2-0.2l-0.4-0.7
            l0-0.7l-0.2-0.3l0-0.2l0.2-0.2l1-0.2l0.3-0.7l0-0.6l-0.2-0.4l-1-1l0-0.2l1-1.4l0.7-0.9l0.2-0.2l-0.1-0.2l-0.2-0.2l-0.5-0.3l-0.6-0.4
            l-0.5-0.5l-0.6-0.7l-0.8-0.6l-0.6-0.6l-0.3-0.5l0-0.5l-0.1-0.8l-0.4-1.3l-0.1-0.9l-0.2-1l-0.2-0.6l-0.1-0.6l-0.3-0.7l-0.2-0.5
            l0.2-0.3l0.2-0.3l0-0.2l-1.5-0.7l-0.3-0.2l-0.4-1.4l-1.1-1.3l-0.1-1v0v-0.4l-0.1-0.6l-0.3-0.5l-0.5-0.3l-0.2-0.4l0.2-0.4l1-0.8
            l0.5-0.1l0.1-0.4l0.3-0.3l0.9-1.2l0.5-0.8l0.5-0.5l0.6-0.3l0.3-0.3l-0.1-0.8l0-0.6l0.2-0.4l0.6-0.4l0.6-0.3l0.1-0.1l0-0.2l-0.5-0.4
            l-1.1-0.4l-0.7,0l-0.4-0.3l-0.2-0.3l-1.4-3.4l-0.2-0.8l0-0.3l0.9-1.7l0.4-0.6l0.7-0.8l-0.1-0.3l-1.2-1.3l-0.4-0.6l0-0.6l0.1-0.8
            l0.7-0.4l0.2-0.6l0.1-0.6l0.3-0.2l0.3-0.3l0.3-0.5l0.5-0.4l0.3-0.3l0.1-0.9l0.3-0.3l0.7-0.3l0.1-0.2l-0.2-0.6l-0.4-0.7l-0.3-0.3
            l-0.4-1.6l-0.4-0.8l0.2-0.3l0.3-0.4l0.3-0.8l0.1-0.9l-0.1-3.5l0-0.7l0.4-0.5l0.5-0.6l0.5-0.2l0.4-0.3l0-0.7l0.3-0.4l0.3-0.5
            l-1.1-1.9l-1-1.7l-0.9-1.6l-1-1.8l-0.7-1.2l-0.8-1.5l-0.7-1.3l-1-1.8l0.9,0l1.9,0.1l1.8,0.3l1.2,0.1l0.5,0.3l0.1,0.4l0.3,0.2
            l0.4-0.1l0.4,0l1-0.4l0.8-0.3l0.7-0.4l0.4-0.3l0.9-1.2l0.7-0.7l0.6-0.2l1.3-0.1l0.4,0.2l0.5,0l0.4-0.7l0.7-0.8l1.3-0.9l0.7-0.3
            l0.4-0.3l0.7,0l0.6-0.3l3.1-2.4l1.3-0.6l0.8-0.1l0.6-0.1l1.1-0.3l2.7-0.3l1.8-0.1l0.6,0.3l0.6-0.1l0.5-0.5l0.4-0.2l0.3,0l0.5,0.6
            l0.2,0.7l-0.2,0.5l0,0.7l0.2,1l-0.1,0.9l-0.7,1.1l-0.3,0.5l-0.1,0.5l0.1,0.6l0.3,1l0.6,1.5l0.1,1.1l-0.4,0.7l-0.2,0.6l0,0.5l0.1,0.4
            l0.2,0.2l0.1,0.4l0,0.6l0.3,0.6l0.6,0.6l0.3,0.5l-0.1,0.5l0,0.3l0.2,0.1l0.2-0.1l0.2-0.1l0.2,0.1l0.4,0.7l0.1,0.1l0.2,0.6l0.1,0.5
            l0.6,0.3l0.7,0.2l0.4,0.2l0.8,0.7l0.6,0.5l0.8,0.4l0.3,0.6l0.5,0.9l1.3,0.4l1.6,0.2l1,0.2l1.2-0.5l0.8,0.1l0.8,0.3l0.4,0.2l0.6,0.5
            l1,0.6l0.8,0.2l0.6-0.3l0.5-0.1l0.4,0.1l0.2,0.7l0.2,0.5l0.5,0.3l1,0.9l0.6,0.4l0.6,0l1.3,0.6l1.4,0.6l0.7,0.1l0.7-0.1l0.5,0.2
            l0.2,0.7l1.2,1.4l0.6,0.5l0.7,0.5l1.7,0l0.5,0.1l0.8-0.1l2.3-0.2l0.4-0.1l1.3,0.6l1.6,0.9l1,0.7l0.7,0.4l0.4,0.6l0.3,0.6l0.1,0.7
            l-0.2,0.7l-0.3,0.3l-0.1,0.4l0.1,0.6l0.5,0.6l0.2,0.7l0.3,1.3l0.3,0.4l0.2,3.9l-1,0l-1.5,0l0.4,0.4l1.2,1.5l1.1,1.3l0.2,2.2l0.1,1.4
            l0.2,1.9l0.1,1.2l2.8,0.1l3.2,0.1l3.9,0.1l3.4,0.1l0.3,0l0.6-0.2l0.4-0.2l0.2,0l0,0.5l-0.1,0.6l0,0.7l-1,1.3l-0.1,0.4l0.1,1.8
            l0.3,1.4l0.2,1.3l0.4,0.4l1.2,0.7l1.7,1.3l0.7,0.2l0.6-0.2l0.4,0.5l0.1,0.8l1,2.3l0.6,1.5l0.3,0.5l0.5,0.3l-0.1,0.2l-0.4,0.1
            l-0.2,0.3l-0.5,1.7l-0.7,2.2l-0.5,1.5l0.4,0l0,0.4l0.1,0.7l-0.5,0.1l-0.2,0.2l-0.6,1.3l-0.8,1.7l-0.8,1.7l-0.5,1l0.8,0.8l1.4,1.3
            l-0.2,0.3l-0.6,0.2l-0.5,0.1l-0.4,0.5l-0.2,0.3L477,444.2z"/>
    </a>
    <a xlink:title="Brasil" (click)="redirectToSearch(3)" [ngClass]="isAvailable(3)">
        <path id="BR" d="M490.6,263l0.2,0.1l0.5,0.1l1.3,0.3l1.7,0.3l0.5-0.1l0.3-0.2l0.1-0.7l0.1-0.5l-0.4-0.4l-0.5-0.5l-0.4-0.6l-0.5-0.2
            l0.1-0.3l0.3-0.3l0.3-0.2l0.2-0.2l0.2-0.8l0.1-0.1l0.1,0l0.2,0l0.3,0.2l1.3,0.7l0.6-0.1l2.2-0.2l0.3-0.4l0.5-0.1l0.8-0.4l0.3,0
            l0.3,0.1l0.4-0.1l0.5-0.4l0.2,0l0.1,0.4l0.3,0.4l0.4,0.4l0.2,0.1l0.7-0.1l0.4,0.1l0.2,0.4l0,0.3l0.3,0.3l0.4,0l0.2,0.1l0.3,0.2
            l0.3,0.4l0.7,0.3l1.1,0.4l0.5,0l0.5,0.1l0.3,0.1l0.3-0.2l1.2-0.7l0.6-0.4l0.4-0.3l0.3-0.3l0.2-0.1l0.1,0.2l0.1,0.2l0.4,0.1l1,0.2
            l0.4,0.1l0.6-0.2l0.5-0.4l0.3-0.1l0.4,0.4l0.3,0.5l0.2,0.2l0.4-0.1l0.8,0.1l0.6,0.2l0.4,0l0.5-0.2l0.3-0.4l0.7-0.4l0.7-0.4l0.4-0.5
            l0.6-0.8l0.2-0.4l0-0.6l0.8-1.7l0.3-0.3l0.2-0.6l0.3-0.6v-0.5l0.2-0.5l0.5-0.4l0.3-0.3l0.5-0.7l0.4-0.7l0.9-1.6l0.1-0.4l0.4-0.3
            l0.1-0.3l0.4-0.4l0.4-0.3l0.2-0.5l0.3-0.5l0.7-0.4l0.3-0.2l0.8-1.4l0.1-0.6l0.7,0l1.1,0.7l0.9,1.1l1.2,3.4l0.2,3.1l0.5,1.6l1.4,3.4
            l0.1,0.6l0.2,0.8l0.4,0.8l0.5,1.3l0,0.2l-0.3,0.4l0.4,0l0.4,0.2l0.3,0.8l0.3,0.6l0.6,0.8l1.3,0.3l0.9,0.1l1.1,0.4l0.8,0.6l0.6,1.9
            l-0.2,1.2l0.1,0.9l-0.3,0.3l-0.9,0.6l-0.2,0.3l-1.8,1.4l-0.4,0.7l-1,0.9l-1,1.7l-1.4,1.6l-0.5,0.4l-0.8,0.1l-0.5,0.2l-1.1,1.3
            l-1.5,0.4l-0.1,0.8l-0.8,1.7l-0.7,0.9l-0.5,0.3l-1.2,1.7l-0.2,0.7l0,1.3l-0.8,0.8l-0.8,0.5l-0.1,1.1l-0.4,0.4l-0.3,0.2l-1.7-0.3
            l-2.6,1.2l-0.9,0.3l2.9,0.1l0.9,0.6l2-0.4l2.4-1.5l0.9-0.3l1.9-1l0.8-0.7l1.4-0.8l0.3-0.4l0.8-0.4l0.4,0.5l0,0.3l-0.6,0.6l0.2,0.4
            l0.4,0.5l0.2,0.7l0.1,0.5l0.3,0.9l0.9,1.2l0,0.4l-0.1,0.5l0.4,0.4l0.4,0.3l1.5,1.3l1.2-0.7l0.7-0.2l0.5-0.3l1-0.2l0.8,0.3l1.5,0.4
            l1.1-0.5l2.2-1.1l-0.7,1.9l-0.5,1.8L547,297l-0.4,1.9l-0.4,0.5L546,300l0.5-0.2l0.4-0.3l0.5-0.7l0.4-1.3l1.6-3.4l0.5-0.3l1.3-0.4
            l2.3-2.7l0.9,0l0.6,0.6l0.5,0.4l0.1-0.8l0.8-0.3l-0.8-0.4l-0.1-0.3l-0.1-0.6l0.6-0.8l-0.3-0.7l1.2-0.8l-0.1-0.6l0.4-0.5l0.5-0.5
            l0.6-0.3l0.1-0.5l0.4-0.2l0.3-0.1l0.6,0.5l0.6-0.6l0.6-0.2l0.3,0.1l0.3,0.3l0.4,0.1l0.3,0l0.8-0.4l0.7,0.6l0.4,0.1l-0.1-0.4
            l-0.2-0.3l0.2-0.3l0.3-0.2l1,0.1l0.6,0.3l0.6,0.5l0.8,0l0.7-0.1l0.4,0.3l0.7,0l0.3,0.5l1,0.6l0.2,0.4l0.8,0.2l0.8,0.3l0.8,0.1
            l0.8-0.1l0,0.6l0.6,0.1l0.8-0.1l0.6,0.7l1.6,0.5l1.1,0.8l0.7-0.1l0.8,0.2l0.9,1.7l0.2,1.2l0.4-0.2l0.4-0.5l0.5-1l0.9-0.3l0.4,0.4
            l0.9,0.6l0.7,0.7l0.3,0.4l0.6,0.1l-0.5,0.5l0.5-0.1l0.6-0.4l0.5,0.8l0.4,0.8l0.1,0.9l-0.3,0.5l-0.3,0.3l-0.3,0.6l-0.4,0.1l-0.4,0.2
            l0.4,0.4l0.3,0.4l0.7-1.1l0.5-0.3l0.7-0.2l0.4,0.8l0.1,0.8l-1.1,0.3l0,0.6l-0.3,0.3l-0.2,0.4l-0.2,0.8l-0.2,0.7l-0.7,3.1l0,0.5
            l0.8-0.5l1.5-1.6l0.5-1.7l0.6-1.6l0.6-0.5h0.4l0.6,0.2l0,0.5l-0.1,0.3l-0.6,0.8l-0.3,0.4l0.3,0.4l1.4-1.3l0.7-0.5l0.6,0.1l1.1-0.6
            l2.2-0.1l0.2-0.7l0.4-0.3l1.2,0.1l2.4,0.6l0.8,0.5l1.3,0.5l0.7,0.6l2.8,1.1l2,0.1l1-0.5l1.2,0.5l0.7,0.6l1.3,0.3l1.3,0.2l1-0.4
            l2.6-0.1l3.2-0.6l1.9,0.1l2.2,0.4l1.6,1l1.3,0.6l0.8,0.6l1.3,0.6l2.7,1.6l1,0.9l1.7,1.2l1.7,0.5l0.9,1.3l0.7,0.6l1.8,2.2l2,1.5
            l1.4,1.5l2.6,1l1,1.6l1.8,0.2l0.8,0.2l0.9,0.7l1.3,0.4l1.6-0.1l1.8,0.1l1.5-0.3l3.5,0.6l0.5,0.3l0.7,0.7l1.3,2.6l0.8,2.8l0.4,2.2
            l0.9,1.7l0.5,3.2l0.4,1l0,0.8l0.3,0.2l0.2,2.1l-0.1,0.9l-0.3,1.1l0,0.5l0,0.3l-0.1,0.5l0,0.4l0.3,1l0,0.8l-0.5,1l-0.6,2.6l-1.5,4.3
            l-1.5,2.4l-2.1,2.5l-1.4,1.3l-0.5,0.1l-0.5-0.3l0.3,0.7l-0.3,0.6l-1.4,1.9l-1.4,1.2l-1.4,2.1l-0.1,0l-1.8,0.8l-1.1,0.7l-1.4,1.2
            l-1.3,1.9l-0.3,0.2l-0.5-0.1l0,1l-1.1,1.5l-0.3,0.2l0-0.4l0.2-0.3l0.1-0.4l0-0.4l-0.3,0.3l-0.6,1.2l0.2,0.8l-0.5,1.3l-1.8,3.7
            l-2.2,3.1l-0.5,1l-1.8,2.1l-1.3,1l-0.4,0l-0.4-0.1l-0.2-1.6l-1-1l-0.3-0.2l-0.4,1l-0.4,0.3l-0.5,0.1l0.6,0.4l0.2,0.5l-0.6,1l0,0.9
            l-1,1l-0.6,0.8l-0.3,1l-0.2,0.9l0.4-0.2l0.2,0.2l0.2,0.3l-0.1,0.4l-0.3,0.8l0,1.9l-0.1,0.4l0.3,0.5l0.3-0.8l0.2,0.2l-0.9,5.2
            l0.4,2.3l0.1,2.6l0.4,2.6l0.5,2.3l0,0.2l-0.6,2.7l-0.8,2.7l-0.5,2.2l-0.3,2.4l-0.3,1.1l-0.1,1.2l0.4,2.7l0.1,0.5l-1,1.2l-1.1,0.6
            l-0.6,0.6l-1.3,2.2l-0.7,3.3l0,1.7l0.3,3.7l-0.3,1.5l-0.4,1l-0.5,0.7l-1.3,0.8l-1.1,1.9l-0.5,2l-0.8,0.7l-0.2,1.1l-0.6,1.2l-1.6,1.8
            l-1.1,0.5l-0.5,0.5l-0.3,1.1l-1,1.8l-0.7,2.3l0.2,0.8l0,0.1l0.3,2.7l-0.1,0.7l-1,0.7l-3.7,1.4l-1,0.6l-2.2,2.4l-0.1,0.6l0.1,0.8
            l0.4,0.5l-0.4,0.5l-0.4,0.9l-0.6-0.1h-3.7l-2,0.3l-1-0.1l-0.5-0.2l-0.5-0.3l-0.2-0.5l0.3-0.7l-0.2-0.4l-0.6,0l-0.6,0.2l-0.1,0.4
            l0,0.3l0.2,0.4l0.1,0.5l-0.2,0.5l-1.2,0.1l-1.3,0.4l-1.6,0.2l-1.3,0.3l-0.6-0.4l0.6-0.2l0.9,0.1l0.9-0.3l-0.2-0.4l-1.3-0.5l-1.5,0.3
            l-0.8,0.6l-1.8-0.1l-2.2,0.4l-0.4,0.4l0.1,0.9l0.4,0.2l0.4,0.4l-0.4,0.4l-0.4,0.2l-2.3,0.4l-2.1,1.7l-0.9,0.2l-0.8,0.8l-0.1,0.6
            l-0.3,0.4l-0.5,0l-1.1-0.4l-1.4,0l-1,0.3l-5.3,2.8l-1.9,1.1l-2.2,2.3l-3.7,2.5l-1.9,1.5l-0.4,0.4l-0.3,0l-0.6,0.3l0.2,0.3l0.4,0
            l-0.2,0.9l-0.8,0.6l-1.4,1.6l-0.3-0.1l0.5-0.8l-0.7,0l-1-0.3l-0.5,0.3l0.3,0.8l-0.4,0.4l-0.7,0l-0.6-0.1l-0.7-0.6l0.3,1.1l1.5,0.3
            l0.6,0.3l0.2,0.4l-1.2,2l-1,0.3l-0.1,0.3h0.5l0.3,0.5l-0.3,2.2l-0.5,0.4l-0.3,0l-0.3,0.4l0.4,0.7l0.4,0.5l-0.1,1l-0.1,0.8l0,0.8
            l0.5,1.6l0.2,1.6l0.2,0.6l0.1,0.7l-0.3,0.6l0.2,1l-0.6,1.7l0.3,2.4l-0.1,2.3l-0.2,1.2l-0.4,0.9l-0.8,1.2l0,1.2l-1.8,1.1l-2,1.6
            l-1.9,1.9l-2,2.7l-2.3,4.1l-2.2,5.8L538,540l-1,1.6l-1.4,1.8l-1.9,2.1l-2.5,2.1l-2.7,1.9l-1,0.8l-1,1.2l-0.2-0.5l0.2-0.8l-0.1-0.6
            l0-0.8l0.6-0.1l0.8,0.5l0.4-0.3l0.3-0.3l1-0.2l1.9-2.1l1.4-0.8l0.8-1.3l0.1-0.7l0-1.4l0.4-0.4l1,0.1l0.2-0.4l-0.1-0.4l0.2-1l1.4-0.8
            l0.7-1l-0.2-2.6l0.3-0.1l0.6,0.4l0.3-0.2l0.3-1.1l-0.1-0.6l-0.7-0.2l-2.3,1.3l-0.8-0.1l-0.1-1l-1.1-0.5l-0.4-0.8l-0.1-0.6l-0.4-0.2
            l0,1l0.1,1l1,1.1l-0.2,0.4L533,536l-0.3,1.2l0,1.5l-0.3-0.5l-0.3-0.3l-0.1,1.6l-0.7,0.6l-0.2,0.6l0.2,0.7l-0.3,0.5l-1.7,1.3
            l-1.7,0.9l-0.4,0.4l-0.2,1l-0.3,1l-0.8,0.9l-0.6,1.8l0,0.8l0.2,1.1l0.3,0.8l-0.5,0.5l-0.7,1l-0.5,1.1l-1.3,4.2l-1.2,2.5l-0.9,1.2
            l-1.3,1.3l-3.6,3.3l-0.2,0l-0.5-0.3l-0.4-0.3l-0.1-0.2l-0.1-0.3l0.1-1.2l0-3.2l0.2-0.6l0.2-0.4l0.7-0.6l0.7-0.8l0.8-1l0.7-0.8
            l-0.3-0.5l-0.6-0.5l-1.1-0.4l-1-0.7l-0.9-1l-0.4-1l-0.4-1.1l-0.4-0.8l-0.1-0.4l-0.4-0.2l-0.6-0.4l-0.4-0.4l-0.5-0.2l-0.9-0.3l-1-0.4
            l-1.2-1l-0.9-1.2l-0.4-0.8l-0.5-0.5l-2.5-0.9l-1.1-1.1l-0.4,0.3l-0.7-0.3l-0.7-0.5l-0.2-0.4l-0.3-0.4l-0.3-0.5l-0.2-0.4l-0.7-0.8
            l-0.9-0.8l-0.4-0.2l-0.2,0.1l-0.2,0.3l-0.1,0.3l-0.3,0.2l-0.4,0.4l-0.4,0.5l-0.5,0.3l-0.6,0.1l-0.4,0l-0.1-0.2l0-0.6l0.2-1.4
            l-0.4-0.6l-0.5-0.6l-0.6-0.8l-1.9-1.7l-2.5-2.4l-0.9-0.7l-0.8-0.1l-0.8,0.1l-0.7,0.3l-0.5,1.1l-0.2,0.2l-1.4,0l-1.4-0.2l-0.5-0.7
            l0.4-0.4l1.3-1l0.7-0.9l0.1-0.8l0.6-0.7l1.1-0.6l1.2-1.1l1.3-1.6l0.8-1.2l0.3-0.8l0.5-0.6l0.8-0.4l0.7-0.9l0.6-1.3l0.8-1.1l1-0.8
            l0.6-0.7l0.1-0.5l0.3-0.3l0.4-0.1l0.2-0.3l0-0.4l0.1-0.3l0.3-0.1l0.4,0.1l0.6,0.2l0.4,0l0.1-0.3L497,516l-0.4-0.4l0.2-0.5l1.2-0.8
            l0.9-0.3l0.5-0.5l0.5-0.7l0.8-0.5l1.2-0.3l0.3-0.3l0-0.4l0.2-0.3l0.7-0.2l0.4-0.4l0.1-0.5l0.2-0.5l0.4-0.4l0.4-0.2l0.5,0.1l0.4-0.2
            l0.4-0.4l0.5-0.2l0.6,0l0.3-0.1l1-0.2l0.5-0.4l0.4-0.8l0.4-0.3l0.3,0.2l0.6-0.3l0.8-0.7l0.2,0l0.6-0.3l0.6-1.3l0.3-0.9l-0.1-0.7
            l-0.2-0.5l0.1-0.7l0.2-2l0.1-0.8l0.3-0.6l0-0.6l-0.6-1.3l-0.6-1.1l-0.3-1.9l-0.2-0.7l-0.5-0.2l-0.5-0.6l-0.6-0.1l-0.3-0.2l-0.3-0.2
            L509,491l-0.4,0.4l-0.7,0l-0.4,0.1l-0.5,0.3l-0.5-0.1l-0.3-0.3l-0.6,0l0-1.3l1.1-1.9l0.3-0.9l-0.1-0.5l0.3-1.8l0.8-3l0.3-2l-0.3-0.9
            l0-0.6l0.4-0.5l0.2-0.2l-1-0.7l-0.6-0.6l-0.7-0.4l-0.8-0.3l-0.4,0.1l-0.4,0.2l-0.8,0.3l-0.9,0.6l-0.5,0.2l-0.8,0.2l-0.9,0.2
            l-0.7-0.1l-0.6-0.1l-0.4-0.3l-0.2-0.8v-0.6l-0.1-0.9l-0.5-0.5l-0.2-0.4l0-0.5l0.1-0.6l0.1-0.4l-0.1-0.5l-0.2-0.3l0.1-0.6l-0.1-0.8
            l-0.3-0.5l-0.2-0.6v-0.6l-0.2-0.6l0-0.7l0.2-0.6l0.1-0.6l-0.2-0.4l-0.5-0.3l-0.3-0.7l-0.1-0.9l-0.4-0.5l-0.4-0.4h-0.5l-0.7-0.2
            l-0.6,0l-1,0l-0.5-0.1l-0.2-0.3l-0.6-0.4l-0.3-0.7l-0.4-0.1l-0.6,0.2l-0.2,0.3l-0.2,0.4l-0.4,0.5l-0.6,0l-0.6,0.3l-0.6,0l-0.7,0.1
            l-0.7-0.2l-0.9-0.3l-0.8-0.2l-0.7,0.2l-0.5-0.1l-0.7-0.1l-0.7-0.1l-0.6-0.5l-0.6-0.3l-0.3,0.1l-0.5,0.3l-0.5-0.1l-0.6-0.2l-0.2-0.5
            l0.1-0.3l0.1-0.3l0.2-0.5l-0.1-0.5l0.1-0.5l0.1-0.4l0.1-0.5l-0.1-0.4l0-0.5l-0.1-0.4l-0.1-0.5l0.3-0.7l0.3-0.5l-0.2-0.5l0.1-0.3
            l0.2-0.5l0.3-0.6l0-1.2l-0.2-0.7l-0.3-0.2l-0.1-0.2l0.1-0.3l-0.1-0.3l-0.1-0.3l0.1-0.2l-0.2-0.5l-0.4-0.1l-0.1-0.1l-0.1-0.5
            l-0.1-0.6l0.1-0.5l-0.2-0.4l-0.3-0.3l-0.3-0.5l-0.3-0.3l-0.1-0.5l-0.2-0.6l0.5-0.1l0.2-0.3l0.4-0.5l0.5-0.1l0.6-0.2l0.2-0.3
            l-1.4-1.3l-0.8-0.8l0.5-1l0.8-1.7l0.8-1.7l0.6-1.3l0.2-0.2l0.5-0.1l-0.1-0.7l0-0.4l-0.4,0l0.5-1.5l0.7-2.2l0.5-1.7l0.2-0.3l0.4-0.1
            l0.1-0.2l-0.5-0.3l-0.3-0.5l-0.6-1.5l-1-2.3l-0.1-0.8l-0.4-0.5l-0.6,0.2l-0.7-0.2l-1.7-1.3l-1.1-0.7l-0.4-0.4L475,418l-0.3-1.4
            l-0.1-1.8l0.1-0.4l1-1.3l0-0.7l0.1-0.6l0-0.5l-0.2,0l-0.4,0.2l-0.6,0.2l-0.3,0l-3.4-0.1l-3.9-0.1l-3.2-0.1l-2.8-0.1l-0.1-1.2
            l-0.2-1.9l-0.1-1.4l-0.2-2.2l-1.1-1.3l-1.2-1.5l-0.4-0.4l1.5,0l1,0l-0.2-3.9l-0.3-0.4l-0.3-1.3L459,395l-0.5-0.6l-0.1-0.7l0.1-0.4
            l0.3-0.3l0.2-0.7l-0.1-0.7l-0.3-0.6l-0.4-0.6l-0.7-0.4l-1-0.7l-1.6-0.9l-1.3-0.6l-0.4,0.1l-2.3,0.2l-0.8,0.1l-0.5-0.1l-1.7,0
            l-0.7-0.5l-0.6-0.5l-1.2-1.4l-0.2-0.7l-0.5-0.2l-0.7,0.1l-0.7-0.1l-1.4-0.6l-1.3-0.6l-0.6,0l-0.6-0.4l-1-0.9l-0.5-0.3l-0.2-0.5
            l-0.2-0.7l-0.4-0.1l-0.5,0.1l-0.6,0.3l-0.8-0.2l-1-0.6l-0.6-0.5l-0.4-0.2l-0.8-0.3l-0.8-0.1l-1.2,0.5l-1-0.2l-1.6-0.2l-1.3-0.4
            l-0.5-0.9l-0.3-0.6l-0.8-0.4l-0.6-0.5l-0.8-0.7l-0.4-0.2l-0.7-0.2l-0.6-0.3l-0.1-0.5l-0.2-0.6l-0.1-0.1l-0.4-0.7l-0.2-0.1l-0.2,0.1
            l-0.2,0.1l-0.2-0.1l0-0.3l0.1-0.5l-0.2-0.5l-0.6-0.6l-0.3-0.6l0-0.6l-0.1-0.4l-0.2-0.2l-0.1-0.4l0-0.5l0.2-0.6l0.4-0.7l-0.1-1.1
            l-0.6-1.5l-0.3-1l-0.1-0.6l0.1-0.5l0.3-0.5l0.7-1.1l0.1-0.9l-0.2-1l0-0.7l0.2-0.5l-0.2-0.7l-0.5-0.6l-0.3,0l-0.4,0.2l-0.5,0.5
            l-0.6,0.1l-0.6-0.3l-1.8,0.1l-2.7,0.3l-1.1,0.3l-0.6,0.1l-0.8,0.1l-1.3,0.6l-3.1,2.4l-0.6,0.3l-0.7,0l-0.4,0.3l-0.7,0.3l-1.3,0.9
            l-0.7,0.8l-0.4,0.7l-0.5,0l-0.4-0.2l-1.3,0.1l-0.6,0.2l-0.7,0.7l-0.9,1.2l-0.4,0.3l-0.7,0.4l-0.8,0.3l-1,0.4l-0.4,0l-0.4,0.1
            l-0.3-0.2l-0.1-0.4l-0.5-0.3l-1.2-0.1l-1.8-0.3l-1.9-0.1l-0.9,0l-0.8,0l-1.3-0.2l-1,0l-0.9,0.4l-1.2,0.5l-0.6,0.1l-0.4,0l-0.4-0.1
            l-0.5-0.3l-0.7-0.6l-0.5,0.2l-0.4,0.3l0-1.4l0-2.1l0-1.8l0-1.5l0-1.7l0-1.2l0.3-0.5l0.2-0.5l-0.3-0.7l0.1-0.6l0.2-0.4l0.2-0.4
            l-0.5,0.2l-0.2,0.1l-0.3,0.3l-0.7,0.4l-0.5,0.4l-0.6,0.4l-0.7,0.8l-0.6,0.4l-0.6,0.3l-1,0.9l-0.8,0.2l-2.2,0.1l-2.3,0l-2.1,0l-0.3,0
            l0-0.8l0.1-0.5l-0.7-0.6l0-0.7l-0.2-0.5l-0.2-0.6l-0.5-0.4l-0.7-0.1l-1.1-0.3l-1.7-0.3l-1.6,0l-1.6,0l1-1.2l1-1.2l0-1l-0.8-0.9
            l-0.4-0.6l-0.7-0.8l-0.8-0.5l-0.4-0.7l0-0.4l-0.1-0.3l-0.3-0.2l-0.3-0.3l-0.4-0.3l-0.5-0.4v-0.4l-0.2-0.4l-0.3-0.5v-0.4l-0.3-0.6
            l-0.3-0.4l-0.3-0.3l-0.4-0.4l0-0.3l0.3-0.2l0.1-0.4l0-0.4l-0.4-0.2l-0.4-0.1l-0.6-0.7l-0.4-0.4l-0.3-0.2l-0.2-0.2l0.2-0.2l0.2-0.2
            l0-0.4l-0.1-0.4v-0.3l0.3-0.1l0.3,0l0.3-0.2l0.4-0.1l0.4,0l0.2-0.2l0-0.4l-0.3-0.7l-0.3-0.3l-0.1-0.4l0.2-0.9l0.1-0.6l0.5-0.6
            l1.6-1.2l1.4-0.8l0.7-0.1l0.5-0.3l0.3-0.5l0.1-0.5l-0.1-0.5l-0.3-0.7l-0.3-0.8l-0.2-0.5l0.2-0.6l0.4-0.8l0.8-1.2l0.7-1.3l0.1-0.4
            l0.1-0.8l0.3-1.6l0.2-0.8l-0.1-0.3l0.2-0.3l0.4-0.2l1.1-0.2l0.7-0.5l1.1-0.9l0.9-0.9l0.8-0.3l1.4-0.9l0.8-0.5l0.3-0.2l0.8-0.4
            l1.4-0.1l1.2-0.1l0.7-0.3l1-0.1l0.7-0.3l0.7,0l1.4-0.3l0.5-0.4l0.4-0.5l0.5-0.5l0.6-0.1l0.7,0.1l0.8,0l1-0.1l0.5,0.4l0.2,0.4
            l0.6,0.4l0.4,0l0.4-0.1l0.6,0.4l0.4,0l0.3-0.2l0-0.5l0.1-0.3l0.3-1.6l0.5-2.7l0.4-2.5l0.5-2.7l0.5-2.8l0.5-2.8l0.4-2.3l0.4-2
            l0.2-1.2l0.4-1.6l0.1-1.4l0.1-0.4l-0.1-0.3l-0.3-0.5l0-0.2l0-0.3l0-0.2l-0.3-0.3l-0.2-0.2L385,286l-0.1-0.3l-0.2-0.3l-0.1-0.3
            l-0.2-0.3l-0.1-0.3l0.2-0.3l0.1-0.3l-0.1-0.3l-0.1-0.4l-0.2-0.4l-0.3-0.2l-0.6-0.2l-0.7-0.6l-0.8-0.5l-1-1l-0.2-0.5l0-1.3l0-1.4
            l0.1-2.1l0-1.1l0.6-0.1l0.5,0l0.5-0.1l0.4-0.1l0.4-0.2l0.3-0.2l0.4-0.1l0.3,0l0.3-0.2l0.3-0.2l0.3-0.1l0.4-0.1l0.4,0.3l0.2,0.3
            l0.3,0.1l0.3,0l0.2,0l0.2,0.2l0.2,0l0.3,0l0.3,0l0.1-0.1l0-0.1l-0.1-0.2l-0.1-0.2l0.1-0.3l0-0.4l0-0.5l-0.2-0.3l-0.2-0.5l-0.3-0.4
            l-0.4-0.3l-0.4-0.1l-0.3,0.2l-0.3,0l-0.2-0.2l-0.4,0l-0.4-0.1l-0.4-0.1l-0.8,0.1l-0.3-0.1l-0.4,0l-0.4,0.2l0-2l0-1.9l0-1.3l0.4,0
            l0.5-0.2l0.7,0l0.6-0.3l0.3,0l0.6,0.1l0.6,0.3l0.6,0h1.6h1.7h1.9h1.9h1.6l0.5,0l-0.3-0.4l-0.3-0.6l0.1-0.4l0.2-0.5l0.2-0.2l0.5,0.3
            l0.4,0.8l0.4,0.6l0.3,0.3l0.4,0l0.5-0.1l0.5-0.2l0.8-1.1l0.8-0.9l0.4-0.3l0.5-0.3l0.3-0.1l0.5,0l0.4,0.2l0.3,0.4l0.9,1.5l0.7,1.1
            l0.2,0.7l0,1.7l0,1.5l0.1,0.2l0.1,0.1l1.5-0.4l2.1,1.9l1.5,1.4l0.7,0.4l0.4,0.1l0.9-0.1l1.1-0.2l0.5-0.2l0.6-0.4l0.9-0.6l0.8-0.3
            l0.3,0l0.3,0.1l0.6,0.4l0.3,0.7l-0.3,0.8l0.1,0.5l0.7,0l0.5-0.8l0.4-0.6l0.8-0.5l0.8-0.7l0.5-0.7l0.6-0.4l0.9-0.5l0.7-0.3l0.7,0
            l0.5-0.3l0.7-0.6l0.5-0.5l0.3-0.2l0.7,0l0.8-0.1l0.8-0.6l0.7-0.7l0.4-1.2l0.3-1.1l0.2-0.2l0.3-0.2l0.3-0.1l0.7-0.1l1.3-0.6l0.9-0.6
            l0.9-0.1l0.3-0.2l0.3-0.5l0.2-0.9l-0.1-0.6l-1.6-0.2h-1l-1.7-0.1l-0.8-0.2l-0.2-0.2l0-0.2l0.2-0.4l0.2-0.8l-0.2-1l-0.9-1.6l-0.6-1.6
            l-0.1-1.1l0-1.2l0-0.8l-0.4-0.6l-2.4-1.9l-0.8-0.9l-0.3-0.6l-0.9-1.2l0.2-0.4l0.5,0l0.5,0.3l0.4,0.6l0.3,0.1h0.4l2.2,0l0.5,0.1
            l0.3,0.2l0.3,0.3l0.4,0.7l0.4,0.4l0.9,0l1.4,0l0.8-0.1l0.5,0.2l0.6,0.2l1.2-0.4l0.3,0l0.4,0.2l1.3,1.3l0.7,0.6l0.6,0.7l0.9,0
            l0.7-0.6l0.2-2.2l0.2-0.6l0.4-0.2l0.4,0l0.5-0.3l0.6-0.4l0.5-0.1l2.1,0.5l0.6-0.2l2.1-0.6l2.2-0.7l0.6-0.9l0.9-0.2l0.7-0.7l0.6,0.1
            l0.9,0l0.5-0.1l0.3-0.1l0.3-0.3l0.5-0.9l0.6-0.3l0.7-0.4l0.5-0.4l0.4-0.5l0.2-0.5l0-0.4l-0.2-0.7l-0.3-0.7l-0.3-0.2l-0.2-0.1
            l0.7-0.2l0.6,0.2l0.9,0l0.4-0.2l0.6,0.1l0.8-0.5l0.5,0.2h0.3l0.3,0.4l0.2,0.4l0.7,0.5l-0.1,0.7l-0.1,0.7l-0.1,0.8l0,0.6l-0.3,0.6
            l-0.4,0.6l-0.1,0.2l-0.1,0.3l0.3,0.2l0.5,0.1l0.7,0l0.5,0.2l0.6,0l0.7,0.5l0.3,0.3l0,0.2l-0.2,0.5l-0.1,0.5l0.2,0.3l0.2,0.2l0.6,1.1
            l0.3,0.4l0.2,0.1l0.1,0.2l-0.2,0.4l-0.2,0.5l-0.5,0.5l-0.1,0.4l-0.4,0.3l-1,0.6l0.2,1l0,0.5l0,0.4l-0.3,0.5l-0.6,1.6l-0.2,0.8
            l-0.2,1.8l0,0.6l0.3,0.8l0.6,1.8l0.3,0.3l0.8,0.4l0.1,1.2l-0.1,1.3l0,0.5l0.1,0.2l0.3,0.1l0.2,0.2l0,0.4l0,0.4l0.6,0.2l0.5,0.1
            l0.5,0.5l0.8,0.8l0.3,0.2l0.2,0.3l0.7,0.7l1.1,0.3l1.1,0.3l0.4,0.4l0.4,0.4l0.3,0l0.3-0.1l0.5-0.3l0.4-0.3l0.6,0l0.8,0l0.1-0.2
            l0.1-0.3l-0.2-0.7l0.3-0.2l0.6-0.1l0.1-0.4l0.1-0.2l0.2-0.2l0.2,0l0.3,0.1l0.4,0.1l0.5,0.1l0.2,0.2l0.4,0l0.5,0l0.2-0.2l0.1-0.3
            l0.1-0.6l0.3,0l0.6-0.1l0.6-0.3l0.8,0l0.8,0l0.4-0.2l0.4-0.4l0.7-1.1l0.4-0.3l0.4-0.2l0.3,0l0.7-0.2l0.6-0.3l0.2,0.1l0.4,0.6
            l0.2,0.1l0.3,0l1.1,0.3l0.6-0.1l0.6-0.2l0.6-0.1l0.4,0.1l0.3-0.2L490.6,263L490.6,263z M540.6,263.1l-0.8,0.4l-0.5-0.1l-0.4-1
            l0.1-0.8l0.6-0.3l0.5,0.1l0.2,0.1l0.4,1.3L540.6,263.1z M541.8,275.5l-0.9,0.3l-0.2-0.3v-1l0.2-0.6l1.1-0.2l0.1-0.2l0.3-0.1l0.2,0.3
            l0,0.6L541.8,275.5L541.8,275.5z M539.6,277.5l-0.1,1.2l-1.5-0.5l0.1-1.2l0.7-0.3l0.6-0.6l0.2-0.8l0-1.1l0.2-0.2l0.2-0.1l0.2,0.1
            l0.1,1.6l0.1,1L539.6,277.5L539.6,277.5z M545.1,276.5l0.3,0.4l-1.1,1.7l-0.6,0.2l-0.7,0l-0.9-0.5l-1.4,0l-0.4-0.1l0-0.7l0.6-0.8
            l1.2,0l2-0.6L545.1,276.5L545.1,276.5z M547.5,279.6l-2.1,0.3l-1-0.4l0.2-0.3l0.7-0.5l0.9-0.4l0.8-0.2l0.8,0.2l0.2,0.5l-0.1,0.5
            L547.5,279.6L547.5,279.6z M537.7,279.7l-2.2,1.6l-0.7-0.5l-0.2-0.3l0.1-0.3l0-0.1l0.2-0.5l1.2-0.4l0.6-0.1l0.8,0.1l0.1,0.4
            L537.7,279.7L537.7,279.7z M546,280.5l0.8,0l1.1-0.2l0.7-0.4l0.8-0.1l0.8,0l2.7,0.4l1.6,0.1l0.6,0.1l0.6,0.2l0.4,0.2l0.1,0.4
            l-0.4,0.7l-0.3,0.7l-0.3,1l-0.2,0.2l-0.4-0.1l0.2,0.9l-0.1,0.4l-0.2,0.4l-0.4,0.8l-0.7,1l-0.2,0.2l-0.5,0.3l-0.4,0.4l0.1,0.4
            l0.2,0.4l-0.2,0.5l-0.8,0.7l-0.5,0.2l-0.4,0.1l-0.4-0.1l-0.7-0.7l-0.1,0.6l-0.2,0.6l-0.2,0.3l-0.9,0l-0.5-0.3l-0.8-0.4l-0.2,1
            l-0.5,0.7l-0.5,0.2l-0.8,0.1l-0.5,0.3l-0.9-0.2l-0.8-0.4l-0.5,0l-0.4,0.4l-1.9,0.1l-0.9,0.4l-0.5-0.1l-0.8-0.7l-0.1-0.5l-0.5-1
            l-0.4-1.2l-0.3-1.1l0.2-0.9l0.5,0l0.6,0.1l0.1-0.1l0-0.3l-0.1-0.2l-0.9,0l-0.6-0.5l-0.1-0.8l0.1-1.7l0.1-0.4l0.4-0.5l0.1-0.4
            l-0.1-0.5l0.2-0.9l0.4-0.7l1.5-0.9l1.7-0.3L546,280.5L546,280.5z M528.2,290.2l-0.9,0.2l1.1-2l1-0.9l0-1.9l1.1-1.7l1-0.7l1.4-0.2
            l0.8,1l-0.9,2.9l-0.3,0l-1.3,1.5l-1.4,1.1L528.2,290.2z M584.4,289.2l-0.5,0.4l-0.2,0.2l-0.4-0.1l0.1-0.2l0.1,0l0.1-0.6l0.7,0.1
            L584.4,289.2L584.4,289.2z M587.5,302.3l-0.8,0.8l0.3-0.9l-0.1-0.6l0.1-0.5l0.5-0.5l0.2-0.1l0,0.6l0,0.2L587.5,302.3L587.5,302.3z
             M634.1,384.8l-0.3,0.2l0-0.5l0.8-0.7l0.1-0.8l0.4,0.4l0.1,0.4l0,0.2L634.1,384.8L634.1,384.8z M632.8,387.9l-0.3,0.5l-0.3-0.1
            l-0.1-0.3l-0.2-0.3l0.1-0.2l0.2-0.1l0.6,0L632.8,387.9z M590.5,469.9l0.3,0.2l-0.5,0l-0.5,0.2l-0.8,0.2l-0.3-0.4l0.7-0.5l0.3-0.4
            l0.2,0.1l0.2,0.3L590.5,469.9L590.5,469.9z M581.4,476.5l0,0.5l-0.3-0.2l-0.9,0.2l-0.3-0.3l1.2-1.5l0.2,0.2l0.2,0.3l0.1,0.4
            l-0.1,0.2L581.4,476.5L581.4,476.5z M554.5,498.8l-0.1,0.1l-0.5-1.1l1-1.1l0.3,0.4l-0.3,0.8l-0.3,0.6L554.5,498.8L554.5,498.8z
             M555.3,511.2l-0.6,0.4l0.1-2.2l0.3-0.7l0.3-0.5l0.4-0.3l0.3,0.5l-0.3,1l-0.7,1.3L555.3,511.2L555.3,511.2z"/>
    </a>
    <a xlink:title="Bahamas">
        <path id="BS" d="M322.8,62.9l-0.2,0.1l-0.7-0.9l-0.6-0.3l0.9-0.6l0.4-0.5l0-1.2l0.2-0.7l-0.1-0.6l0.2-0.6l-0.3-0.6l-0.7-0.5l-1.5-2
            l-2.3-0.5l-1.2,0l0.7-0.3l0.6,0l0.9,0.2l1.1,0.1l0.7,0.6l0.7,0.8l0.6,0.3l0.2,0.2l0,0.2l0.1,0.2l0.8,0.4l0.8,0.6l0.2,1.8l-1,0.8
            l-0.2,2.6L322.8,62.9L322.8,62.9z M312.5,55.5l1,0.3l0.5,0l0.3-0.2l1.4,0.1l1.2-0.3l0.2,0.5l0,0.3l-2.5,0.2l-2.3,0.7l-1.3,0.5
            l-0.6,0.1l-0.4-0.3l-1.5-1.4L309,56l1.1,0.8l0.7-0.1l0.6-0.5l0.1-0.4l-0.1-0.2l0.3-0.6L312.5,55.5L312.5,55.5z M327.5,66.7l1.3,1
            l1.1,0.4l1.2,1.3l0.5,0.4l0.1,0.4l-0.2,1.9l-0.3,1.1l0,1l-0.3-0.3l-0.3-0.6l-0.5-0.4l-0.2-0.2l0.9,0l0.1-1l0.4-0.8l-0.1-0.8l-1-0.9
            l-0.7-0.8l-1.1-0.3l-1-0.8l-0.6-0.1l-0.7,0.1l0.3-0.5l0.2-0.6l0.1-0.1L327.5,66.7z M318.6,73.6l0,1.1l0.1,0.8l-0.1,0.3l-0.9,0.5
            l-0.2,0.3l-0.8,0.3l-0.5,0.4l-0.3-0.7l-0.5-0.4l-0.1-0.7l-0.4,0.2l-0.5-0.1l-0.9-0.5l-0.6-0.7l0.8-0.1l0.2,0.5l0.6-0.6l-0.1-0.3
            l-0.1,0l-0.2-0.6l0.9-1.4l0.2-0.9l-0.4-1.5l0.4-0.1l1,0.5l0.5,0.5l0,0.7l0.4,0.5l0.6,1.3L318.6,73.6L318.6,73.6z M321.8,70.9
            l-0.9,0.2l-0.7-0.2l-0.2-0.1l0.3-0.2l0.6-0.2l1,0l0.4,0.2l0.1,0.1L321.8,70.9L321.8,70.9z M338.3,78.1l0.1,0.4l-0.5-0.1l-0.8,0.2
            l-0.3,0l0.2-0.3l0.6-0.4l0-0.4l-0.7-0.6l-0.8-1.4l-0.4-0.3l-0.2-0.5l-0.7-0.6l0.1-0.3l0.1-0.1l0.5,0.1l1,2l0.1,0.2L338.3,78.1z
             M319.3,77.6l0,0.2l-0.8,0.6l0.6,0.4l0.5-0.9l0.4,0.7l0.2,1.3l0,0.2l0,0.2l0.1,0.3l0,0.4l-0.4,1.1l-1.6-0.1l0-1l-0.2-0.2l-0.4-1.4
            l-0.5-0.4l-0.7-1.1l0.4-0.3l0.5,0.1l0.3-0.1l0.7-0.1l0.5-0.2L319.3,77.6L319.3,77.6z M345.4,79.2l-0.6,1l-0.3-0.1l0.2-1.2l0.4-0.2
            l0.2,0L345.4,79.2L345.4,79.2z M335.4,84.7l-0.3,0.1l-0.6-0.2l-1.4-1.1l-0.7-0.1l0.2-0.6l0.5,0.2l1.1,0.9l0.4,0.5L335.4,84.7z
             M342.1,89.5l-0.1,0.2l-1-1.7l-1.3-0.4l-0.7-0.4l0.2-0.2l0.5-0.1l0.1-0.6l-0.2-0.6l-0.7-1.2l-0.4-0.8l-0.2-0.2l-0.1-0.7l0.8,1.1
            l0.3,0.9l0.5,0.9l0.4,1.6l1,0.5l0.7,0.8L342.1,89.5L342.1,89.5z M348.4,91l0.2,0.2l-0.5,0.4l-1.2-0.4l-0.3,0l-0.2-0.5l-0.1-0.3
            l0.1-0.3l0.7,0.2l0.4,0.5L348.4,91L348.4,91z M347.2,95.4l-0.6,0.3l0.1-0.4l1.1-0.8l0.6-0.7l0.3-0.2l0.1-0.2l0.5-0.2l0.2-0.4
            l-0.1-0.4l-0.5-0.6l0-0.4l0.2-0.3l0.8-0.1l-0.2,0.4l0.3,1.2l-1.1,1.5l-1,0.5L347.2,95.4L347.2,95.4z M356.6,93.6l0.5,0.1l0.3,0
            l0.9,0.3l0.6,0.4l0.1,0.1l-0.3,0.3l-0.9-0.6l-0.7-0.1l-1,0l-0.4-0.1l0.3-0.7L356.6,93.6L356.6,93.6z M357.6,101.6l-1.1,0.4l-0.1-0.5
            l0.6-0.4L357.6,101.6L357.6,101.6z M356.7,104.3l-0.3,0.6l-0.9,1.2l-1.9,0.3l-2.1,0.1l-0.2-0.3l0-0.3l0.2-0.5l0-0.2l-0.1-0.2
            l0.8-0.2l0.5-0.6l0.8-0.1l1,0.4l0.5,0l0.8-0.4l0.6-0.9l0.4,0.1L356.7,104.3L356.7,104.3z"/>
    </a>
    <a xlink:title="Belice">
        <path id="BZ" d="M233.2,127.5l-0.4,1l0.4,0.1l0.4-0.1l0.9,0l0.4,1.1l-0.1,0.9l-0.9,2.3l-0.1,0.8l-0.4,1.2l0.6,0.8l-0.5,1l-0.2,0.7
            l0,1l0.3,1.9l-0.4,2.8l-0.7,1.2l-0.5,0.5l-0.8,1.2l-1.1,0.4l-1.5,1.9l-0.3,0.5l0.1,0.5l-0.3,0l-1.4-0.1l-1,0.1l0,0l0.1-2.1l0.1-3.2
            l0.1-2.4l0.1-2.3l0.1-1.7l0.1-2.4l0.1-2l0-0.7l0.2-0.6l0.7-0.2l0.9,0.5l0.4,0.2l0.3-0.1l0.4-0.3l0.5-0.9l1.3-1.9l0.5-1.3l0.5-0.3
            l0.7,0L233.2,127.5L233.2,127.5z M236,132.2l-0.4,0.2l0.3-0.5l0-0.3l0.4-1.3l0.3,0l0.1,0.1L236,132.2L236,132.2z M236.8,136.4
            l-0.6,1.2l0-0.3l0.3-0.9l0.4-0.3l0.2-0.3l0-0.4l0.3,0.2l-0.1,0.4L236.8,136.4L236.8,136.4z"/>
    </a>
    <a xlink:title="Chile" (click)="redirectToSearch(4)" [ngClass]="isAvailable(4)">
        <path id="CL" d="M403.9,467.1l1.5,1.6l-0.7,2.1l-1,3.4l-0.8,2.6l-0.1,0.4l-0.2,0.5l-1.7,0.8l-2.5,1.1l-1.3,0.6l-1.6,0.7l-0.4,0.6
            l-0.5,0.3l-0.5,0.4l-0.2,0.5l-0.5,0.3l-0.4,1v0.8l0.3,0.5l0.5,0.2l0.2,0.7l0.2,0.5l0.4,0.4l-0.1,0.3l-0.3,0.2l-0.5,0.1l-0.4,0.7
            l-0.4,1.6l-0.1,0.6l0.5,1.5l0.3,0.8l0.7,2.9l0.1,0.8l-0.9,1.1l-0.4,0.7l-0.1,0.6l0,0.5l0.1,0.4l0.8,1.4l0.9,1.2l0.4,0.6v0.9
            l-0.2,0.5l-0.5,0.2l-1.1,0.3l-0.4,0.5l-0.5,0.1l-0.5-0.4l-0.5,0.1l-0.6,0.3l-0.2,0.8l-0.5,1.4l-0.5,0.4l-0.3,1.1l-0.6,1.6l-0.3,1
            l-0.2,0.7l-0.6,0.4l-0.7,0.9l-0.6,0.9l-0.2,0.3l-0.4,0.1l-0.3,0.8l-1,1.2l-0.3,1.4l-0.4,0.7l-0.1,1.3l-0.6,2.4l-0.1,0.5l-0.6,0.4
            l-0.8,0.9l-0.3,0.7l0.4,2l0.4,2.1l0,1l-0.2,1.3l-0.1,0.6l0.3,0.2l0.5,0.2l0.2,0.5l-0.4,0.4l-0.2,0.6l-0.4,0.7l-1.2,0.3L380,535
            l-0.1,0.2l0.1,0.5l-0.3,0.6l-0.6,1.6l-0.4,1.5l-0.2,0.6l0.1,0.5l0.2,0.3l0,0.3l-0.3,0.4l-0.3,0.6l-0.3,0.1l-0.4-0.2l-0.4,0.3
            l-0.1,0.7l-0.2,0.9l-0.1,1l-0.2,1.3l0.5,0.9l0.6,1.7l0.5,0.4l0.5,0l0.4,0.3l0.2,0.4l-0.3,0.7l-0.5,0.1l-0.1,0.4l0.2,0.9l0.2,0.9
            l0.5,0.4l0.2,1.1l0.5,0.4l-0.1,1.5l0.5,1.7l0.5,0.5l0.2,0.2l-0.2,0.8l-0.4,0.6l-0.1,1l0.2,0.7l0.5,0.7l0.4,0.1l0.6-0.3l0.6,0.3
            l0.1,0.6l0.1,0.5l-0.3,0.7l-0.4,1.3l-0.1,1.3l0.1,1.9l0.2,1.5l0,0.9l0,0.4l-0.2,0.3l-0.5,0.1l-0.5,0.1l-0.4,0.2l-0.1,0.5l-0.3,0.8
            l-0.3,0.6l-0.6,0.9l-0.4,0.9l-0.3,0.6l0,0.4l-0.2,0.8l-0.2,0.7l-0.4,2.2l-0.6,0.5l-0.5,0.2l-0.2,0.3l0.2,0.6l0.5,0.2l0.2,0.5
            l-0.1,0.8l0.3,0.7l0,0.9l0.3,1.6l-0.3,1.1l0.1,0.9l0,0.9l-0.4,0.7l-0.9,0.1l-0.5,0.7l-0.8,0.7l-0.1,0.6l-0.1,0.5l-0.3,0.2h-0.5
            l-0.4,0.1l-0.6,0.7l-0.6,0.4l-0.1,0.5l0.1,0.7l-0.3,0.4l-0.4,0.8l-0.3,0.8l0.3,0.8l0.3,1.4l0,0.6l-0.4,1.1l-0.3,0.7l0.3,0.9l0.2,0.5
            l-0.2,1.2l-0.2,0.7l0.2,1.3l0.6,1.5l0.6,1.3l0.1,1.6l0.1,1.2l0.3,1.3l0.6,0.5l0.4,0.4l-0.1,0.6l-0.3,0.8l-0.4,0.6l-1.1,0.2l-0.9,0.5
            l-0.7,0.4l-0.5,0.4l-0.4,0.5l-0.2,0.5l0.1,2.3l-0.1,0.8l-0.4,1.2l-0.3,1l-0.1,0.3l0,0.4l-0.1,0.4l-0.4,0.1l-0.5-0.2l-0.3,0.1
            l-0.2,0.3l0.2,0.7l0.2,1.3l0.3,0.6l-0.1,0.4l-0.1,1l-0.4,0.8l-0.5,0l-0.3,0.3l-0.1,0.5l0.1,0.7l0.6,0.6l0.2,0.4l-0.1,0.5l-0.5,0.2
            l-0.3,0.4l-0.3,0.9l-0.4,1l-0.4,0.8l-0.1,1l0.6,1.1l-0.1,1.1l0,3.2l-0.1,1.1l0.2,1.8l-0.2,0.5l-0.1,0.5l0.5,1.3l0.6,2.1l0.2,0.8
            l-0.1,0.6l-0.8,0.5l-0.7,0.2l-0.4-0.4l-0.3,0.1l-0.3,0.6l-0.4,0.5l-0.1,0.5l0.4,0.7l0.2,1.3l-0.4,0.5l-0.3,0.6l0.1,0.8l0.1,1.4
            l-0.3,2.3l0.4,0.8l0.4,0.4l1.3,0.5l0.9,0.2l0.3,0.8l-0.1,0.6l-0.5,0.3l-0.7,0.3v1l0.6,1l0.7,0.7l0.1,0.6l0,0.6l-0.5,0.5l0.6,1.2
            l0.3,0.8l-0.3,0.6l-0.4,0.9l-0.4,0.4v0.5l-0.1,1l0,1l0.1,0.6l4,0.5l0.9,0.2l0.5,0.6l-0.1,0.7l-0.5,0.8l-0.3,1.5l-0.8,0.3l-0.8-0.4
            l-0.9,0.1l-0.7,0.1l-1.1,0l-1.4,0.2l-0.9-0.2l-0.1,0.6l0.3,1l1.9,0.3l1.7,0.6l0.5,1l0.7,1.1l0.7,0.7l0,1.2l-1.1,1.2l-0.1,0.9
            l-1.5,0.3l-0.4,0.5l-0.2,1.7l0.2,1.3l0.6,0.5l0.4,0.9l-0.4,1l-1,0.7l-0.5,0.7l0.3,0.5l0.5,0.9l0.1,0.5l0.2,1.3l0.3,1.8l0,0.9
            l-0.3,0.6l-1,1l-0.7,0.5l-0.1,1.2l-0.1,0.9l0.1,0.8l0.4,0.7l0,0.7l-0.6,0.1l-0.5,0.3l-0.5,1.2l-1.5,1.2l-0.5,0.6l0,0.9l-0.6,1.4
            l-0.5,1.2l-0.4,1.1l0.1,1.2l0.8,0.5l0.6,1.1l0.3,1.4l-0.5,1.7l-1.2,0.6l-0.7,0.7l-0.2,0.5l0.2,1.7l0,0.8l-0.2,0.8l-0.3,0.6l-0.6,0.7
            l-1.1,0.6l-0.9,0.4l-0.4,0.5l-0.5,1l-0.4,1.1l0.1,1.4l-2.6,0.2l-0.2,1l-0.6,0.8l-0.2,1.5l0.6,1.4l0.3,1.2l-0.5,1.5l0.2,1.5l0.1,1.2
            l0.9,1.3l0.6,1.7l0.3,1.4l0.2,1.1l0.2,0.7l0.4,0.8l0.2,0.9l0.6,0.3l1-0.8l0.7-0.6l0.5-0.2l1.5,0.1l0.9-0.5l0.4,0.1l0.5,0.3l0.4,0.6
            l0.3,1.4l0.2,1.5l-0.3,1.6l-0.4,0.3l-0.1,0.5l0.1,1l0.5,0.7l0,1l-0.5,2.2l-0.3,0.9l0.6,1l0.5,0.9l1.1,0.7l0.9,1l0.6,0.8l-0.1,1.1
            l0.4,0.3l1.6,0l2.4,0l3.8,0.1l3.7,0.1l4.2,0.1l2,0.9l1.8,0.8h2.3l2.3,0.9l1.7,0.6l1,0.2l1,0.2l0.1,0.9l-4.6-1.2l-1-0.7l-0.9-0.1
            l-1.7,0.8l-0.9,2l-0.5,0.6l-1.2,0.5l-1.2,0.1l-3.9,2l-1.4,0.2l-1,0.5l-0.9,0.7l-0.4,1.6l0.1,1l-1.1,3.5l-0.3,2l0,1l0.3,1.7l-0.4,2.8
            l-0.7,0.6l-1.7,0.8l-1.2-0.6l-2-0.5l-1.4-1.1l-1.9-0.8l-0.6-0.5L362,790l-0.2-0.7l-0.1-0.9l0.9-1.3l0.5-0.1l1.4,0l1.1-0.2l0.7,0.7
            l0.2,1.5l-0.3,0.8l-0.3,0.5l0.1,0.4l0.8-0.5l0.4-3.4l2.8-1.7l0.9-1l0.9-1.5l0.1-0.4l0.1-0.6l-0.6-0.5l-1.3-0.6l-4.1,3.2l-1.9,0.8
            l-1.2,0.9l-1.5,1.6l-0.3,0.5l-0.3,1.1l-0.1,1.2l-1.4-0.6l-2.2-1.7l-0.4-0.6l0.4-0.9l0.7-0.7l0-2.5l0.2-0.9l0.5-0.7l0.8-0.8l0.4-0.2
            l0.4,0.3l0,0.6l1.4,0l2.7-2.2l1.1-0.1l1.5,0.5l1.7-0.3l0.3-0.2l0.3-0.5l-1.2-0.6l-1.2-0.3l-3.3-0.2l-0.7,0.2l-1,1.2l-0.3-0.3
            l-0.2-0.6l-1.1-0.4l-0.5,0.1l-0.5,0.6l0.1,0.9l-0.3,0.9l-1.1,0.9l-0.7,1.5l0,1.1l-0.1,0.6l-0.3,0.3l-0.5,0.4l-1.7-0.3l-1-1.2
            l-0.4-0.8l-1.1-0.9l2.4-1.1l0.9-0.6l0.8-1.4l0.6-0.9l-0.4-0.6l-0.5,0l0,1l-0.5,0.8l-1.1-0.4l-1.6,1.2l-1-0.3l-1.6,0.4l-0.8-0.7
            l-0.2-0.8l0.3-0.9l-0.3-1.5l-0.5-0.4l-0.5,0.1l-0.2-0.9L347,773l-0.2-0.4l-0.2-0.7l0.2-0.2l0.6,0.2l0.5,0.5l0.8,0.1l1.7,1l0.7-0.2
            l0.4-0.2l0.1-0.8l0-0.8h0.3l0.9,1l0.6-0.1l1.1,0.3l0.5-0.1l1-0.4l1.6-1.1l0.8-1.1l0.4-0.2l0.5,0.1l0.3,0.3l0,0.8l0.4,0.7l0.5,0.6
            l0.1,0.7l-0.1,0.7l-0.9,1l-0.2,0.3l0.3,0.4l0.4-0.2l0.5-0.5l0.3-0.7l0.1-0.4l0-0.5l0-0.6l-0.7-1.7l-0.1-0.4v-0.8l0.8-0.7l0.2-0.6
            l0-1.1l-0.4-0.8l-1.8-1.7l-3-1.7l-0.3,0.2l-0.2,0.3l0.3,0.2l0.4,0.1l2.6,1.4l0.7,0.8l0.4,0.2l0.5,0.5l-0.1,0.8l-2.7,0.8l-2.1,1.7
            l-1.6,1l-1.1-0.4l-0.5-1.1l-0.6-1.4l-0.8-0.8l-0.5,0.1l-0.4-0.1l-0.3-0.4l-0.6,0.3l-1.4-0.9l-0.4-0.4l1-1.3l1.1,0.5l0.3-3.7
            l-0.4-0.8l-1.5-0.9l-0.7,0.1l-1-0.1l-0.7-0.4l-0.8-0.2l-0.6-0.2l-0.8-0.6l-1-0.3l-1.4-2.3l-0.6-1.2l-0.3-1.3l2.1,0l1.2-0.2l0.3-0.6
            l-0.4-1.1l-0.6-0.8l0.4-0.8l0.6-0.6l0.7,0.3l1.6,1.3l0.3,0.9l1.1,2.8l0.3,0.3l0.1,0.3l2.4,1.6l0.3,0l-0.1-1.3l0.7-1.8l0.7-0.6l0.3,0
            l0-0.3l-0.6-0.7l0.3-1l-0.2,0l-0.6,0.6l-1.1,3.1l-0.7,0.6l-1-1.4l-0.5-1l-0.3-0.4l0.1-1.6l1.9,0.3l-0.7-0.5l-2.2-0.9l-0.6-0.4
            l-0.4-0.2l-0.7-1.1l-0.9-0.9l1.6-1.6l0.8-1.1l2.5,0.6l0.5-0.4l-0.4-0.8l-0.5,0.3l-0.8-0.5l-1.2-1.6l0-0.8l0.2-1.4l0.5-0.3l1-0.3
            l1.2,0.5l0.5,0.4l0.4-0.2l-0.5-1.1l-0.8-0.4l-0.9-0.8l0.1-0.8l0.3-0.7l0.2-0.8l0.1-1.1l-0.1-0.8l0.2-0.4l0.4-0.2l0-0.3l-0.7,0.1
            l-0.3,1l-0.1,1l-0.5,0.8l-0.2,0.9l-0.1,1l-0.3,1.2l-0.6-0.5l-0.4-0.5l-0.1-0.3l0.1-0.6l-0.2-3.8l0-3.1l0.3-2.4l0.9-1l0.4-0.3
            l0.4,0.1l0.6,0l0.4-0.3l-1.3-0.6l-0.8,0.3l-0.6,0.5l-1.1-0.3l-0.2-1.2l-0.6-1.1l-0.1-1.4l0-2l1.5,0.2l1.2,0.4l3.2,0l2.6,1.9l1.2-0.3
            l-0.1-0.4l-0.9-0.5l-0.6-1.1L352,720l-0.2-0.6l-0.1-0.7l-0.7-2.7l-0.3,0.1l-0.3,0.9l-0.5,1.5l-0.8,0.8l-1.2,0.3l-1.2,0.2l-1-0.3
            l-0.2-0.6l0-0.7l-0.4-0.4l-1.1-0.3l-0.3-0.2l-0.4-0.7l0.5-1l0.4-0.6l0.5,0.1l0.5,0.3l0.7,0.8l0.6,0.2l0.7-0.6l0.1-0.4l-0.5-0.3
            l-0.4-0.1l-0.7-0.3l-1.3-1.2l0.6-1.2l1.5-1.4l0.5-0.3l-0.4-1.2l0.5-1.3l-0.5-1.1l-0.8-1.1l-1.1-0.3l-0.2,0.3l0,0.5l0.2,0.4l-0.1,0.3
            l-0.3,0l-1.4-0.3l-1-0.8l-1.6-0.7l-0.2-0.5l-0.2-0.8l0.6-1.4l-0.3,0l-1,1.1l-1.6,0.6l-1.1,0.2l-0.5,0.4l-0.2,0.4l0.3,0.2l0.7,0.1
            l0.5,1.3l-0.1,0.5l-0.2,0.3l-0.5,0.1l-1.1-0.9l-0.6-1l0-0.8l0.4-1.1l1.8-1.5l0.5-0.6l1-0.7l1.4-1.6l1.2-0.9l-0.6-0.7l-0.6-1.1
            l0.1-1.5l2.5-0.6l1.1,0.3l1.4,0l0.8-0.4l0.6-0.1l1.2-0.4l0.5-0.6l0.1-0.4l0-0.4l-0.1-0.5l-0.2-1.2l0.2-0.4l0.5-0.5l0.7-0.2l0.3,0
            l0.8,0.4l-0.2,0.6l-0.3,0.8l-0.6,3.1l-0.3,0.7l-0.5,0.6l0.3,1.3l-0.5,0.9l-2.2,0.9l-0.3,0.1l0.2,0.3l1.3-0.1l1-0.2l1-0.8l0.3-1.2
            l0.4-2.4l0.5-0.3l0.6-0.1l0.3,0.6l-0.1,1.2l0,1.2l-0.8,3.6l-1,1.4l-0.1,0.4l0,0.4l0.8-0.1l0.6-0.8l0.4-1l0.4-1.4l0-1l0.1-0.6l0.2-2
            l0.3-1l0-1.4l-0.5-0.5l-0.8-0.3l-0.2-0.9l0.4-1.7l1.5,0l1.4-1.2l0.9-0.4l0.5,0.1l1.8,1.1l0.4,0l-0.1-0.4l-0.3-0.3l-0.7-0.4l-1.3-1.2
            l-1.8-0.2l0.3-1.6l0.3-1.4l0.9-0.2l1.4-0.5l2.7-2.1l0.5-1.6l0.1-1.8l-1.3-0.5l-1.4-1.2l-1.1-0.6l-1-0.8l0.2-1.2l0.1-1.9l1.3-0.4
            l0.6-2.6l-0.8-2l0.2-1.5l1.1-1.2l0.2-0.9l0.3-0.9l1-0.1l0-0.5l-0.1-0.9l-0.6-1.1l0-1.5l0.6-1.8l1,0.1l0.2-0.1l-0.7-1.1l-0.6-1.2
            l0.1-0.5l0.5-0.4l0.6-0.2l0.7,0.6l1,1.9l0.1-0.5l-0.4-2l-0.3-2.5l-1,0.3l-0.9-0.2l-0.3-0.4l-0.3-0.6l0.3-0.7l0.3-0.5l0.7-0.6
            l1.4-0.2l1-0.8l0.3-1.6l-0.3,0.2l-0.6,1.4l-0.9,0.5l-0.5-0.1l-0.6-0.3l-1.1-1.2l-0.6-0.3l-0.6,0l-0.5,0.3l-1.3,2.2l-0.5,0.4
            l-2.3,0.2l-0.8-0.3l-0.9-0.3l0.1-0.5l0.3-0.6l0.5-0.3l0-0.3l-0.7-0.1l-0.8-0.6l-0.4-0.8l-0.2-1.4l-0.7-2.2l-0.1-1.5l0.5-1.1l1.1-4.3
            l0.3-2.2l0.6-1.9l0-1.3l1.5-1.1l0.6-0.7l1.3-3.8l0.2-2.1l-2.1-6.2l-0.3-1.2l-0.1-1.5l0.5-2.4l0.1-0.9l-0.4-1.3l-1.2-2.2l0-1.1
            l0.5-1.1l-0.5-1.4l0.2-0.9l0.3-0.7l1.8,0.4l0.8-0.2l0.4-0.4l0.3-1.1l0.2-1.8l0.1-0.8l0.2-1.1l0.9-0.4l0.3-1.1l0.8-1.5l0.8-4.1l0.8-1
            l0.8-1.2l-0.3-1.7l0.5-0.8l0.5-0.6l0.4-1l0.6-1l1.3-1.4l0.3-1.7l1-3l0.2-1.9l0.3-1.3l-0.1-1.2l0.6-1.5l0.6-1.2l0.2-0.7l1.3-1.6
            l0.2-1.3l-0.5-0.9l0-1.3l-0.4-1.9l0.9-0.7l0.4-0.5l1.1-3l-0.1-1.2l0.3-1.4l-0.7-1.7l-0.1-3.8l-0.4-2.9l-0.7-3.1l0.1-1.7l-0.4-2.1
            l0-1.2l0.3-2.8l2.2-1.7l0.4-2l0.3-2.6l-0.1-1.9l-0.2-0.9l-1.1-1.4l-0.3-2.5l0.2-0.7l0.9-0.7l0.6-1l0.3-1.5l0.7-1.2l0.3-2.9l0.6-2.3
            l0.3-0.8l0.9-1l0.2-0.3l0.1-0.8l0-1.8l0.1-1.1l0.7-2.1l0.1-1l0.8-2.2l0.2-1.6l0.3-0.8l-0.1-0.9l0.2-2.1l-0.5-1.2l-0.1-0.7l0.7-2.1
            l0.4-0.5l0.7-1l0.3-1.1l0.1-0.7l-0.9-3.5l-0.1-1.2l0.2-2.8l0.3-1.8l-0.1-1.4l0.1-0.7l0.2-0.9l0.6-1.1l0.1-0.8l-0.2-0.3l-0.7-0.4
            l-0.6-1l0-1l0.2-0.7l0-1l0.9-0.2l0.5-0.6l0.5-1.1l0.6-2.6l0.3-3.2l0.3-1.9l0.2-0.9l0.2-2l0.3-1.3l0.1-1.2l-0.1-0.9l-0.9-4.5l0-1.7
            l0.4-2.6l0-3.6l-0.1-0.8l-0.3-0.8l-0.1-1.1l-0.5-1.9l-0.5-3.7l0-2l-0.2-1.7l-0.5-0.4l0.3-0.1l0.8-0.1l0.8,0l1-0.4l1.1-0.7l0.7-1
            l0.3-0.9v-0.8l-0.3-1l-0.1-0.7l0.4-0.3l1-0.1l0.8-0.6l0.6-0.6l0.1,1l1.1,1.3l0.4,1.4l0.3,0.2l1.5,0.7l0,0.2l-0.2,0.3l-0.2,0.4
            l0.2,0.5l0.3,0.7l0.1,0.6l0.2,0.6l0.2,1l0.1,0.9l0.4,1.3l0.1,0.8l0,0.5l0.3,0.5l0.6,0.6l0.8,0.6l0.6,0.7l0.5,0.5l0.6,0.4l0.5,0.3
            l0.2,0.2l0.1,0.2l-0.2,0.2l-0.7,0.9l-1,1.4l0,0.2l1,1l0.2,0.4l0,0.6l-0.3,0.7l-1,0.2l-0.2,0.2l0,0.2l0.2,0.3l0,0.7l0.4,0.7l-0.2,0.2
            l-0.4,0.3l0,0.3l0.1,0.4l0.4,0.3l1.6,1l0.1,0.1l0,0.1l-0.6,0.7l-0.1,0.4l0,0.7l0.1,0.4l0.2,0.2l0.8,0.2l1,1.6l0.9,1.5l0,1.3l0.1,1.5
            l0.6,1.2l0.1,0.9l0.2,1.1l0.7,0.6l0.3,1.3l0.1,0.7l0,0.4l0.6,1.4l0.1,1.2l-0.1,0.9l0,0.5l0.1,0.3l0.5,0.3l0.2,0.2l0.7,0.1l1,0
            l1.8-0.3L403.9,467.1L403.9,467.1z M350.7,666.4l-0.6,0.2l-0.6,0.1l-0.6-0.2l-1,0l-1-0.4l-0.9-0.6l-0.3-0.4l0.1-0.5l0.7-1.2l0.6-2.2
            l0.4-3.2l-0.3-1.2l0-0.5l0.2-0.6l0.1-0.6l0-0.6l0.1-0.6l0.7-1.2l0.1-0.5l0-0.6l0.3-1.2l-0.1-0.4l-0.3-0.3l0.2-0.3l2.5,0.9l1.6,0.2
            l0.1,0.9l0.3,0.7l0.2,1.3l0.3,0.3l-0.1,0.9l-0.8,0.4l0.1,0.9l0.4,0.8l-0.6,0.3l-0.7,0.2l-0.2,0.2l-0.5,0.2l-0.6,0.4l0.2,0.4l0.8,0.9
            l0.8,0.6l0.5,0.9l0.6,1l-0.3,0.6l-0.6,0.9l-0.9,0.6l-0.8,0.4l0.1,1.5L350.7,666.4L350.7,666.4z M343.5,669.3l-1.1,0.2l-0.3-0.3
            l0-0.3l0.2-0.2l0.6-0.2l0.4,0.2l0.2,0.3L343.5,669.3L343.5,669.3L343.5,669.3z M350.4,671.7l0.2,0.6l-0.4,0.1l-0.6-0.1l-0.3,0.4
            l-0.1,0.1l-1.3-0.4l-0.2-0.2l0-0.6l1.4,0l0.9-0.4l0.1,0.1L350.4,671.7L350.4,671.7z M351,678.1l-0.4,0.5l-0.6,0.1l-1,0.6l-0.1,0.5
            l0,0.6l0.7,0.7l0.3,0.8l0.4,1.3l0.3,1.2l0,0.4l0.1,0.6l0.5,1l0,0.4l-0.1,0.4l-0.2,0.8l-0.2,0.1l-0.5,0.1l0,0.5l-0.1,0.2l-1.4,0.1
            l-0.7-0.2l0.1-1.5l-0.9-0.6l-0.6-1l-0.7-1.7l-0.6-0.5l-0.6-1.3l-1-1.1l1.1-0.7l-0.2-1.3l0.6-0.4l1-0.4l0.7,0.4l0.6-0.1l0.3-0.3
            l-0.1-1.3l0.2-1l0.7-0.5l0.8-0.1l0.3,0.6l0.4,0.6l0.9,0.4l0,0.6L351,678.1L351,678.1z M357.1,682.4l-2,0.9l-1-0.3l-0.4-0.7l-0.2-0.6
            l-0.2-1l0.3-0.5l0.7-0.7l0.3-0.5l0.1-0.6l0-0.5l0.1-0.5l0.4-0.2l1.4,0.6l1.5,0.8l0.5,0.6l0.1,0.5l-0.7,1l-0.4,0.8L357.1,682.4
            L357.1,682.4z M351.9,682.9l-0.3,0.1l-0.2,0l-0.2-0.4l-0.1-0.5l-0.5-0.8l-0.1-0.4l0-0.4l0.3-0.6l0.4-0.2l0.3,0l0.4,0.7l0.1,0.8
            l0.1,0.8L351.9,682.9L351.9,682.9z M340.4,683.7l-0.2,0.2l-0.3-0.1l-0.2-0.4l-0.1-0.6l0.3-0.2h0.2l0.3,0.3l0.1,0.5L340.4,683.7
            L340.4,683.7z M346.3,692.9l-0.4,0.5l-0.8,0.2l-0.8-0.4l-0.9,0.2l-0.1-0.9l0.4-0.7l0.7-0.9l0.5-1.1l-0.1-1.6l0.4-0.4l0.2-0.6
            l0.9-0.3l0.2,1.2l-0.2,2.1l0.6,1.3l0.1,0.4l-0.1,0.5L346.3,692.9L346.3,692.9z M339.9,718.2l-0.6,0.2l-0.1-0.4l-0.5-0.7l0.5-0.4
            l0.9-0.4l0.7,0.1l0.6,0.3l0.1,0.4l-1.1,0.5l-0.3,0.3L339.9,718.2L339.9,718.2z M344.3,727.3l-0.2,0.3l-1-0.2l-1.7,0.3l-0.7-1.1
            l-0.3-1.8l-0.2-0.4l-0.4-1l-0.2-0.7l-0.4-1l-0.2-1.1l-0.1-0.3l0.4-0.6l1.8-0.6l0.6-1l0.6,0.1l-0.2,2.1l0.3,0.7l0.6,0.6l0.1,0.2
            l0.1,0.7l0.3,1.1l0.4,0.5l0.1,0.3l0,0.3l-0.1,0.4L344.3,727.3L344.3,727.3z M336.7,729.4l-0.9,0l-0.2-2.2l1.1-3.1l0.1-1.2l-0.4-0.9
            l-0.1-0.7l0.1-0.3l1.4-0.6l0.4,0.7l0.5,1.7l1,2.5l0,2.4l-0.5,0.6l-1.7,0.6L336.7,729.4z M345,734.1l0.1,1.8l-0.1,1.8l-0.3,2.2
            l0.1,0.5l0.4,0.1l0.1,0.3l-0.1,1.2l-0.2,0.9l-0.4,0.7l-0.2,0.9l-0.2,0.2l-0.9,0.2l-0.5-0.1l-0.4-1l-0.1-0.6l0-0.8l-0.5-1.1l0-0.4
            l0.2-0.7l0.4-0.4l0.1-1.1l0.2-0.3l0.4-0.5l0.1-0.2l0-0.2l-0.2,0l-1.7,1.4l-0.2,0.4l-0.1,0.5l0,1.8l-0.3,1.1l-0.3,0.2l-0.8,0
            l-1.1-0.1l-1.2-1l-0.8,0.3l-0.2-1.2l0.4-0.9l1.5,0.1l0.3-1.7l-0.5-0.4l-0.5-0.7l-0.3-0.6l0.3-0.5l0.9-0.7l0.5-0.1l0.4,0.4l1.1-0.3
            l-0.1-1l-0.9-0.5l0.2-0.8l1.2-0.8l0.7-0.8l0-0.9l-0.3-0.9l0.1-0.3l0.6-0.7l0.8-0.3l0.4,0l0.8,0.5l0.7,0.1l0.2,0.1l0.1,0.6L345,734.1
            L345,734.1z M339.9,730.3l-0.1,1l-1.2,1.9l-1,1.1l-0.9,0.9l-0.6,0l-0.5-0.4l0.6-0.7l0.7-0.7l-0.2-0.9l-0.2-0.3l-0.3-0.1l-0.5-0.5
            l0.2-0.7l0.3-0.3l0.4-0.3l0.4,0.1l1.6-0.5l0.5-0.4l1-0.1L339.9,730.3L339.9,730.3z M340.3,748.4l-1.6,1l-0.5-0.4l-1.1,0l0.3-1.1
            l0.1-0.8l0.2-0.3l0.1-0.7l0.3-1.3l0.9,0.4l0.7,0.1l1,0.4l1,0.3l0.3,1.1l-1,0.5L340.3,748.4L340.3,748.4z M338.3,753.3l-0.2,1.2
            l-0.7-0.1l-0.2-0.3l-0.1-0.7l-0.2-0.4l0.3-0.7l0.2-0.8l-0.1-0.6l1,0l1.2,0.2l0.3,0.2l-0.4,0.6l-0.3,0.3l-0.7,0.2L338.3,753.3
            L338.3,753.3z M344.4,760.9l0,1.1l-0.3,0.3l-0.2,0.1l-0.6-0.3l-0.3,0l-0.5,0.6L342,763l-0.7-0.1l-0.9-0.4l-0.8,1.6l-0.4,0.5
            l-0.8,0.8l-0.1-0.9l0.5-1.3l0.2-0.9l0.5-1.3l0.9,0.5l1.3-0.5l1.2-0.9l1,0l0.3,0.5L344.4,760.9L344.4,760.9z M342.2,765.5l0.3,2.5
            l0.2,0.4l0.8,0.2l0.9,1.3l0,0.4l-1.1,2.2l-0.2,1.6l-1.3-0.1l-0.5-1.6l-0.8-1.5l-0.3-1.8l-0.4-1.5l0.8-0.8l0.8,0.2l0-1.1L342.2,765.5
            L342.2,765.5z M347.7,769.4l-0.2,0.1l-0.9-0.3l-0.4-0.3l-0.7-0.7l-0.1-0.7l-0.3-0.8l0.2,0l0.7,0.3l0.3,0.3l0.4,0.5l1.2,0.8l0.1,0.2
            l0,0.3L347.7,769.4L347.7,769.4z M392.3,779l0,4l0,3.9l0,3.7l0,3.7l0,3.6l0,3.7l-0.1,4.2l0,3.2h-1.2l-0.3,0.3l-1.9,0.5l-3.3-0.7
            l-0.8-0.6l-1.1-1.4l-0.4,0.4l-1,0.6l-1.1,0.5l-0.9,0.1l-0.8-0.6l-0.2-0.3l-0.2-0.1l-1.7,0.8l-1.9-0.8l-1.5-0.5l-2.5-0.3l-1.7-1
            l-3.2,0.1l-0.6-0.3l-0.2-1l0.2-0.5l0.7-0.3l0.2-0.6l0.7,0.1l0.9,0.7l0.3,0l0.6-0.7l0.9-0.6l0.3-0.1l1.6,0.8l0.6-0.1l0.9-0.3l0.2-0.3
            l0.1-0.5l0.2-0.3l0.8-0.1l0.8,0.3l0.1,0.9l-0.1,1l1,0.3l1.3,0l0.9,0.4l0.1-0.6l-1.4-1.6l-0.6-1l-0.8-0.6l-1-0.3l-0.8-1.8l0.1-1.6
            l-0.1-1.5l1.8-0.8l-0.4-1.3l0.6-1l0.7-0.4l0.7,3.6l0.5,1.3l-0.7,0.3l-1.4,0l0.8,1.8l1.3,0.6l1.1,1.3l0,1l0.6,0.4l1.4,0l1-0.2
            l0.5-0.6l0.5-0.2l1,0.8l1.6,0.6l0.4,0.4l0.3,0.7l0,0.8l0.1,0.4l0.5-0.2l0.7-1l0.3-0.4l0.4-0.2l0.3-0.2l0-0.3l-1.2-0.7l-6.4-3.4
            l-0.8-1.3l-0.5-1.7l0-1.7l0.5-0.5l1.1-0.7l2.1-1l2.4-1.4l0.3-0.3l0-0.9l-0.3-0.6l-1-0.4l-1-0.1l-1,0l-1,0.2l-1.7,0.9l-1-0.1
            l-0.9-0.5l-0.7-1l-0.4-1.3l0-0.8l0.1-0.8l0.4-0.8l0.6-0.3l0.5,0l0.5-0.2l0.3-0.3l0.2-0.4l-0.1-0.3l-0.2-0.3l-0.8-0.6l-0.3-0.5
            l-0.7-0.9l0.4-0.2l1.2-0.1l0.8,0.6l0.8,0.7l0.5,0l0.4-0.3l1-0.9l0.8-1.1l0.7-1.3l0.6-0.8l0.7-0.1l2,2.4l0.7,0.1l2.3-1.3l0.3,0.1
            l0.8,0.7L392.3,779L392.3,779z M345.8,782.6l0.1,0.1l0.3,0l0.4,0.2l1.7,0.3l1.5,0.6l0.8,0.6l1,0.2l0.8,0.8l0.4,0.2l0.4,0.1l1.1,1.4
            l0.1,0.2l1.3,1.1l0.1,0.2l-0.7,0.1l-1.5-0.5l-0.7,0l-0.5-0.2l-0.1-0.1l-0.1-0.6l-0.2-0.3l-1.4-1.5l-0.6-0.3l-1-0.3l-1.2,0.2
            l-0.8-0.2l-0.3,0.1l-1.2-1.2l-1.2-1l-0.5-1.2l-0.7-0.9l0-0.3l0.3-0.2l0.8,0.5l0.8,0.9l0.4,0.3L345.8,782.6L345.8,782.6z
             M357.6,790.1l0.2,1.1l0.1,0.2l0.6-0.2l1-0.1l1.6,0.4l0.2,0.1l0.7,1.2l0.5,0.5l0.8,1.1l-0.8,0.7l-0.5,1.1l0,0.5l-0.3,0.3l-0.5,0.3
            l-0.7,0.6l-0.9,0.1l-0.9,0.3l-0.4,0.3l-0.2,0l-0.3-0.2l-0.3-0.3l-0.1-0.4l0.6-0.3l0.8-1.2l0.1-1.2l-0.9-0.2l-0.5,0.2l-0.4,0
            l-0.4-0.4l-0.3,0.6l-0.1,0.6l0.1,0.9l-0.1,0.3l-0.3,0.2l-0.7-0.3l-0.8-0.6l-0.1-0.3l0.2-1.1l0-0.6l-0.2-0.9l-0.1-0.1l-0.3,0
            l-0.9,0.2l-0.9-1.1l-0.5-1.2l-1.6-0.3l1.3-1.6l1.9-0.2l0.7,0.8l2.2,0.6l-0.1-0.9l0-0.3l0.3-0.4l0.2,0l0.3,0.3l0.4,0.1l0.2,0.3
            L357.6,790.1L357.6,790.1z M370,797.6l1.8,1.1l1.2,0l0,0.7l0.1,1.2l-0.2,0.5l-0.4,0.5l-0.3,0.7l-0.2,0.1l-1.3-0.8l-1.4-1.1l-0.7,0.2
            l-0.9-0.3l-0.7,0.1l-0.5,0.6l-1.1,0.3l-0.2-1.3l-1-1.2l-1-1l0.5-1.5l0.6-0.2l0.6-0.5l2.4,0.5l1.2,0.4L370,797.6z M373.2,809.2
            l0.4,0.9l0.1,0.2l1,0.3l0.4-0.2l1.1-0.1l0.7-0.3l0.9-0.2l1.1,2.2l-0.1,0.7l-0.9,0.7l-0.6,0.2l-0.5-0.2l0-0.4l-0.1-0.3l-0.4-0.4
            l-0.4,0l-0.6,0.3l-0.3,0l-0.6-0.3l-1-0.3l-0.2-0.3l0-0.5l-0.2-0.2l-1-0.7l-0.7-0.6l-0.6-0.1l-0.2,0.1l-0.2,0.3l-0.5,0.3l-0.1,0
            l-0.2-0.2l-0.1-0.3l0.2-0.7l0.3-0.1l1.4,0.1l0.9,0.3L373.2,809.2L373.2,809.2z M383.6,809.9l6.5,1.4l2-0.8l1.6,0l0.5,1.2l-1.6,1.2
            l-0.1,0.4l0.2,0.3l1.6,0.2l0.4,0.4l0.4,0.5l-0.3,0.8l0,0.3l0.2,0.3l1.2,1.1l0.5,0.6l0.3,0.6l0.1,1l0,0.8l-0.3,0.1l-0.6-0.2l-0.6-0.5
            l-0.5-1.1l-0.4-0.2l-1-0.2l-1-0.6l-0.8,0l-0.7-0.2l-0.7,0.2l-0.2-0.4l-0.3-0.8l0-0.3l0.3-1.2l0-0.3l-0.2,0l-0.8,0.2l-0.3-0.2
            l-0.8-0.9l-0.3-0.2l-0.8-0.1l-0.5,1.5l0,0.4l0.5,1l1,1.5l-0.5,0l-1.4-0.5l-0.4-0.3l-0.4-0.8l-0.8-0.4l-0.3-0.3l-0.1-0.3l0-1
            l-0.2-0.1l-1.2,0.3l-0.2-0.2l-0.1-0.4l-0.2-0.2l-0.8-0.4l-0.1-0.2l0.3-0.3l0.2-0.7l0.3-2.5L383.6,809.9L383.6,809.9z M404.9,813.2
            l-0.2,0.5l-0.5,0.7l-0.7,0.6l-0.7,0.2l-0.5-0.3l-0.2-0.5l-0.1-0.5l-0.2-0.3l-0.3-0.1l-0.3,0l-0.4,0.2l-0.9,0.7l-0.4,0.2l-0.2,0
            l-2.4-0.5l-0.2-0.2l-0.3-0.5l-0.3-1.4l-1-1.3l1.6-0.7l1.9,0l3.6,0.6l1.4,0.1l1.1,1.2l0.2,0.7L404.9,813.2L404.9,813.2z M409.8,814.3
            l-0.6,0.6l-0.5,0l-0.2-0.2l-0.1-0.3l0.1-0.3l0.2-0.3l0.5-0.3l0.1-0.1l0.7,0.3L409.8,814.3z M403.2,822.1l-0.3,0.1l-0.2-0.3l-0.3-0.2
            l-1.3-0.4l0-0.2l0.1-0.3l0.3-0.3l0.5-0.3l0.4-0.8l0.2,0.1l0.2,0.3l0.3,1.1l0.4,0.8l0,0.3L403.2,822.1L403.2,822.1z M400.9,823.7
            l-0.3,0l-0.7-0.3l-1.1-0.1l-0.1-0.1l0-0.2l0.1-0.2l0.6-0.2l1.8,0.1l0.2,0.1l0.1,0.2l-0.3,0.5L400.9,823.7L400.9,823.7z"/>
    </a>
    <a xlink:title="Colombia" (click)="redirectToSearch(5)" [ngClass]="isAvailable(5)">
        <path id="CO" d="M370.6,182.6l-0.3,0.1l-0.4,0.2l-1.1,0.4l-1.5,0.4l-1.9,0.5l-0.4,0.5l-1.9,3.3l-1.6,0.7l-0.6,0.5l-0.4,0.6l-1,1.2
            l-0.4,0.9l-1.1,1.9l-0.6,2.4l-0.2,1.4l-0.3,2l-0.5,1l-0.6,0.9l-0.7,0.9l-0.6,1l-0.5,0.8l-0.1,0.3l0.2,0.2l1.2-0.2l0.5-0.2l0.6-0.3
            l0.4,0.2l0.4,0.9l0.4,0.1l0.4-0.1l0.4,0.2l0.6,2.1l0.5,1.8l1.1,1.1l0.9,0.9l0.2,0.8l0.2,1.1l0.1,0.5l-0.3,0.3l-0.4,0.7l-0.1,1.3
            l-0.1,0.4l-0.1,1.2l0.1,0.7l0.2,0.6l0.4,0.3l0.8,0.2l0.7,0.2l0.4,1l0.6,1.2l0.6,0.5l0.9,0.3l0.7-0.1l1.5-0.2l1.3,0.1l1.9,0.3l0.7,0
            l0.9-0.1l1.6-0.7l0.6-0.1l0.7,0.1l1,0.3l0.5,0.3l0.7,0.3l1,0.2l0.6,0l0.5,0l0.3,0.1l1.5,1.9l1.3,1.7l1.2,1.4l1.3,1.5l0.1,0.1
            l0.6-0.2l0.4,0.1l0.3,0.3l0.6-0.1l0.8-0.6l1.2-0.1l1.6,0.3h2.1l2.7-0.3l1.7-0.4l0.6-0.4l1.1,0l1.3,0.3l0.7,0.5l0.1,0.5l0.3,0.8
            l-0.3,0.8l-0.8,0.8l-0.5,1l-0.1,1.2l-0.4,0.9l-0.8,0.6l-0.3,0.8l0.2,1.1l-0.1,1.6l-0.3,2.1v1.3l0.3,0.4l0.2,0.6l0,0.8l0.1,0.7
            l0.4,0.9l0.6,1.8l0.5,0.8l0.4,0.3l0.4,0.3l1.2,1.8l0.3,0.4l-0.1,0.3l-0.1,0.3l-0.1,0.2l-1.3,1.1l-2.6,2.4l-0.2,0.3l0,0.5l0.7-0.3
            l0.8,0.3l0.4,0.1l0.2,0.2l0.2,0.6l0.3,0.1l0.4,0.3l0.8,0.7l0.6,0.7l0.5,0.3l0.3,0.3l0.1,0.5l-0.1,0.5l0.4,1.1l0.3,0.3l0.1,0.4
            l-0.1,0.4l0.3,0.5l0.4,0.9l0.4,1.2l0.1,0.6l0.2,0.3l0.2,0.9l0.4,0.8l-0.1,0.5l0.2,0.5l-1.5,0.4l-0.1-0.1l-0.1-0.2l0-1.5l0-1.7
            l-0.2-0.7l-0.7-1.1l-0.9-1.5l-0.3-0.4l-0.4-0.2l-0.5,0l-0.3,0.1L401,262l-0.4,0.3l-0.8,0.9l-0.8,1.1l-0.5,0.2l-0.5,0.1l-0.4,0
            l-0.3-0.3l-0.4-0.6l-0.4-0.8l-0.5-0.3l-0.2,0.2l-0.2,0.5l-0.1,0.4l0.3,0.6l0.3,0.4l-0.5,0h-1.6h-1.9H390h-1.7h-1.6l-0.6,0l-0.6-0.3
            l-0.6-0.1l-0.3,0l-0.6,0.3l-0.7,0l-0.5,0.2l-0.4,0l0,1.3l0,1.9l0,2l0.4-0.2l0.4,0l0.3,0.1l0.8-0.1l0.4,0.1l0.4,0.1l0.4,0l0.2,0.2
            l0.3,0l0.3-0.2l0.4,0.1l0.4,0.3l0.3,0.4l0.2,0.5l0.2,0.3l0,0.5l0,0.4l-0.1,0.3l0.1,0.2l0.1,0.2l0,0.1l-0.1,0.1l-0.3,0l-0.3,0l-0.2,0
            l-0.2-0.2l-0.2,0l-0.3,0l-0.3-0.1l-0.2-0.3l-0.4-0.3l-0.4,0.1l-0.3,0.1l-0.3,0.2l-0.3,0.2l-0.3,0l-0.4,0.1l-0.3,0.2l-0.4,0.2
            l-0.4,0.1l-0.5,0.1l-0.5,0l-0.6,0.1l0,1.1l-0.1,2.1l0,1.4l0,1.3l0.2,0.5l1,1l0.8,0.5l0.7,0.6l0.6,0.2l0.3,0.2l0.2,0.4l0.1,0.4
            l0.1,0.3l-0.1,0.3l-0.2,0.3l0.1,0.3l0.2,0.3l0.1,0.3l0.2,0.3l0.1,0.3l0.2,0.2l0.2,0.2l0.3,0.3l0,0.2l0,0.3l0,0.2l0.3,0.5l0.1,0.3
            l-0.1,0.4l-0.1,1.4l-0.4,1.6l-0.2,1.2l-0.4,2l-0.4,2.3l-0.5,2.8l-0.5,2.8l-0.5,2.7l-0.4,2.5l-0.5,2.7l-0.3,1.6l-0.1,0.3l-0.4-0.6
            l-0.6-0.6l-0.6-0.3l-0.3-0.4l-0.3-0.9l-0.5-0.3l-0.3-0.2l-0.3,0l-0.3,0.2l-0.5,0.2l-0.4,0l-1.4-0.6l-0.2-0.1l0.9-1.4l1.6-2.5l1-1.6
            l1.2-1.8l0.6-0.9l0.1-0.2v-0.2l-0.3-0.3l-0.6-0.1l-0.6-0.3l-0.4-0.4l-0.6-0.2l-0.4-0.3l-0.8-0.3l-0.5-0.3L376,298l-0.5-0.5l-1.7-1
            l-0.4-0.1l-0.5,0.2l-0.7,0.2l-0.7,0.5l-0.8,0.2l-0.8,0l-0.4-0.3l-0.4-0.1l-0.5-0.4l-0.9-0.3l-0.7-0.2l-0.4,0.1l-0.5,0.5l-0.5,0.5
            l-0.4,0.3l-0.6,0l-0.7,0.4l-0.7,0.2l-0.7,0.1l-0.8,0.2l-0.9-0.3l-0.7-0.2l-0.3-0.1l-0.3,0.1l-0.4,0.3l-0.8,0.1l-0.6,0l-0.4-0.1
            l-0.4-0.4l-0.6-0.2l-0.7-0.3l-0.1-0.6l0.1-0.4l0.3-0.6l-0.1-0.6l-0.3-1l-0.1-0.4l-0.2-0.3l-0.3-0.1l-0.7,0.1l-0.7-0.4l-0.5-0.4
            l-0.2-0.4l0.2-0.8l-0.2-0.7l-0.4-0.4l-0.3-0.7l-0.4-0.5l-0.6-0.3l-0.6,0l-0.5-0.2l-0.5-0.6l-0.5-0.2l-0.5-0.6l-1-0.2l-0.5-0.2
            l-0.3-0.3l-0.4-0.6l0-0.3l-0.2-0.3l-0.2-0.6l-0.3-0.9l-0.4-0.5l-0.4-0.4l-0.3-0.3l-0.5-0.5l-0.6-0.3l-0.5-0.3l-0.2-0.4l-0.2-0.4
            l-0.3,0l-0.4,0l-0.5-0.1l-0.5-0.3l-0.4-0.3l-0.7-0.5l-0.4-0.1H339l-0.5,0.5l-1.4-0.5l-1.2-0.8l-1.3-0.2l-0.8-0.5l-0.8-0.8l-0.4-0.5
            l-0.3-0.3l-1.6-0.8l-0.3-0.1l-0.6,0.4l-0.2,0.2l0,0.6l-0.1,0.3l-0.5,0.2l-0.9,0l-0.6-0.2l-0.4,0l-0.1,0.2l-0.2,0.1l-0.5,0l-0.7-0.2
            l-0.7-0.2l-0.9-0.5l-0.4,0.1l-1-0.1l-0.8-0.3l-0.2-0.2l-0.4-1.7l-0.1-0.1l-0.4-0.1l-0.6-0.2l-0.4-0.3l-0.2-0.5l-0.2-0.4l-1,0.1
            l-1.7-0.6l-1.2-0.6l-1.1-0.6l-1.6-1.2l-0.6-0.3l-0.8-0.4l-0.4-0.6l-0.7-0.6l-0.2-0.2l-0.2-0.6l-1.1-0.8l0.5-1l1.3-0.8l1.7,0.6
            l0.2-1.2l-0.6-1.1l0.1-2l0.2-0.4l0.5-0.5l0.6-0.4l0.4-0.1l0.6,0.2l0.4-0.4l1.4,0.2l0.4-0.2l0.3-0.3l0.3-0.2l0.4-0.5l0.3-0.6l0.2-0.2
            l0.5,0.1l0-0.2l0.3-0.3l0.9-0.7l0-0.3l-0.2-0.7l0.1-0.3l0.5-0.1l0.6-0.2l0.3-0.7l0.4-0.6l0.4-0.9l0.5-0.1l0.3-1l0.7-0.9l1.3-2.6
            l-0.4,0.1l-0.3,0.4l-0.4,0l-0.4-0.2l0.1-1.2l-0.2-0.1l-0.6,0.9l-0.6-0.9l-0.1-0.6l0.2-0.6l0-0.4l-0.9,0.3l0-0.4l0.6-0.4l0.3-0.4
            l0.5-0.4l0.2-0.6l0.1-1l0.2-1l-0.2-0.5l-0.3-0.4l-0.2-1.9l0.1-1.1l-0.1-0.9l-0.2-0.7l-1.1-1l1.7-1.1l0.6-0.8l-0.8-1.7l-1-1.5l0-0.9
            l0.3,0.1l0.3,0l0.3-1.9l-0.1-0.6l-0.6-0.9l-0.7,0l-0.6-1.2l-0.4-0.3l-0.3-0.7l-1-1.4l-0.8-0.7l0.6-1.7l0.5-0.3l0.2-0.4l-0.2-1.1
            l0.1-0.2l0.1-0.1l0.1,0l0.2,0.2l0.4,0.5l0.3,0.6l0.3,0.2l0.4-0.2l1.5-1.1l-0.1-0.4l0.1-0.7l0.5-0.6l0.5-0.2l0.2-0.3l-0.1-0.5
            l-0.6-1.2l-0.5-0.7l-0.3-0.7l-0.2-0.6l-0.6-0.6l0.2-0.5l0.4-0.6l0.2-0.1l0.2,0.2l0.7,1.2l1.1,0.8l1.1,1.2l0.5,0.8l0.4,0.2l0.3,0.3
            l-0.1,0.2l-0.3,0.2l-0.1,0.5l0.2,0.3l0.2,0.2l0.7-0.1l0.4-0.6l-0.2-2.5l-0.4-1.3l-0.4-0.4l-0.4-0.5l0.3-0.4l0.7-0.2l0.9-0.4l3.3-2.4
            l1.1-2.3l0.9-0.8l1-0.5l1.2,0.1l0.9-0.3l0.3-0.7l-0.3-1l-0.4-0.6l0.3-0.9l0.4-1.3l0-1.1l0.5-0.6l-0.2-0.3l-0.7,0.5l-0.5,0.2l0.3-0.4
            l0.9-1.1l0.5-1.6l0.4-0.7l1.3-1l0.3-0.5l1-0.7l1.6-1.5l0.6-0.4l3.2,1l1-0.1l-0.2,0.2l-0.5,0.1l-0.7,0.3l-0.2,0.6l0.5,0.6l0.5,0.2
            l0.4-0.4l0.4-1.1l0.7-1.3l0.2-1.3l0.5-0.5l0.7-0.2l1.2,0.3l0.9,0.3l1,0l2.9-0.2l4.8-3.4l2.2-0.7l1.4-0.7l0.9-1.4l0.2-1.1l0.7-0.4
            h0.7l0.3-0.3l0.1-0.3l1.7-0.9l0.9-0.1l0.8,0l1.9,0.8l0.9,1.4l0.1,1l-1.2,1.1L370.6,182.6L370.6,182.6z M315.6,258.2l-0.2,0.2
            l-0.4-0.3l-0.1-0.4l0.3-0.3l0.3,0.1l0.1,0.2L315.6,258.2z"/>
    </a>
    <a xlink:title="Costa Rica" (click)="redirectToSearch(6)" [ngClass]="isAvailable(6)">
        <path id="CR" d="M270.9,190.4l0.2,0.3l0.2,0.5l0.1,0.7l1,2.2l0.8,1.2l1.8,2.2l0.8,0.4l1.3,1.8l0.5,0.3l0.3,0.5l1.4,0.4l0.4,0.3
            l0,0.1l-0.1,0.2l-0.2,0.2l-0.3,0.1l-0.6-0.3l-0.6-0.4l-0.3,0.2l-0.1,0.5l-0.2,0.2l-0.3,0.1l-0.1,0.2l0,1.6l0,1.5l0.5,0l0.8,0.5
            l0.3,0.3l0.1,0.3l-0.1,0.1l-0.6,0.3l-0.6,0.4l-0.3,0.5l0.5,0.9l0.1,0.6l0,0.6l-0.1,0.3l-1.1,0.7l-0.2,0.2l0,0.2l0.6,0.5l0.3,0.5
            l0.2,0.6l0,0.5l-0.6-0.9l-0.8-0.9l-0.7-0.5l-0.1-1.2l-0.3-0.7l-1-0.6l-0.9-0.4l-0.6,0.1l0.4,0.7l1,0.9l0.1,0.3l0,0.5l-0.7-0.1
            l-0.6-0.2l-0.7-0.1l-0.5-0.3l-1-1.1l0.7-0.9l0.2-0.6l0-1.3l-0.2-0.6l-0.8-0.9l-1.3-1l-1.8-0.8l-0.8-0.7l-2.1-0.5l-0.8-0.3l-0.6-0.6
            l-0.1-0.5l0.2-0.7l-0.6-0.9l-2.5-1.8l-1.4-0.6l-0.3-0.4l-0.2-0.1l0.2,1.2l0.6,0.7l1.6,0.7l0.4,0.4l0.2,0.5l-0.9,1l-0.5,0.3l-0.1,0.5
            l-0.3,0.2l-0.3-0.3l-1.3-1.6l-2.5-0.7l-0.5-0.5l-0.9-1.4l-0.4-1.3l0.2-0.9l1-1.4l0.3-0.6l-0.1-0.4l0-0.5l-0.4-0.4l-1-0.5l-0.6-0.4
            l0.2-0.2l1.1-0.5l0.1-0.5l0-0.2l0.2,0l0.2-0.1l0.1-0.1l0.3-0.5l0.3-0.3l0.3,0l0.4,0.2l1.4,0.5l1.5,0.5l2.2,0.8l0.9-0.5l0.8-0.4
            l0.5,0.1l1.2,0.4l0.7,0.1l0.4,0l0.7,0.6l0.4,0.5l0.1,0.3l0.2,0.2l0.6,0l1.4,0.3l0.9-0.1l0.8-0.3l0.4-0.4L270.9,190.4z"/>
    </a>
    <a xlink:title="Cuba" (click)="redirectToSearch(7)" [ngClass]="isAvailable(7)">
        <path id="CU" d="M285.5,87.2l2.1,0.4l1.7-0.1l0.8-0.2l-0.1,0.2l0.8,0.6l0.3,0l1.1-0.3l2.9-0.1l0.3,0.2l0.5,0.6l0.7,0.4l0.8,0.3
            l0.8,0.1L299,89l0.7,0.1l0.9,0.6l0.3,0.1l0.8-0.2l-0.2,0.5l1.4,0.7l1,1.4l0.7,0.6l0.8,0.5l0.7,0.4l0.7,0.2l2.3-0.1l0.5,0l0.5,0.2
            l0.5,0.1l0.3-0.1l4.4,2.2l1.4,1.2l0.9,0.6l1.8,0.9l0.7,0.2l0.4-0.1l-0.1-0.2l-0.5-0.5l-0.1-0.2l0.7,0.2l1.3,1l0.3,0.4l0.6,0.3
            l0.6,0.2l-0.3,0.4l-0.5,0l-1-0.2l0.8,0.6l0.1,0.5l0.4,0l0.5-0.5l0.3-0.4l1.4,1.1l0.7,0.5l-0.2,0.3l-0.1,0.3l0.8-0.3l0.3,0l0.3,0.2
            l0.3,0.5l0.8,0.1l0.8,0l1.6,0.4l1.5,0.8l1.4,0.2l1.4,0l0.7,0.4l0.3,0.6l-0.3,0.4l-0.2,0.4l0.5,0.5l-1.2,0.2l-0.2,0.3l0.1,0.3
            l0.2,0.2l0.7-0.2l1,0.1l1.5,0.1l1-0.1l2.1,0.4l0.6,0.2l1.2,0.7l0.6,0.4l1.2,1.2l1,0.5l0.9,0.1l0.3-0.1l0.3,0.1l0.3,0.2l0.2,0.5
            l-0.1,0.5l-0.5,0.4l-0.3,0.3l-1.3,0l-1.8,0.1l-1.7,0.5l-0.9,0.4l-0.4,0.3l-0.9,0.2l-0.1-0.2l0-0.3l-0.2-0.5l-0.2,0.4l-0.3,0.3
            l-0.6,0.3l-2.1,0l-0.9-0.4l-0.9-0.2l-3.2-0.3l-0.8,0l-2.1,0.3l-2.1,0.1l-0.9,0.2l-0.9,0.2l-1.7,0l-2,0.3l-2,0.1l1.3-1.9l2.8-1.9
            l0.5-0.4l0.4-0.5l0.1-0.4l-0.1-0.3l-0.7-0.6l-0.1-0.4l-0.2-0.3l-1-0.2l-1-0.1l-1,0l-2.1-0.2l-1.1,0l-1-0.4l-1.6-1.4l-0.7-0.4
            l-0.4-0.3l-0.3-0.4l-0.4-2.1l-0.3-1l-0.5-0.9l-0.7-0.7l-0.8-0.2l-3,0.6l-0.7-0.1l-0.7-0.2l-4.5-1.4l-1.8-0.7l-0.8-0.4l-0.6-0.5
            l-0.7-0.9l-0.7-0.8l0,0.3l-0.1,0.2l-3.7,0.1l-0.6-0.2l-0.4-0.2l-0.3-0.3l-0.2-0.6l-0.4-0.5l-0.1,0.6l-0.2,0.5l-0.5,0.3l-0.6,0
            l-0.7-0.7l-3-0.1l-0.3-0.1l-1-0.7l-0.9-0.8l0.8-0.3l1.7-0.4l0.4-0.3l0.2-0.3l-0.2-0.5l-0.3-0.4l-0.4-0.2l-0.4-0.1l-0.5-0.1l-6.8-0.1
            l-0.4,0.3l-0.6,0.6l-1.2,0.7l-0.8,0.7l-0.3,0.4l-0.4,0.3l-0.8,0.5l-0.7,0.7l-0.9,0.3l-0.5-0.2l-0.5,0l-0.3,0.2l-0.4,0.1l-1.7,0.1
            l-0.3,0.2l-0.2,0.5l-0.3,1l-0.3,0.3l-0.9,0.1l-0.8,0.3l-1.7,0.9l-0.4,0.1l0.1-0.7l-0.1-0.7l-0.5,0l-0.5,0.1l-0.5,0.2l-0.8,0.5
            l-0.4,0.1l-0.4-0.3l0.1-0.3l2.8-1.2l0.3-0.1l0.5,0.1l0.5,0l0.4-0.3l-0.5-1.6L265,94l0.6-0.8l1.3-1.3l0.6-0.4l6.4-2.6l0.7-0.1
            l4.1-0.5l0.6-0.2l1.9-0.8l2-0.3L285.5,87.2L285.5,87.2z M305.6,91.5l0,0.2l-1.4-0.6l-0.6-0.7l-0.2-0.2l0.4,0l1.6,1.1L305.6,91.5z
             M311.4,92.5l1.1,0.2l0.4-0.1l0.4,0l0.4,0.1l0.5,0.7l-0.5,0.1h-0.4l-0.3-0.1l-1,0l-0.7-0.2l-0.4-0.2l-0.2-0.2L311.4,92.5L311.4,92.5
            z M316.3,94.8l-0.2,0.1l-0.4-0.3l-0.6-0.1l-0.4-0.5l-0.4-0.2l0-0.2l0.6-0.1l0.4,0.1l0.5,0.4l0.3,0.7L316.3,94.8L316.3,94.8z
             M317.5,96.2l-0.3,0l-0.8-0.4l-0.2-0.3l0.3-0.4l0.1-0.4l0.1,0l0.1,0.5l0.6,0.2l0,0.1l0.4,0.5L317.5,96.2L317.5,96.2z M319.2,97.7
            l-0.3,0.3l-0.4-0.4l-0.2,0l-0.3-0.2l-0.6-0.4l-0.1-0.4l0.5,0l0.7,0.1l1.1,0.3l-0.1,0.5L319.2,97.7z M279.6,101l-0.8,0.5l-1.6,0.6
            l-0.9,0l-0.9-0.2l-0.6-0.5l-0.3-0.5l0-0.3l0.5,0.4l0.5,0.2l0.4-0.1l0.3-0.2l-0.9-1.7l0-0.4l0.7-0.9l1.9,0.3l0.3,0.2l0.3,0.6l0.4,0.5
            l0.5,1.3L279.6,101z"/>
    </a>
    <a xlink:title="Ecuador" (click)="redirectToSearch(8)" [ngClass]="isAvailable(8)">
        <path id="EC" d="M338.5,279.5l-0.5,0.3l-0.5,0l-0.6,0.1l-0.9-0.3h-0.3l0,0.3l0.6,0.3l0.6,0.4l0.2,0.6l0.3,0.7l0.8,0.8l0.5,0.4l0,0.3
            l-0.2,0.5l0,0.4l0.3,2l-0.2,0.1l-0.3,0l-0.3,0l-0.3-0.2l-0.2-0.1l-0.1,0.3l-0.2,0.9l-0.5,2l-0.5,1.7l-0.6,0.6l-0.8,1l-1.1,1.3
            l-1.7,1.9l-1.2,0.9l-1,0.7l-1.1,0.8l-1.5,1l-1.6,0.6l-2.3,0.8l-1.6,0.6l-1.2,0.4l-1.2,0.4l-1.6,0.6l-0.6,0.5l-1,1.3l-0.5,0.6
            l-0.4,0.5l-0.1,0.2l0.1,0.2l0.2,0.3l0,0.3l-0.3,0.2l-0.3,0l-0.1-0.1l-0.1-0.3l-0.3-0.3l-0.3-0.1l-0.2,0.1l0,0.3l-0.4,1.3l0,0.6
            l-0.2,0.3l0,0.6l-0.4,0.5l-0.2,0.5l-0.1,0.4l-0.3,0.3l-0.1,0.4l-0.3,0.9l-0.4,0.7l-0.3,0.6l0,0.5l0.2,0.3l0.1,0.3l-0.2,0.5l-0.1,0.4
            l-0.5,0.2l-1,0.6l-0.4,0.4L309,317l0.1,0.4l0,0.3l-0.5,0.1l-0.2,0.3l-0.3,0.5l-0.3,0.2l-0.9-0.3l-0.7,0l-0.5-0.2l-0.6-0.7l-0.5-0.6
            l-0.4-0.8l-0.1-1.1l-0.5-0.3l-0.5-0.4l-0.6,0.1l-0.7,0.1l-0.4-0.2l-1-0.4l-0.8-0.5l-0.6-0.3l-0.5,0.1l-0.3,0.3l-0.5,0.5l-0.7,0.4
            l-0.3,0l-0.4-0.3l-0.1-0.3l0.4-0.5l0.7-1l-0.8,0l-0.3-0.3l0-0.4l-0.1-0.4l0.2-0.5l0.4-0.3l0.6,0.2l0.4,0l0.3-0.5l0.3-0.2l0.3-0.1
            l0.1-0.2l-0.3-0.7l-0.1-0.4l0.1-0.2l0-0.5l0-0.3l-0.2-0.3l0-0.4l-0.2-0.2l-0.1-0.3l0-0.3l-0.2-0.1l-0.2-0.1l0.2-0.1l1.2-0.4l0.5-0.4
            l0.6-0.4l0.5-0.6l0.3-0.5l0.8-2.5l0.8-1.6l-0.1-0.8l-0.6-1l-0.1-1.5l0.1-0.5l-0.1-0.3l-0.4,0.6l0.1,2.2l-0.4,1l-0.5,0.2l-0.3-0.2
            l0.2-1.6l-0.4,0.3l-0.6,1.1l-1,0.8l-0.1,0.3l-0.2,0.3l-0.8-0.3l-0.6-0.3l-1.9-1.8l-1.2-0.4l-0.8-0.6l-0.2-0.3l-0.1-0.4l0.8-0.4
            l0.8-0.5l0.1-1.1l0-0.9l-0.6-1.5l0.3-2l-0.1-0.8l-0.7-1.7l0.5-0.8l1.8-0.6l0.6-0.4l0.4-1.3l0.4-0.8l0.8,0.3l0.6,0l-0.8-0.3l-0.7-1.2
            l-0.1-0.5l1.3-1.6l0.7-0.4l0.8-0.9l0.7-1.3l0.2-2l-0.3-1.5l-0.2-1.5l0.4-0.4l1.1-0.2l0.9-0.5l0.4-0.5l1,0.1l1.2-0.7l1.9-0.4l2.7-0.8
            l0.6-0.7l-0.3-1.3l0.2,0.2l0.7,0.6l0.4,0.6l0.8,0.4l0.6,0.3l1.6,1.2l1.1,0.6l1.2,0.6l1.7,0.6l1-0.1l0.2,0.4l0.2,0.5l0.4,0.3l0.6,0.2
            l0.4,0.1l0.1,0.1l0.4,1.7l0.2,0.2l0.8,0.3l1,0.1l0.4-0.1l0.9,0.5l0.7,0.2l0.7,0.2l0.5,0l0.2-0.1l0.1-0.2l0.4,0l0.6,0.2l0.9,0
            l0.5-0.2l0.1-0.3l0-0.6l0.2-0.2l0.6-0.4l0.3,0.1l1.6,0.8l0.3,0.3l0.4,0.5l0.8,0.8l0.8,0.5l1.3,0.2l1.2,0.8L338.5,279.5L338.5,279.5z
             M309.2,268.6l-0.5,0.1l-0.2-0.4l0.6-0.4l0.2-0.1L309.2,268.6z M299.3,302.5l-0.2,0.3l-0.8,0l-0.2-0.1l0-0.4l0.2-1.1l0.2-0.5
            l0.6-0.5l0.5-0.2l0.7,0l0.7,0.4l-0.8,0.8l-0.5,0.1l-0.2,0.1L299.3,302.5L299.3,302.5z"/>
    </a>
    <a xlink:title="El Salvador" (click)="redirectToSearch(9)" [ngClass]="isAvailable(9)">
      <path id="SV" d="M224.6,161.6l0.2,0l1.4,0.4l0.4-0.1l0.5,0.3l0.2,0.3l0.2,0.4l1.1,0.7l0.2,0.3l0.8,0.4l0.3,0.3l0.3,0.1l0.7,0.1
          l0.6,0.2l0.1,0.1l0.1,0.5l0.1,0.4l0.3,0l0.3-0.2l1.1-0.6l1-0.4l0.6,0.2l0.3,0.5l0.4,0.2l0.8-0.1l0.7,0l0.6,0.4l0.1,0.2l-0.4,1.3
          l-0.1,0.6l-0.1,0.5l0.2,0.1l0.2,0.2l0,0.3l-0.6,0.4l-0.2,0.1l0.1,0.8l-0.5,0.5l-0.4,0.4l-0.7,0.1l-1.3,0l-1.9-0.4l-1.4-0.6l-0.8,0
          l0.2,0.2l0.6,0.1l0.8,0.4l-0.2,0.1l-2.9-0.8l-3.3-1.6l-2-0.3l-2.3-0.4l-1.3-1l-1-0.4l-0.1-0.4l0-0.4l0.5-0.6l0.9-0.8l0.6-0.4
          l0.3-0.1l0.4,0l0.4-0.2l0.3-0.5l0.3-0.3l0.8-0.3l0.2-0.1l-0.1-0.3l-0.2-0.6l0-0.4l0.3-0.2l0.3,0l0.7-0.1l0.3,0L224.6,161.6z"/>
    </a>
    <a xlink:title="Guyana Francesa">
        <path id="GF" d="M529.7,246l-0.3,0.2l-0.7,0.4l-0.3,0.5l-0.2,0.5l-0.4,0.3l-0.4,0.4l-0.1,0.3l-0.4,0.3l-0.1,0.4l-0.9,1.6l-0.4,0.7
            l-0.5,0.7l-0.3,0.3l-0.5,0.4l-0.2,0.5v0.5l-0.3,0.6l-0.2,0.6l-0.3,0.3l-0.8,1.7l0,0.6l-0.2,0.4l-0.6,0.8l-0.4,0.5l-0.7,0.4l-0.7,0.4
            l-0.3,0.4l-0.5,0.2l-0.4,0l-0.6-0.2l-0.8-0.1l-0.4,0.1l-0.2-0.2l-0.3-0.5l-0.4-0.4l-0.3,0.1l-0.5,0.4l-0.6,0.2l-0.4-0.1l-1-0.2
            l-0.4-0.1l-0.1-0.2l-0.1-0.2l-0.2,0.1l-0.3,0.3l-0.4,0.3l-0.6,0.4l-1.2,0.7l-0.3,0.2l-0.3-0.1l-0.5-0.1l-0.5,0l-1.1-0.4l-0.7-0.3
            l-0.3-0.4l-0.3-0.2l-0.2-0.1l0.1-0.1l0.3-0.1l0.3,0l0.4-0.6l0.7-0.4l1.2-2l0.5-0.8l0.1-0.5l0.1-1l-0.3-1.2l0.1-0.3l1-1.4l0.4-0.8
            l0-0.7l0.1-0.5l-0.1-0.2l-0.2-0.1l-0.4-0.6l-0.2-0.5l-0.7-0.5l-0.5-0.5l-0.8-1.2l0.1-0.7l-0.2-0.2l-0.2-0.3l0-0.3l-0.2-0.8l-0.2-0.7
            l-0.1-0.5l0.2-0.8l-0.1-0.9l-0.3-0.5l-0.1-0.7l0.1-0.6l0.2-0.4l0-0.4l1-1.4l0.7-0.8l0.7-0.6l0.6-0.4l0.8-2.1l0.6-0.7l0.6-0.1
            l3.2,1.8l1.5,0.2l3,1l1.1,1.2l2.5,2l1.3,0.6l0,0.5l-0.3,0.8l0.8-0.7l1.3,1.2l0.4,0.6l0.4,1.1L527,243l-0.2,0.4l0,0.3l0.4-0.4
            l0.2-0.3l0.1-0.7l0.3-0.9l0.4,0l0.3,0.5l0.7,2.3l0.3,0.5l0.1,0.7l0,0.3L529.7,246z"/>
    </a>
    <a xlink:title="Guatemala" (click)="redirectToSearch(11)" [ngClass]="isAvailable(11)">
        <path id="GT" d="M226.2,133.1l-0.1,2l-0.1,2.4l-0.1,1.7l-0.1,2.3l-0.1,2.4l-0.1,3.2l-0.1,2.1l0,0l1-0.1l1.4,0.1l0.3,0l0.4,0.2
            l0.3,0.1l0.7,0.5l0.9,0.4l0.5-0.7l-0.3-0.4l-0.2-0.2l0-0.2l3,1.9l-0.3,0.3l-0.8,0.7l-1.4,1.1l-1.2,1l-1.2,0.9l-1.1,0.8l-0.1,0.1
            l-1.3,0.6l-0.2,0.3l-0.3,1.2l-0.1,0.3l0.2,0.6l0.2,1l-0.1,0.5l-0.9,0.6l-0.4,0.6l-0.2,0.4l-0.2-0.1l-0.3,0l-0.7,0.1l-0.3,0l-0.3,0.2
            l0,0.4l0.2,0.6l0.1,0.3l-0.2,0.1l-0.8,0.3l-0.3,0.3l-0.3,0.5l-0.4,0.2l-0.4,0l-0.3,0.1l-0.6,0.4l-0.9,0.8l-0.5,0.6l0,0.4l0.1,0.4
            l-3.1-1.4l-1-0.2l-4.4,0l-1.9-0.5l-2.1-1l-1.4-0.9l-3.4-2.6l0.2-0.2l0.2-0.5l0.2-0.5l-0.1-0.6l-0.1-0.5l0.3-0.7l0-0.5l0.1-0.3
            l0.4-0.2l0.2-0.4l-1-1.4l0-0.3l0.1-0.4l0.8-1.5l1-1.7l1.1-1.9l0.7-1.2l2.4,0l1.6,0h2.1l2.2,0h1.5l0.6,0l-0.1-0.7l0.1-0.8l0.3-0.8
            v-0.3l-0.4-0.4l-0.8-0.2l-0.5-0.4l0-0.5l-0.2-0.6l-0.4-0.6l-0.9-0.7l-1.3-0.7l-1.1-0.9l-0.9-1.1l-0.8-0.7l-0.6-0.3l-0.1-0.2l1.7,0
            l1.6,0l0-1.6l0-1.5l0-1.7l3,0l3.5,0l3.7,0l2.9,0L226.2,133.1z"/>
    </a>
    <a xlink:title="Guyana" (click)="redirectToSearch(12)" [ngClass]="isAvailable(12)">
        <path id="GY" d="M484.8,234l-0.4,0.5l-0.1,0.3l-0.3,0.6l-0.2,0.3l0.3,0.7l0.4,0l0.1,0.1l0.1,0.1l0,0.2l-0.1,0.1l-0.3,0.2l-0.3,0.4
            l0,0.5l-0.2,0.2l-0.7,0.1h-1.3l-0.6,0l-0.5,0.1l-0.3,0.3l-0.4,0.2l-0.3,0l-0.3,0.3l-0.3,0.5l0.1,0.3l0.3,0.4l0.2,0.5l-0.2,0.7
            l-0.3,0.6l-0.1,0.4l-0.2,0.8l-0.5,0.9l-0.4,0.5v0.6l0.2,0.8l1,1.2l0.3,0.6l0.3,0.9l0.9,0.7l0.6,0.6l-0.1,0.8l0.1,0.2l0.4,0.2
            l0.4,0.1l0.5,0l0.4-0.1l0.1-0.1l1,0l0.1,0.2l0.1,1.1l0,0.4l0.2,0.2l0.1,0.3l0,0.2l0,0.6l0.1,0.3l0,0.6l0.1,0.2l0.3,0.2l0.3,0.5
            l0.1,0.1l0.1,0.2l0.3,0.7l0.1,0.2l0.1,0l0,0.2l0.2,0.6l0.1,0.2l0.3,0.5l0.1,0.5l0.4,0.6l0.4,0.4l0.2,0.4l0.5,0.9l0.5,0.6l0.6,0.2
            l0.5,0.1l0.3,0.2l0.3,0.3l-0.3,0.1l-0.3,0.2l-0.4-0.1l-0.6,0.1l-0.6,0.2l-0.6,0.1l-1.1-0.3l-0.3,0l-0.2-0.1l-0.4-0.6l-0.2-0.1
            l-0.6,0.3l-0.7,0.2l-0.3,0l-0.4,0.2l-0.4,0.3l-0.7,1.1l-0.4,0.4l-0.4,0.2l-0.8,0l-0.8,0l-0.6,0.3l-0.6,0.1l-0.3,0l-0.1,0.6l-0.1,0.3
            l-0.2,0.2l-0.5,0l-0.4,0l-0.2-0.2l-0.5-0.1L476,266l-0.3-0.1l-0.2,0l-0.2,0.2l-0.1,0.2l-0.1,0.4l-0.6,0.1l-0.3,0.2l0.2,0.7l-0.1,0.3
            l-0.1,0.2l-0.8,0l-0.6,0l-0.4,0.3l-0.5,0.3l-0.3,0.1l-0.3,0l-0.4-0.4l-0.4-0.4l-1.1-0.3l-1.1-0.3l-0.7-0.7l-0.2-0.3l-0.3-0.2
            l-0.8-0.8l-0.5-0.5l-0.5-0.1l-0.6-0.2l0-0.4l0-0.4l-0.2-0.2l-0.3-0.1l-0.1-0.2l0-0.5l0.1-1.3l-0.1-1.2l-0.8-0.4l-0.3-0.3l-0.6-1.8
            l-0.3-0.8l0-0.6l0.2-1.8l0.2-0.8l0.6-1.6l0.3-0.5l0-0.4l0-0.5l-0.2-1l1-0.6l0.4-0.3l0.1-0.4l0.5-0.5l0.2-0.5l0.2-0.4l-0.1-0.2
            l-0.2-0.1l-0.3-0.4l-0.6-1.1l-0.2-0.2l-0.2-0.3l0.1-0.5l0.2-0.5l0-0.2l-0.3-0.3l-0.7-0.5l-0.6,0l-0.5-0.2l-0.7,0l-0.5-0.1l-0.3-0.2
            l0.1-0.3l0.1-0.2l0.4-0.6l0.3-0.6l0-0.6l0.1-0.8l0.1-0.7l0.1-0.7l-0.7-0.5l-0.2-0.4l-0.3-0.4h-0.3l-0.5-0.2l-0.8,0.5l-0.6-0.1
            l-0.4,0.2l-0.9,0l-0.6-0.2l-0.7,0.2l-1.7-1.9l-1.7-1.9l-1.7-1.9l-0.1-0.3l0.7-0.9l0.6-0.6l0.5-0.4l0.3-0.3l-0.2-1.4l0-0.5l-0.2-0.5
            l-0.2-0.6l0.2-0.5l0.3-0.4l0.3-0.1l0.8-0.1l0.6-0.1l0.2-0.2l0.3-0.2l0.4,0l0.8,0.2l0.4-0.3l0.7-0.4l1.5-0.7l0.3-0.5l0.2-0.7l0-0.3
            l-0.2-0.1l-0.4-0.1l-0.6,0l-0.5,0.2l-0.5-0.1l-0.4-0.4l0-0.4l0.2-0.5l-0.1-0.3l-0.8-1.1l0-0.3l0.6-0.5l0.3-0.4l0.4-1l0.3-0.3
            l1.1-0.1l0.3-0.2l0.5-0.5l0.8-0.6l1.2-0.5l0.3-0.9l0.2-0.2l0.9-0.5l0.2-0.2l0-0.2l-1.5-2l0.3,0.1l1.2,1.3l0.6,0.3l0.1,0l0-0.3
            l0.6,0.1l1.5,0.9l2.2,1.5l3.1,2.7l0.9,1l0.6,0.5l0.9,1.2l0.3,0.6l0,2.3l-0.8,1.6l-0.2,1.2l0,1.6l-0.5,0.9l0.6-0.5l0.2-1.4l0.5-0.9
            l0.7-0.9l0.9-0.2l1,0.4l0.8,0.1l0.7,0.3l1.5,1.5l1.5,1.2l0.5,1l1.6,0.5l0.9,0.8l0.3,0.7l0.2,1.7l-0.3,2.6L484.8,234z"/>
    </a>
    <a xlink:title="Honduras" (click)="redirectToSearch(13)" [ngClass]="isAvailable(13)">
        <path id="HN" d="M252.9,144.5l-0.6,0.5l-0.1-0.2l0.3-0.4l0.4-0.3l0.4,0l-0.1,0.2L252.9,144.5L252.9,144.5z M248.4,145.2l-1.3,0.7
            l-0.4,0l0.6-0.5l1-0.4l0.8-0.2l0.7,0.1L248.4,145.2L248.4,145.2z M274.8,156.8l-2.1-0.1l-1,0.3l-0.4,0.6l-0.4,0.3l-0.3-0.1l-0.6,0.2
            l-0.9,0.5l-0.8,0.2l-0.8-0.1l-0.2,0.1l-0.1,0.2l-0.1,0.2l-0.3,0.1l-0.3,0l-0.4-0.2l-0.2,0.1l0,0.3l-0.2,0.1l-0.4-0.2l-0.4,0.1
            l-0.5,0.4l-0.7,0.1l-0.9-0.2l-0.7-0.4l-0.5-0.6l-0.6-0.2l-1,0.5l-0.4,0.6l-0.1,0.3l0.1,0.3l-0.2,0.2l-0.5,0.1l-0.4,0.4l-0.2,0.7
            l0,0.5l0.1,0.4l-0.2,0.3l-0.6,0.2l-0.7,0.6l-0.8,1l-0.8,0.7l-0.8,0.4l-0.4,0.4l0,0.5l-0.1,0.1l-0.2,0.1l-0.3,0.1l-1.6-1l-0.5-0.7
            l-0.4,0.1l-0.5,0.4l-0.7,0.8l-0.8,1.1l-0.4,0.1l-1.9-0.2l-1,0.1l-0.2,0.1l-0.1,0.4l0.1,0.5l0.3,1.9l0.2,0.8l-0.1,0.2l-0.5,0
            l-0.7,0.1l-0.4,0.4l-0.1,0.4l0,0.5l-0.2,0.5l-0.4,0.4l-0.4,0.1l-2.3,0.1l0-0.9l-0.6-0.4l-0.4-0.7l-0.3-0.5l0.1-0.3l0-0.3l-0.9-0.3
            l-0.9,0.2l-0.5-0.1l-0.4-0.2l0.6-0.4l0-0.3l-0.2-0.2l-0.2-0.1l0.1-0.5l0.1-0.6l0.4-1.3l-0.1-0.2l-0.6-0.4l-0.7,0l-0.8,0.1l-0.4-0.2
            l-0.3-0.5l-0.6-0.2l-1,0.4l-1.1,0.6l-0.3,0.2l-0.3,0l-0.1-0.4l-0.1-0.5l-0.1-0.1l-0.6-0.2l-0.7-0.1l-0.3-0.1l-0.3-0.3l-0.8-0.4
            l-0.2-0.3l-1.1-0.7l-0.2-0.4l-0.2-0.3l-0.5-0.3l-0.4,0.1l-1.4-0.4l-0.2,0l0.2-0.4l0.4-0.6l0.9-0.6l0.1-0.5l-0.2-1l-0.2-0.6l0.1-0.3
            l0.3-1.2l0.2-0.3l1.3-0.6l0.1-0.1l1.1-0.8l1.2-0.9l1.2-1l1.4-1.1l0.8-0.7l0.3-0.3l0.8,0.2l0.6-0.5l0.4-0.2l0.8-0.6l0.3-0.1l1.4-0.3
            l0.7,0l0.6,0.6l0.5,0.4l0.9-0.3l0.7-0.1l3.1,0.6l1.2-0.3l2.2-0.1l1,0.1l1.4-0.9l0.9-0.2l1.1-0.4l-0.1-0.4l-0.3-0.2l1.6,0.2l2.4,0.9
            l2.6-0.2l0.9-0.5l0.6-0.1l2.7,0.9l0.7,0.7l0.5,0.1l0.4-0.2l0.1-0.1l-0.5-0.1l-0.2-0.2l2.1,0.4l3.9,3.2l0.1,0.3l-1.7-1l-0.9,0.1
            l-0.2,0.2l0.1,0.5l0.1,0.2l0.4,0l0.3-0.1l0.7,0.2l0.5,0.3l0.6,0.5l0.3,0.6l0.4,0l0.4-0.3l0.7,0l0.4,0.4l0.3,0l-0.4-0.6l-1-0.6l0.2,0
            l2.2,1.1l0.6,1.3l0.5,0.3L274.8,156.8L274.8,156.8z"/>
    </a>
    <a xlink:title="Haiti">
        <path id="HT" d="M359.7,114.2l0.3,0.2l-0.1,0.2l-0.8-0.1l-0.8-0.3l-0.3,0.1l-0.2,0l-0.5-0.3l0.4-0.2l0.4-0.1l0.5,0L359.7,114.2
            L359.7,114.2z M366.8,116.9l0.2,0.3l0.4,1.7l0,0.6l-0.4,0.8l0.1,0.3l0.8,0.8l0,0.3l-0.1,0.3l-0.7,0.7l-0.5,0.5l0.2,0.6l0.4,0.5
            l0.1,0.5l-0.1,0.6l-0.7,0.7l-0.3,0.3l-1,0l-0.1,0.1l0.5,0.7l0.5,0.8l0.9,0.6l0.2,0.6l-0.2,0.6l0,1.4l-0.7-0.7l-0.8-0.6l-0.5-0.2
            l-0.5-0.1l-3.6,0.1l-0.4,0.1l-0.3,0.2l-0.3,0.1l-1,0.2l-1,0l-2.3-0.5l-0.9-0.2l-0.9-0.1l-1,0l-1,0.1l-0.8,0.3l-0.6,0.6l-0.1,0.5
            l-0.4,0.1l-0.8-0.9l-0.8-0.6l-0.9-0.5l-1.8-0.7l-0.3-0.4l-0.1-0.5l0.7-1.5l0.8-0.3l0.5-0.1l1,0.2l1,0.3l0.9,0.2l1.4,0.1l0.8,0.4
            l5.5,0.6l1,0.2l0.4-0.1l0.4-0.2l0.3-0.4l0.3-0.3l1.6-0.1l0.3-0.1l0.2-0.4l0-0.4l-1-0.6l-1.5-1.3l-1.3-1.5l0.6-0.5l-0.2-0.9l0.2-0.9
            l0.3-0.8l-1.3-0.7l-1.5-0.7l-2.1-0.2l-0.7-0.2l-0.3-0.5l0.3-0.7l0.7-0.4l0.8-0.2l0.8-0.2l2-0.2l1.9,0.2l1.7,0.7l1.7,0.6l2.1,0.2
            l1,0.2L366.8,116.9L366.8,116.9z M358.6,125l-0.1,0.6l-2.1-0.7l-1.7-0.9l0.1-0.5l0.9-0.1l0.8,0.3l1.2,0.6L358.6,125z"/>
    </a>
    <a xlink:title="Jamaica" (click)="redirectToSearch(14)" [ngClass]="isAvailable(14)">
        <path id="JM" d="M322.5,127.7l1,0.3l1,0.2l0.4,0l0.4,0.1l0.9,0.7l0.7,0.4l2.8,0.9l0.9,1.5l0.2,0.5l-0.7,0.3l-0.9,0.1l-0.9,0
            l-0.8-0.3l-0.4-0.2l-0.9-0.1l0.2-0.2l-0.4-0.1l-0.5,0l-0.3,0.6l-0.4,0.5l-0.7,0l-0.3-0.4l-0.4,0.2l-0.3,0.3L323,134l-0.6-0.5
            l-0.7-0.5l-0.8-0.2l-1.7,0l-0.8-0.1l-0.7-0.9l-0.3-0.3l-0.7-0.2l-0.7-1.1l-0.2-0.1l-1.8-0.2l-0.4-0.6l0.1-0.5l0.6-0.6l0.3-0.2l1,0
            l0.9-0.2l0.4-0.3l0.4-0.2l3.4,0.5l0.8,0L322.5,127.7z"/>
    </a>
    <a xlink:title="México" (click)="redirectToSearch(15)" [ngClass]="isAvailable(15)">
        <path id="MX" d="M161.6,62.4l-0.1,1.8l-0.5,1.5l-1.6,3.1l-0.7,1.9l-1.3,5.5L157,80l-0.1,1.7l-0.1,0.2l0.1,0.2l-0.3,3.7l0.2,3.2
            l-0.1,0.5l-0.5,1l-0.3,1.3l0.1,0.6l0,0.4l0.5,2l0.2,1.5l1.4,2.6l0.8,0.9l1,0.8l0.4,0.4l-0.2,1.1l-0.4,0.6l-0.2,0.9l-0.2-0.7l0.1-0.9
            l0.3-0.5l0-0.4l-0.6-0.4l-1.1-1.3l-1.3-2.3l0.9,3.7l0.3,0.6l0.3,0.2l0.4,0.3l0.1,0.4l0,0.3l1.2,2.5l1.3,2.6l0.1,0.7l0.5,0.9l3.3,3.7
            l2,2.7l0.7,2.6l0.4,0.8l0.2,1.1l1.3,1.2l0.4,0.8l0.7,0.4l0.6,1.3l1.1,0.8l-0.3,0l-1-0.4l0.1,0.3l0.8,0.6l1.6,0.5h0.4l-0.6-0.3
            l-0.5-0.4l0.2-0.1l1.1,0.5l3.1,0.2l1.4,1.1l1.8,0.5l0.9,1.4l1.1,1.5l0.7,0.1l0.5,0l1.6-0.2l2.6-0.9l0.9-0.5l1.7-0.6l2.6-0.1l0.8,0.2
            l2-0.4l0.9-0.5l0.3-0.4l0.2-0.3l1.8-0.4l0.4-0.1l1.8-0.1l0.9-0.2l1-0.1l0.8,0.7v0.3l-0.5,0.3l0.2,0.3l0.9,0.5l1.6,0.2l0.5-0.1
            l0.8-0.7l1.3-0.7l0-0.8l-0.2-0.4l-0.4,0l-0.1-0.3l0.3-0.6l-0.1-0.2l-0.8,0.6h-0.2l0.1-0.3l0.2-0.2l2.4-1.3l0.6-0.5l0.8-0.5l1.7-1.7
            l0.4-3.2l0.3-0.6l1.2-1l0.1-0.3l0.1-0.7l0-1.7l0.1-1.3l0-1.5l0.2-1.4l0.2-0.4l0.7-2.2l1.4-1l2.4-1.1l0.5-0.2l7.6-1.2l1.1-0.3
            l1.3-0.8l1-0.3l1.7,0l0.5-0.1l0.1-0.1l0-0.1l0.3-0.1l1,0.1l1.9,0.5l0.7,0.1l1.7,0.5l1.9,0.2l0.3-0.1l0.2-0.2l0.2-0.3l-0.2-0.3
            l-0.2,0l-0.3,0.1l-0.4,0l-0.7-0.2l0.1-0.2l0.7,0l0.5-0.1l0.7-0.3l0.8,0.3l1,1.1l0.7,0.4l0.1,1.6l0.1,0.3l0.3,0.4l-0.4,1.3l-0.4,1
            l-0.5,0.8l-1.1,1.3l-1.3,1.1l-1.6,2.4l-0.4,1.1l0,0.9l0.3,0.9l-0.1,0.3l-0.2,0.3l-0.3,0l-0.6,0.4l-0.8,1.2l0,0.4l0.4,0.3l0.5-0.2
            l0.6,0l0.3-0.1l0.4,0l-0.1,0.7l-0.4,0.5l-0.2,0.2l-0.4,0.1l-0.5,0.3l-0.3,0.3l0,0.8l0.3,0.1l0.6-0.6l0.3,0l0.1,0.3l-0.7,2.1
            l-0.5,2.1l-0.7,1.2l-0.2,1.8l-0.3,0.8l-0.4,0.7l-0.2,0l-0.6-1.4l-0.6-0.4l-0.1-0.3l0.4-1.7l-0.2-1l-0.3,0l-0.4,0.5l-0.6,0.5l0,0.7
            l-0.7,1.1l-0.2,0.4l-0.6-0.1l-0.7,0l-0.5,0.3l-0.5,1.3l-1.3,1.9l-0.5,0.9l-0.4,0.3l-0.3,0.1l-0.4-0.2l-0.9-0.5l-0.7,0.2l-0.2,0.6
            l0,0.7h-1.7l-2.9,0l-3.7,0l-3.5,0l-3,0l0,1.7l0,1.5l0,1.6l-1.6,0l-1.7,0l0.1,0.2l0.6,0.3l0.8,0.7l0.9,1.1l1.1,0.9l1.3,0.7l0.9,0.7
            l0.4,0.6l0.2,0.6v0.5l0.5,0.4l0.8,0.2l0.4,0.4v0.3l-0.3,0.8l-0.1,0.8l0.1,0.7l-0.6,0h-1.5l-2.2,0h-2.1h-1.6l-2.4,0l-0.7,1.2
            l-1.1,1.9l-1,1.7l-0.8,1.5l-0.1,0.4v0.3l1,1.4l-0.2,0.4l-0.4,0.2L202,157l0,0.5l-0.2,0.7l0.1,0.5l0.1,0.6l-0.2,0.5l-0.2,0.5
            l-0.2,0.2l-0.2-0.2L199,158l-2.2-2.5l-0.9-0.8l-0.9-0.6l-1.2-1.1l-3-2.5l-1.6-1.2l-1.5-1.4l-1.3-0.8l-1.3-0.5l-0.6-0.3l-0.5-0.4
            l-0.3,0l-0.1,0.5l0.5,0.3l0.5,0.2l0.4,0l0.5,0.2l1.3,0.7l0.2,0.4l-3.8-1.4l-1.5-0.1l-0.2-0.2l0.8-0.7l-0.2-0.3l-0.3,0l-0.8,0.5
            l-0.3,0l-0.1-0.3l0-0.3l-0.5-0.5l-0.3,0l-0.3,0.3l-0.7,0.6l0,0.2l1.4,0.3l0.5,0.1l-0.1,0.2l-1.2,0l-1.5,0.3l-2.7,1.7l-2.5,0.7
            l-3.6,1.6l-1.6,0.1l-0.8,0.3l-2.4-0.6l-3-1.5l-4.6-0.5l-3.1-2l-3.1-0.8l-2-1.9l-1.2-0.1l-0.8-0.3l-2.8-0.7l-2.8-0.5l-2.7-1.7
            l-1.8-0.5l-1.5-0.7l-3.4-1.1l-1.2-0.6l-1.2-1l-1.9-1l-0.8-0.9l-0.9-0.3l-1.3-1.6l-0.7-0.7l-0.6-0.3l-0.6-0.1l-1.8,0.1l-2.7-0.7
            l-1.2-0.2l-2.6-1l-3.4-1.2l-1.1-1.3l-1-1.3l-1.7-1.7l-1.1-0.7L104,123l-1-0.7l-1.6-0.5l-2.7-1.3l-0.9-1.1l-0.5-1l-1.4-1.2l-1.6-2.3
            l-0.4-0.9l-0.3-1.3l-0.4-0.8l-0.4-0.6l0.2-0.4l0.8-0.5l1.3-0.1l0.9-0.6l0.1-0.5l-0.1-0.3l-0.6-0.7l-0.8-0.2l-0.6,0l-0.1-0.3l0.4-0.3
            l0.5-0.7l0.7-0.9l0.5-0.8l0.1-1.1l-0.1-1.1l0.2-1l-1.8-1.1l-0.2-0.5l-0.6-1.3l-1-1.5l0-2.9l-1.2-2.6l-1.2-1.3l-0.6-0.5l-1.7-2
            l-1.4-1.2l-1.3-2.2l-1.3-1.4l-1.7-2.4l-1.2-1.2l-5.5-4H76l1.6,1l0.3-0.1l0-0.5l-0.2-0.6l-0.3-0.1l-0.4,0.1l-0.6-0.1l-0.3-0.2
            l-0.9-0.1l-1.1-0.7l-0.5-0.7l-0.1-0.8l-1.6-1.7l-0.6-0.9l0.3,0.1l0.4,0.4l0.4,0.1l0.5,0l0.4-0.2l-0.1-0.3l-0.3-0.2l-2.3-0.9
            l-0.7-0.6l-1.9-1l-0.4-0.4l-0.3-1l-0.5,0l-0.4,0.3l-1.1,0.3l-0.3-0.3l0-0.3l0.8-0.3l0.7-1l0-0.3l-0.4,0.4l-0.6,0.4l-0.6,0.2
            l-0.9,0.2l-0.5-0.1l-0.4-0.2l-0.7-0.8l-0.3-2.7l0.6-0.9l0.7-0.9l0.6-0.6l0.3,0.4l0.3,0.1l-0.2-0.5L64,58.9l-0.2-0.4l0-0.4l-0.3-0.8
            l-1.7-1.6l-1.6,0.1l-0.6-0.1L59,55.2l-0.5-1l-0.2-0.9l0-0.5l-0.1-0.5l-2.7-0.7L54.6,51l-0.8-0.8l-0.3-0.7L53.1,49l-0.3-0.9l-0.2-1
            l0.3-1.3l0.4-0.6l-1.9-0.5l-0.7,0L50.2,45l-0.5-0.3l-1.1-0.4L47.3,43l-1.5-2.5l-1.7-0.8l-0.5-0.9l-0.7-0.8l-0.6-1l-0.1-0.4L42,36.5
            l-0.8-0.7l-0.9-1.1l-0.2-0.9l-0.3-1.4l-0.6-0.5l-0.6-0.2L38.3,31l0-0.4l-0.2-0.7l-1.3-1.7l-0.6-1.4l-0.4-0.4l-0.3-0.6l-0.2-1.3
            l-0.5-1.6l-1-2l-0.9-1.3l-0.4-1.3l0.2-1.4L32.6,16l-0.1-0.2l0.1-0.3l0.3,0.2l0.2-0.2l0-0.9l-0.3-0.3l-0.8-0.3l-0.4-0.2l-2-0.4
            L28.4,13l-0.1-1.1l-0.5-0.5L27.3,11l-1.5-0.7l-0.2,0.3l-0.2,0.6l-0.6,0.1l-0.6,0L23.2,11l-2.3-1.7l-0.5-0.3l-0.7-0.1l-0.4-0.3
            l-1.6-0.9l0.3,0.5l0.4,0.5l0.4,1.4l-0.5,1l-0.3,3.6l0.3,0.7l0.7,1.1l0.5,1.8l0.1,1.3l0.4,1.1L20,23.4l0.2,0.8l0.6,1.2l1.2,1.2
            l0.2,0.6l1.6,0.9l1,1.2l1.9,1.6l0.6,0.7l1.7,2.4l0.1,0.7l0.3,0.9l1-0.2l0.4,0.7l0,0.3l0.1,0.2l0.5-0.1l0.4,0.2l0.9,2.6l0.5,0.4
            l0.6,0.2l0.7,0.3l0,0.7l0,0.5l0.6,0.8l-0.1,1l0.5,0.9l-0.1,0.9l0.2,0.7l1.5,1.5l1.8,1.2l0.4,1.6l0.7,1.5l0.8,0.4l0.8,0.6L41.4,53
            l0.1,0.4l1,1.1l0.2,1.5l0.9,1l0.2,0.1l0.2-0.1l-0.6-1L43,55.3l0-1l0.2-0.1l1.8,1.6l0.2,1.2l0.6,0.7l0,0.9l0.4,0.5l0.1,0.8l0.6,1.3
            l0,1.7l0.3,1.3l1.2,1.9l0.9,0.4l0.2,1l1,2.5l1.1,1.3l0.6,1.1l0.1,0.7l-0.4,1l0,0.7l0.6,2.2l0.9,1.1l1,0.3l0.2,0.2l-0.1,0.3l0.3,0.3
            l0.4-0.3l0.2-0.5l-0.2-0.6l0-0.4l0.2-0.3l0.3,0l1.9,1.5l0.3,0.6l0.7,0.7l0.7,0.8l0.3,0.7l0.5,0.5l0.3,1.3l1.4,0.6l0.7,1l0,0.7
            L62,86.7l-0.3,0.5l-1.1,0.7l-0.8,0.8l-0.8,0.5l-0.8,0.3l-0.7-0.1l-0.6-1l-0.8-2.9L55.7,85l-0.4-0.9l-0.6-0.8l-2.2-1.2l-1.1-1.2
            l-1.1-0.8l-1.1-1.2l-3.1-2l-1.3-1l-0.8-1l-0.5,0L43.1,75l-0.2-0.3l0-0.5l-0.2-0.3l-1.8-1.5l-0.4-0.8l-0.1-1l0.4-2.5l0.2-1.5
            l-0.1-0.8l-0.2-0.1l-0.2-0.4l0-1.2l-0.4-1.3l-1.6-2.7l-1.2-0.5l-1.1-0.4l-2.9-2.4l-0.8-1.2L32.2,55l-0.1-1.4l-0.4,0.8L31.2,55
            l-1.2,0l-1.4,0.7L27.7,55l-0.4-0.7l-0.7-0.9l-0.8-0.2l-0.5,0l-0.9-1.1l-0.7-0.3l-1.1-0.1l-0.9-0.5l-0.3-0.6l-0.1-0.8l-0.3-0.5
            l-1.4-1l-1.2-1.1l-1.1-0.7L17,45.8l0-0.4l1.7,0.1l2,0.4l1-0.1l0.6-0.4l0.6-0.3l0.1,0.3l-0.1,0.6l0.6,0.5l0.8,0.4l0.5,0l-0.5-0.5
            l-0.3-0.9l0.1-0.3l0-0.5l-0.8,0.1l-0.1-0.2l0.7-0.7l0.7-1.9l0.4-1.9l-0.8-1.6l-1.3-1.1L19.9,34l-1.7-1.7l-0.5-0.6l-0.5-0.3l-1.4-0.4
            l-1.2-1l-2.1-1.4l-0.9-0.7l-0.6-1.7L10.7,26l0.2-1.2l-0.2-2l-0.3-0.5l-1.1-0.5l-0.3-1.4L8.8,19l-0.2-0.9l-1.9-1.6l-0.1-0.8v-0.7
            l-0.2-0.7l-1-1.5l-1.2-1.3l-0.4-0.6L3.7,9.7L3.3,9.3l0.2-0.1l0.4,0l0.2-0.2l0-0.9L2.3,6.8L1.7,4.9l-1-1L0.5,3.6L0,1.7l2.3-0.2
            l2.3-0.2l2.3-0.2l2.3-0.2l2.3-0.2l2.3-0.2l2.3-0.2l2.3-0.2L19.4,0l-0.5,1.4L18.5,2l3.8,1.4l3.8,1.4l3.8,1.4l3.8,1.4L37.7,9l3.8,1.4
            l3.8,1.4l3.8,1.4l2.9,0l2.9,0l2.9,0l2.9,0l2.9,0l2.9,0l2.9,0l2.9,0l0-1.1l0-1.1l0-1.1l0-1.1l1.8,0l1.8,0l1.8,0l1.8,0L81,9l1.8,0
            l1.8,0l1.8,0l0.1,0l0.1,0l0.7,0.8l0.7,1.3l0.9,0.9l1,0.5l1.7,1.5l2.4,2.4l1.9,1.6l1.4,0.8l1,0.7l0.5,0.6l0.7,1.3l1.3,2.9v1.3
            l0.5,1.3l1,1.6l0.8,1l0.7,0.3l0.8,0.6l0.8,0.9l1,0.6l1.1,0.3l1.5,0.8l1.9,1.3l1.3,0.6l0.7,0l0.6-0.4l0.5-0.8l0.5-0.5l0.5-0.2
            l0.2-0.4l-0.1-0.5l0.4-1.2l0.8-1.9l1-1l1.1-0.2l0.7-0.3l0.3-0.5l0.6-0.1l0.9,0.4l1.4,0.3l1.9,0.1l1.1,0l0.3-0.2l0.2,0l0,0.2l0.3,0.1
            l0.6,0l0.5,0.3l0.6,1l2.1,1.6h0l0.2,0.6l0.7,0.8l1.4,1.2l0.8,1l0.2,0.9l0.7,1.4l1.2,1.9l0.5,1l-0.1,0.1l0.1,0.5l0.3,0.9l0.6,0.8
            l0.9,0.6l0.9,1.1l0.9,1.6l1.1,1.3l1.3,0.9l0.7,0.8l0.2,0.7l0,0.6l-0.2,0.5l0.1,0.5l0.4,0.5l0.1,0.6l-0.1,0.8l0,0.2l0,0l0.1,0.2
            l1.1,1.4l0.6,1.1l0.5,1.8l0,0l0.5,1.1l0.7,0.4l1.2,0.2l0.9,0.4l0.6,0.6l0.8,0.3l0.9,0.1l0.9,0.4l0.8,0.6l1.6,0.4l2.3,0.2l1.7,0.5
            l1.2,0.8l0.5,0.2l0.1,0l0.1-0.1l0.1-0.2l0.5-0.3L161.6,62.4L161.6,62.4z M19.7,9.6l-0.3,0l-0.5-0.4l0-0.4l0.1,0l0.5,0.4l0.2,0.3
            L19.7,9.6z M32.1,34.4l-0.1,0.2L31.3,34L29.4,32l-0.7-1l-0.1-0.5l0.1-1l0.6,0.1l0.7,0.7l0.3,0.6l0,0.7l1.4,0.3l0.2,1.6L32.1,34.4
            L32.1,34.4z M39.8,34.8L39.2,37l-0.6,0l-1.3-0.7l-0.1-0.4l0.5-2.5l0.4-0.3l1.1-0.3l0.2,0.3l0.1,0.7L39.8,34.8L39.8,34.8z M15.8,43.4
            l-0.1,0.3l-1.4-0.6l0.7-1.1L15,40.9l0.3-0.2l0.3,0.4l0.4,1.4L15.8,43.4L15.8,43.4z M48.8,61.9l0.1,0.3l-0.1,0.1l-0.3-0.2l-0.6,1.3
            l-0.2,0.1l0.3-1.8l0.4-0.3l0.4-0.1L48.8,61.9L48.8,61.9z M41,75l-0.2,0.1l-0.7-1l-0.1-0.7l-0.3-0.3l-0.7-0.2l0.6-1.4l0.5-3l0.2,0.5
            l-0.5,3l0,0.4l0.3,0.4l0.3,0.6l0,0.7l0.5,0.6L41,75L41,75z M53.1,71l0.2,1l-0.4-0.1l-0.5-0.5L52,70.6l0-0.3l0.1-0.1l0.8,0.4L53.1,71
            L53.1,71z M43.9,76.4l-0.1,0.4l-2.4-1.7L42,75l0.7,0.1L43.9,76.4z M59.2,78.5l-0.2,0l-0.4-0.5l-0.2-1.2l0.1-0.1l0.8,1.4l0,0.2
            L59.2,78.5L59.2,78.5z M246,103.9l0.1,0.4l-0.1,0l-0.2-0.3l-0.1-0.4l0.1,0l0.1,0.1L246,103.9z M244.2,111.9l-0.4,0.3l-0.2-0.9
            l0.3-0.9l0.4-0.5l0.8-0.1l0.5-0.2l0.1,0.2l-0.4,0.7L244.2,111.9L244.2,111.9z M205.8,125.8l-0.9,0.2l-0.2-0.2l1.8-0.9l0.3,0l0.1,0.1
            l-1,0.4L205.8,125.8L205.8,125.8z"/>
    </a>
    <a xlink:title="Nicaragua" (click)="redirectToSearch(16)" [ngClass]="isAvailable(16)">
        <path id="NI" d="M274.8,156.8l-0.2,0.3l-0.2,0.2l-0.5,1l-0.2,0.1l0-0.7l-0.3-0.1l-0.4,0.3l-0.2,0.4l0.3,0.5l0.3,0l0.3,0.1l0.9,3.4
            l-0.2,0.6l-0.6,0.9l-0.5,0.8l-0.5,0.5l-0.7,2.1l-0.6,3.5l0.4,3.1l-0.2,2.9l0.2,0.7l0.1,0.8l-0.4,0.2l-0.2,0l-0.3-0.5l0-0.5l0.3-0.5
            l0.1-0.7l-0.1-0.4l-0.3,0.8l-0.5,0.4l-0.3,0.1l-0.3,0.4l0.3,0.8l0.4,0.6l0.1,0.4l-0.1,0.5l-0.1,1.7l-0.1,0l-0.1-0.2l-0.4,0l0,0.7
            l0,0.4l-0.4,0.3l-0.1,0.3l0.3,0.2l0.3,0.1l0.4,0l0.3,0.8l0.1,0.7l-0.8,0.6l-0.3,0.5l-0.4,0.6l-0.2,0.6l-0.1,0.4l0.3,1.4l0.5,1
            l0.4,0.6l0.6,0.1l-0.1,0.7l-0.4,0.4l-0.8,0.3l-0.9,0.1l-1.4-0.3l-0.6,0l-0.2-0.2l-0.1-0.3l-0.4-0.5l-0.7-0.6l-0.4,0l-0.7-0.1
            l-1.2-0.4l-0.5-0.1l-0.8,0.4l-0.9,0.5l-2.2-0.8l-1.5-0.5l-1.4-0.5l-0.4-0.2l-0.3,0l-0.3,0.3l-0.3,0.5l-0.1,0.1l-0.2,0.1l-0.2,0
            l0-0.2l-0.7-0.9l-1.1-1.1l-4.1-3.3l-1.5-2l-0.8-1.4l-0.8-0.7l-2.2-1.5l-0.5-0.6l-2.2-2.1l-1.7-1.2l0-0.5l0.7-0.6l0.3,0l0.4,0.5
            l0.6,0.5l0.3,0l0.4-0.2l0-0.2l2.3-0.1l0.4-0.1l0.4-0.4l0.2-0.5l0-0.5l0.1-0.4l0.4-0.4l0.7-0.1l0.5,0l0.1-0.2l-0.2-0.8l-0.3-1.9
            l-0.1-0.5l0.1-0.4l0.2-0.1l1-0.1l1.9,0.2l0.4-0.1l0.8-1.1l0.7-0.8l0.5-0.4l0.4-0.1l0.5,0.7l1.6,1l0.3-0.1l0.2-0.1l0.1-0.1l0-0.5
            l0.4-0.4l0.8-0.4l0.8-0.7l0.8-1l0.7-0.6l0.6-0.2l0.2-0.3l-0.1-0.4l0-0.5l0.2-0.7l0.4-0.4l0.5-0.1l0.2-0.2l-0.1-0.3l0.1-0.3l0.4-0.6
            l1-0.5l0.6,0.2l0.5,0.6l0.7,0.4l0.9,0.2l0.7-0.1l0.5-0.4l0.4-0.1l0.4,0.2l0.2-0.1l0-0.3l0.2-0.1l0.4,0.2l0.3,0l0.3-0.1l0.1-0.2
            l0.1-0.2l0.2-0.1l0.8,0.1l0.8-0.2l0.9-0.5l0.6-0.2l0.3,0.1l0.4-0.3l0.4-0.6l1-0.3L274.8,156.8z"/>
    </a>
    <a xlink:title="Panamá" (click)="redirectToSearch(17)" [ngClass]="isAvailable(17)">
        <path id="PA" d="M321.6,208.8l-0.2,0.1l-0.4,0.6l-0.2,0.5l0.6,0.6l0.2,0.6l0.3,0.7l0.5,0.7l0.6,1.2l0.1,0.5l-0.2,0.3l-0.5,0.2
            l-0.5,0.6l-0.1,0.7l0.1,0.4l-1.5,1.1l-0.4,0.2l-0.3-0.2l-0.3-0.6l-0.4-0.5l-0.2-0.2l-0.1,0l-0.1,0.1l-0.1,0.2l0.2,1.1l-0.2,0.4
            l-0.5,0.3l-0.6,1.7l-0.2-0.2l-1.9-2.3l-1.7-2.9l-0.4-1.3l0.4-0.1l0.4,0l0.2-0.2l0.3-0.4l-0.2-0.9l0.8-0.7l0.3-0.5l0.2,0.1l0.5,0.8
            l0.8,0.4l1,0.6l0.6,0.1l-0.7-0.7l-1.3-0.9l-0.4-0.6l-0.3-0.8l-0.5,0.4l-0.2,0.3l-0.3,0.2l-0.2-0.2l0-0.3l-0.8-0.1l-0.2-0.2l-0.2-0.1
            l0.1,0.5l0.1,0.3l-0.1,0.4l-0.2,0l-0.2-0.4l-0.3-0.4l-0.4-1.5l-0.9-0.7l-0.4-0.2l-0.3-0.1l-0.5-0.5l-0.6-0.3l-0.9-0.7l-1.1-0.5
            l-1.3-0.2l-1.6,0.1l-0.5,0.3l-0.4,0.4l-0.2,0.2l-0.9,0.4l-0.4,0.6l-0.2,0.5l-0.5,0.6l0.5,0.4l-3,2l-0.6,0.3l-1.4,0.2l-0.3,0.2
            l-0.4,0.4l-0.1,0.6l0.1,0.5l0.4,0.4l0.4,0.2l0.8,1.2l1.5,1.5l0.3,0.5l0.2,0.8l-0.5,0.4l-0.4,0.2l-1.4,0.1l-0.5,0.3l-0.2,0.5
            l-0.5,0.4l-1.8,0.4l-1.4,0l-0.4-0.5l-0.1-1.3l-1-2.2l-0.2-1.5l-0.2,0.2l-0.5,0.2l-0.2,0.4l-0.1,1.1l-0.2,0.4l-0.4,0l-0.8-0.4
            l-1.1-0.4l-1.4-2.4l-0.1-0.4l-0.3-0.5l-1.1-0.2l-0.9-0.4l-1-0.1l-0.5,0.2l-0.5-0.3l-0.1-0.7l-1,0.3l-1.3-0.1l-1.2-0.3l-0.8,0.1
            l-0.7,0.5l0.1,1.2l-0.2,0.2l0-0.5l-0.2-0.6l-0.3-0.5l-0.6-0.5l0-0.2l0.2-0.2l1.1-0.7l0.1-0.3l0-0.6l-0.1-0.6l-0.5-0.9l0.3-0.5
            l0.6-0.4l0.6-0.3l0.1-0.1l-0.1-0.3l-0.3-0.3l-0.8-0.5l-0.5,0l0-1.5l0-1.6l0.1-0.2l0.3-0.1l0.2-0.2l0.1-0.5l0.3-0.2l0.6,0.4l0.6,0.3
            l0.3-0.1l0.2-0.2l0.1-0.2l0-0.1l0.5,0.4l1,0.8l0.1,0.4l-0.1,0.4l0.3,1l0.5,0.1l0.5-0.2l0.1,0.2l-0.1,0.2l-0.3,0.2l-0.1,0.9l0.9,0.4
            l0.4,0.4l1.5-0.2l0.5,0.1l0.4-0.1l-0.4-0.7l-0.6-0.5l0-0.2l0.4,0.2l0.3,0.4l0.7,0.4l1.3,1.6l1.5,0.4l1.2,0l1.1-0.2l1.8-0.6l1.3-1.1
            l1-0.5l3.4-1l1.2-1.1l0.5-0.1l0.5-0.1l1.1-0.8l0.6-0.6l0.6-0.3l1.8,0.2l1.2,0.3l0.8,0l0.8,0.2l0.3,0.5l0.4,0.2l1.9-0.1l1.6,0.2
            l3.4,1.4l2,1.4l1.1,1.5L321.6,208.8L321.6,208.8z M282.3,202.9l-0.1,0.4l-0.6-0.7l0.4-0.1l0.1,0L282.3,202.9z M309.3,211.9l-0.2,0.3
            l-0.4-0.8l0.1-0.2l0-0.7l0.4-0.2l0.3,0l0.2,0.1l0.1,0.8l-0.1,0.4L309.3,211.9z M307.9,212.1l-0.4,0.4l-0.1-0.3l0.3-0.4l0.1,0
            L307.9,212.1L307.9,212.1z M287.4,219.6l-0.4,0l-0.9-0.3l-0.7-0.7l-0.1-0.2l0-0.2l0.4-0.7l0.5-0.2l0.2,0l0.5,0.8l-0.3,0.3l0.1,0.5
            l0.7,0.4L287.4,219.6L287.4,219.6z"/>
    </a>
    <a xlink:title="Paraguay" (click)="redirectToSearch(18)" [ngClass]="isAvailable(18)">
      <path id="PY" d="M477,444.2l0.2,0.6l0.1,0.5l0.3,0.3l0.3,0.5l0.3,0.3l0.2,0.4l-0.1,0.5l0.1,0.6l0.1,0.5l0.1,0.1l0.4,0.1l0.2,0.5
          l-0.1,0.2l0.1,0.3l0.1,0.3l-0.1,0.3l0.1,0.2l0.3,0.2l0.2,0.7l0,1.2l-0.3,0.6l-0.2,0.5l-0.1,0.3l0.2,0.5l-0.3,0.5l-0.3,0.7l0.1,0.5
          l0.1,0.4l0,0.5l0.1,0.4l-0.1,0.5l-0.1,0.4l-0.1,0.5l0.1,0.5l-0.2,0.5l-0.1,0.3l-0.1,0.3l0.2,0.5l0.6,0.2l0.5,0.1l0.5-0.3l0.3-0.1
          l0.6,0.3l0.6,0.5l0.7,0.1l0.7,0.1l0.5,0.1l0.7-0.2l0.8,0.2l0.9,0.3l0.7,0.2l0.7-0.1l0.6,0l0.6-0.3l0.6,0l0.4-0.5l0.2-0.4l0.2-0.3
          l0.6-0.2l0.4,0.1l0.3,0.7l0.6,0.4l0.2,0.3l0.5,0.1l1,0l0.6,0l0.7,0.2h0.5l0.4,0.4l0.4,0.5l0.1,0.9l0.3,0.7l0.5,0.3l0.2,0.4l-0.1,0.6
          l-0.2,0.6l0,0.7l0.2,0.6v0.6l0.2,0.6l0.3,0.5l0.1,0.8l-0.1,0.6l0.2,0.3l0.1,0.5l-0.1,0.4l-0.1,0.6l0,0.5l0.2,0.4l0.5,0.5l0.1,0.9
          v0.6l0.2,0.8l0.4,0.4l0.6,0.1l0.7,0.1l0.9-0.2l0.8-0.2l0.5-0.2l0.9-0.6l0.8-0.3l0.4-0.2l0.4-0.1l0.8,0.3l0.7,0.4l0.6,0.6l1,0.7
          l-0.2,0.2l-0.4,0.6l0,0.6l0.3,0.9l-0.3,2l-0.8,3l-0.3,1.8l0.1,0.5l-0.3,0.9l-1.1,1.9l0,1.3l-0.1,3.8l-0.4,2.7l-0.6,2l-0.6,1.1
          l-0.5,0.1l-0.4,0.3l-0.2,0.5l-0.4,0.4l-0.6,0.3l-0.3,0.4l-0.1,0.4l-0.6,0.3l-1.1,0.1l-0.7,0.3l-0.2,0.5l-0.4,0.4l-0.6,0.3l-0.3,0.5
          l0,0.7l-0.3,0.6l-0.7,0.5l-0.6,0l-0.6-0.5l-0.7-0.3l-0.9-0.2l-0.8,0.1l-0.6,0.4l-0.6,0.7l-0.5,0.9l-0.5,0.1l-0.6-0.6l-0.8-0.2
          l-0.9,0.2l-0.7-0.1l-0.5-0.4l-0.8,0l-1.1,0.3l-2.3-0.4l-3.4-1l-2.9-0.4l-3.5,0.4l-0.3-1.1l0.2-0.6l0.6-0.4l0.4-0.5l0.1-0.6l0.4-0.4
          l0.6-0.3l0.3-0.3l-0.1-0.3l0.1-0.3l0.4-0.2l0.2-0.4l0-0.5l0.1-0.2l0.2-0.2l0-0.3l-0.1-1l0-0.9l0.2-0.7l0.2-0.4l0.2-0.1l0.1-0.2
          l0.1-0.4l0.2-0.4l1.1-0.8l0.4-0.4l0-0.4l0.2-0.5l0.7-1.1l0.2-0.5l0-0.3l0.2-0.3l0.8-0.6l0.4-0.6l0.1-0.5l-0.2-0.6l-0.5-0.7l-1.4-1.7
          l-1.1-0.8l-1.4-0.6l-0.9-0.2l-0.5,0.2l-0.5-0.2l-0.5-0.6l-0.8-0.5l-1.7-0.5l-3.7-2l-1.5-1l-0.5-0.6l-1.4-1.1l-2.3-1.5l-1.8-0.7
          l-1.2,0l-2-0.4l-2.7-0.9l-1.6-0.9l-0.4-0.9l-1-0.9l-1.6-0.9l-0.8-0.6l-0.1-0.3l-0.5-0.4l-0.9-0.4l-1-0.8l-1-1.1l-1.1-1.7l-1.2-2.2
          l-1.3-1.5l-1.4-0.8l-0.7-0.5l0-0.3l-0.2-0.2l0.2-0.4l0.5-1.7l0.7-2.5l0.7-2.5l0.9-3v-2.1l0-2.2l1.3-1.8l0.9-1.3l0.8-1.2l0.8-2.1
          l0.5-1.4l2-0.3l3.4-0.7l1.7-0.4l3.5-0.8l3.6-0.8l3.8,0l3.6,0l2.8,1.7l2.2,1.3l2.4,1.5l0.2,0.3l0.2,1.2L477,444.2z"/>
    </a>
    <a xlink:title="Perú" (click)="redirectToSearch(19)" [ngClass]="isAvailable(19)">
        <path id="PE" d="M381.5,312.7l0,0.5l-0.3,0.2l-0.4,0l-0.6-0.4l-0.4,0.1l-0.4,0l-0.6-0.4l-0.2-0.4L378,312l-1,0.1l-0.8,0l-0.7-0.1
            l-0.6,0.1l-0.5,0.5l-0.4,0.5l-0.5,0.4l-1.4,0.3l-0.7,0l-0.7,0.3l-1,0.1l-0.7,0.3l-1.2,0.1l-1.4,0.1l-0.8,0.4l-0.3,0.2l-0.8,0.5
            l-1.4,0.9l-0.8,0.3l-0.9,0.9l-1.1,0.9l-0.7,0.5l-1.1,0.2l-0.4,0.2l-0.2,0.3l0.1,0.3l-0.2,0.8l-0.3,1.6l-0.1,0.8l-0.1,0.4l-0.7,1.3
            l-0.8,1.2l-0.4,0.8l-0.2,0.6l0.2,0.5l0.3,0.8l0.3,0.7l0.1,0.5l-0.1,0.5l-0.3,0.5l-0.5,0.3l-0.7,0.1l-1.4,0.8l-1.6,1.3l-0.5,0.6
            l-0.1,0.6l-0.2,0.9l0.1,0.4l0.3,0.3l0.3,0.7l0,0.4l-0.2,0.2l-0.4,0l-0.4,0.1l-0.3,0.2l-0.3,0l-0.3,0.1v0.3l0.1,0.4l0,0.4l-0.2,0.2
            l-0.2,0.2l0.2,0.2l0.3,0.2l0.4,0.4l0.6,0.7l0.4,0.1l0.4,0.2l0,0.4l-0.1,0.4l-0.3,0.2l0,0.3l0.4,0.4l0.3,0.3l0.3,0.4l0.3,0.6v0.4
            l0.3,0.5l0.2,0.4v0.4l0.5,0.4l0.4,0.3l0.3,0.3l0.3,0.2l0.1,0.3l0,0.4l0.4,0.7l0.8,0.5l0.7,0.8l0.4,0.6l0.8,0.9l0,1l-1,1.2l-1,1.2
            l1.6,0l1.6,0l1.7,0.3l1.1,0.3l0.7,0.1l0.5,0.4l0.2,0.6l0.2,0.5l0,0.7l0.7,0.6l0,0.5l0,0.8l0.3,0l2.1,0l2.3,0l2.2-0.1l0.8-0.2l1-0.9
            l0.6-0.3l0.6-0.4l0.7-0.8l0.6-0.4l0.5-0.4l0.7-0.4l0.3-0.3l0.2-0.1l0.5-0.2l-0.2,0.4l-0.2,0.4l-0.1,0.6l0.3,0.7l-0.2,0.5l-0.3,0.5
            l0,1.2l0,1.7l0,1.5l0,1.8l0,2.1l0,1.4l0.4-0.3l0.5-0.2l0.7,0.6l0.5,0.3l0.4,0.1l0.4,0l0.6-0.1l1.2-0.5l0.9-0.4l1,0l1.3,0.2l0.8,0
            l1,1.8l0.7,1.3l0.8,1.5l0.7,1.2l1,1.8l0.9,1.6l1,1.7l1.1,1.9l-0.3,0.5l-0.3,0.4l0,0.7l-0.4,0.3l-0.5,0.2l-0.5,0.6l-0.4,0.5l0,0.7
            l0.1,3.5l-0.1,0.9l-0.3,0.8l-0.3,0.4l-0.2,0.3l0.4,0.8l0.4,1.6l0.3,0.3l0.4,0.7l0.2,0.6l-0.1,0.2l-0.7,0.3l-0.3,0.3l-0.1,0.9
            l-0.3,0.3l-0.5,0.4l-0.3,0.5l-0.3,0.3l-0.3,0.2l-0.1,0.6l-0.2,0.6l-0.7,0.4l-0.1,0.8l0,0.6l0.4,0.6l1.2,1.3l0.1,0.3l-0.7,0.8
            l-0.4,0.6l-0.9,1.7l0,0.3l0.2,0.8l1.4,3.4l0.2,0.3l0.4,0.3l0.7,0l1.1,0.4l0.5,0.4l0,0.2l-0.1,0.1l-0.6,0.3l-0.6,0.4l-0.2,0.4l0,0.6
            l0.1,0.8l-0.3,0.3l-0.6,0.3l-0.5,0.5l-0.5,0.8l-0.9,1.2l-0.3,0.3l-0.1,0.4l-0.5,0.1l-1,0.8l-0.2,0.4l0.2,0.4l0.5,0.3l0.3,0.5
            l0.1,0.6v0.4v0l-0.6,0.6l-0.8,0.6l-1,0.1l-0.4,0.3l0.1,0.7l0.3,1l0,0.8l-0.3,0.9l-0.7,1l-1.1,0.7l-1,0.4l-0.8,0l-0.8,0.1l-0.3,0.1
            l-0.6-0.6l-2.6-1.9l-1-1l-0.9-0.5l-2.3-1.6l-0.2-0.5l-0.3-1.7l-0.3-0.5l-0.8-0.6l-2-0.8l-0.8-0.4l-0.8-0.7l-1.2-0.5l-1.3-1.1
            l-0.8-0.8l-0.9-0.6l-2.6-0.8l-1.3-0.8l-2.5-1.1l-1.1-0.7l-2.6-0.9l-0.8-0.4l-2.6-2l-1.8-0.7l-1.5-1.1l-4.4-2.4l-0.7-0.8l-0.7-1.2
            l-1-0.7l-1.1-1.6l-1.6-1l-1.6-1.3l-0.6-1.1l-1.1-1.5l-0.3-0.8l-0.9-0.8l-0.1-1.5l-0.6-0.7l0.5-0.3l0.5-0.2l0.6-2.4l-0.3-1.2
            l-1.6-2.2l-0.6-1l-0.4-1.3l-0.7-0.8l-1-1.7l-0.6-1.5l-1.3-1.1l-0.4-0.4l-0.2-0.5l-0.7-0.4l0-1.1l-0.5-2.1l-0.7-1.1l-2.6-2l0-0.8
            l-0.2-1.4l-0.6-1.5l-2.9-4.7l-0.7-1.4l-0.7-2.3l-0.7-1.3l-0.7-2.3l-1.1-1.7l-0.7-1.5l-0.7-1.9l-0.1-1l-1.3-1.7l-0.7-1.6l-1.2-1.3
            l-1.2-1l-0.5-0.7l-1.7-3.4l-0.2-1l-1.2-1.9l-1.2-1.3l-0.7-1.1l-0.9-1l-5.7-3l-2-1.2l-0.7-0.6l-0.3-0.9l0.1-0.5l0.6-0.5l0.8,0.4
            l0.5-0.2l0.4-0.7l0-1l-0.5-1.3l-1.8-2.5l0.1-0.5l0.3-0.6l-0.7-1.2l-0.8-1l-0.4-0.7l0.4-2.8l0.4-0.7l2.7-2.8l0.8-1.2l1.2-0.7l1.2-1.1
            l1.4-0.9l0.2,0.1l0.2,0.1l0,0.3l0.1,0.3l0.2,0.2l0,0.4l0.2,0.3l0,0.3l0,0.5l-0.1,0.2l0.1,0.4l0.3,0.7l-0.1,0.2l-0.3,0.1l-0.3,0.2
            l-0.3,0.5l-0.4,0l-0.6-0.2l-0.4,0.3l-0.2,0.5l0.1,0.4l0,0.4l0.3,0.3l0.8,0l-0.7,1l-0.4,0.5l0.1,0.3l0.4,0.3l0.3,0l0.7-0.4l0.5-0.5
            l0.3-0.3l0.5-0.1l0.6,0.3l0.8,0.5l1,0.4l0.4,0.2l0.7-0.1l0.6-0.1l0.5,0.4l0.5,0.3l0.1,1.1l0.4,0.8l0.5,0.6l0.6,0.7l0.5,0.2l0.7,0
            l0.9,0.3l0.3-0.2l0.3-0.5l0.2-0.3l0.5-0.1l0-0.3L309,317l0.1-0.5l0.4-0.4l1-0.6l0.5-0.2l0.1-0.4l0.2-0.5l-0.1-0.3l-0.2-0.3l0-0.5
            l0.3-0.6l0.4-0.7l0.3-0.9l0.1-0.4l0.3-0.3l0.1-0.4l0.2-0.5l0.4-0.5l0-0.6l0.2-0.3l0-0.6l0.4-1.3l0-0.3l0.2-0.1l0.3,0.1l0.3,0.3
            l0.1,0.3l0.1,0.1l0.3,0l0.3-0.2l0-0.3L315,306l-0.1-0.2l0.1-0.2l0.4-0.5l0.5-0.6l1-1.3l0.6-0.5l1.6-0.6l1.2-0.4l1.2-0.4l1.6-0.6
            l2.3-0.8l1.6-0.6l1.5-1l1.1-0.8l1-0.7l1.2-0.9l1.7-1.9l1.1-1.3l0.8-1l0.6-0.6l0.5-1.7l0.5-2l0.2-0.9l0.1-0.3l0.2,0.1l0.3,0.2l0.3,0
            l0.3,0l0.2-0.1l-0.3-2l0-0.4l0.2-0.5l0-0.3l-0.5-0.4l-0.8-0.8l-0.3-0.7l-0.2-0.6l-0.6-0.4l-0.6-0.3l0-0.3h0.3l0.9,0.3l0.6-0.1l0.5,0
            l0.5-0.3l0.5-0.5h0.3l0.4,0.1l0.7,0.5l0.4,0.3l0.5,0.3l0.5,0.1l0.4,0l0.3,0l0.2,0.4l0.2,0.4l0.5,0.3l0.6,0.3l0.5,0.5l0.3,0.3
            l0.4,0.4l0.4,0.5l0.3,0.9l0.2,0.6l0.2,0.3l0,0.3l0.4,0.6l0.3,0.3l0.5,0.2l1,0.2l0.5,0.6l0.5,0.2l0.5,0.6l0.5,0.2l0.6,0l0.6,0.3
            l0.4,0.5l0.3,0.7l0.4,0.4l0.2,0.7l-0.2,0.8l0.2,0.4l0.5,0.4l0.7,0.4l0.7-0.1l0.3,0.1l0.2,0.3l0.1,0.4l0.3,1l0.2,0.6l-0.3,0.6
            l-0.1,0.4l0.1,0.6l0.7,0.3l0.6,0.2l0.4,0.4l0.4,0.1l0.6,0l0.8-0.1l0.4-0.3l0.3-0.1l0.3,0.1l0.7,0.2l0.8,0.3l0.8-0.2l0.7-0.1l0.7-0.2
            l0.7-0.5l0.6,0l0.4-0.3l0.5-0.5l0.5-0.5l0.4-0.1l0.7,0.2l0.9,0.3l0.5,0.4l0.4,0.1l0.4,0.3l0.8,0l0.8-0.2l0.7-0.5l0.7-0.2l0.5-0.2
            l0.4,0.1l1.7,1l0.5,0.5l0.6,0.1l0.5,0.3l0.8,0.3l0.4,0.3l0.6,0.2l0.4,0.4l0.6,0.3l0.6,0.1l0.3,0.4v0.2l-0.1,0.2l-0.6,0.9l-1.2,1.8
            l-1,1.6l-1.6,2.5l-0.9,1.4l0.2,0.1l1.4,0.6l0.4,0l0.5-0.2l0.3-0.2l0.3,0l0.3,0.2l0.5,0.3l0.3,0.9l0.3,0.4l0.6,0.3l0.6,0.6
            L381.5,312.7z"/>
    </a>
    <a xlink:title="República Dominicana" (click)="redirectToSearch(21)" [ngClass]="isAvailable(21)">
      <path id="DO" d="M366.9,131.2l0-1.4l0.2-0.6l-0.2-0.6l-0.9-0.6l-0.5-0.8l-0.5-0.7l0.1-0.1l1,0l0.3-0.3l0.7-0.7l0.1-0.6l-0.1-0.5
          l-0.4-0.5l-0.2-0.6l0.5-0.5l0.7-0.7l0.1-0.3l0-0.3l-0.8-0.8l-0.1-0.3l0.4-0.8l0-0.6l-0.4-1.7l-0.2-0.3l0.4-0.1l0.2-0.5l0.3-0.5
          l0.4-0.2l0.5-0.2l0.9,0l1.3,0.4l0.4,0l1.2-0.4l1-0.2l1,0.2l0.4,0.3l0.8,0.5l0.4,0.1l1.3,0l0.3,0l1.1,0.8l0.9,0.3l0.5,0l0.9-0.3
          l0.5,0l0.5,0.7l0.1,1l0.4,0.9l0.7,0.6l3.4-0.2l0.7,0.5l-0.3,0.4l-0.5,0.2l-1.6-0.1l-0.7,0l-0.1,0.4l0,0.4l0.9,0.1l0.9,0.2l0.9,0.3
          l0.9,0.2l1.1,0.1l1.1,0.2l1.8,0.7l1.9,1.6l0.5,0.4l0.3,0.5l-0.2,0.6l-0.7,1l-0.4,0.3l-0.6,0.2l-0.4,0.4l-0.4,0.7l-0.2,0.1l-0.3,0
          l-0.5-0.4l-0.3-0.6l-0.9-0.6l-1.1,0.1l-1.6-0.3l-1,0.2l-1,0l-1-0.2l-1-0.1l-1,0.2l-1,0.4l-0.4,0.2l-0.6,0.6l-0.3,0.2l-2.4,0.3
          l-0.7-0.4l-0.6-0.6l-0.9-0.1l-1.3,0.4l-0.8,0.2l-0.3,0.2l-0.1,0.2l0,0.8l-0.2,0.5l-1.3,1.9l-0.7,1.3l-0.3,0.4l-0.4,0.1l-0.6-0.8
          l-0.4-0.3l-0.5-0.1l-0.2-0.4l0-0.6l-0.1-0.6l-0.3-0.4L366.9,131.2z"/>
    </a>
    <a xlink:title="Suriname" (click)="redirectToSearch(23)" [ngClass]="isAvailable(23)">
        <path id="SR" d="M509.4,235.5l-0.7,0.6l-0.7,0.8l-1,1.4l0,0.4l-0.2,0.4l-0.1,0.6l0.1,0.7l0.3,0.5l0.1,0.9l-0.2,0.8l0.1,0.5l0.2,0.7
            l0.2,0.8l0,0.3l0.2,0.3l0.2,0.2l-0.1,0.7l0.8,1.2l0.5,0.5l0.7,0.5l0.2,0.5l0.4,0.6l0.2,0.1l0.1,0.2l-0.1,0.5l0,0.7l-0.4,0.8l-1,1.4
            l-0.1,0.3l0.3,1.2l-0.1,1l-0.1,0.5l-0.5,0.8l-1.2,2l-0.7,0.4l-0.4,0.6l-0.3,0l-0.3,0.1l-0.1,0.1l-0.4,0l-0.3-0.3l0-0.3l-0.2-0.4
            l-0.4-0.1l-0.7,0.1l-0.2-0.1l-0.4-0.4l-0.3-0.4l-0.1-0.4l-0.2,0l-0.5,0.4l-0.4,0.1l-0.3-0.1l-0.3,0l-0.8,0.4l-0.5,0.1l-0.3,0.4
            l-2.2,0.2l-0.6,0.1l-1.3-0.7l-0.3-0.2l-0.2,0l-0.1,0l-0.1,0.1l-0.2,0.8l-0.2,0.2l-0.3,0.2l-0.3,0.3l-0.1,0.3l0.5,0.2l0.4,0.6
            l0.5,0.5l0.4,0.4l-0.1,0.5l-0.1,0.7l-0.3,0.2l-0.5,0.1l-1.7-0.3l-1.3-0.3l-0.5-0.1l-0.2-0.1l-0.3-0.3l-0.3-0.2l-0.5-0.1l-0.6-0.2
            l-0.5-0.6l-0.5-0.9l-0.2-0.4l-0.4-0.4l-0.4-0.6l-0.1-0.5l-0.3-0.5l-0.1-0.2l-0.2-0.6l0-0.2l-0.1,0l-0.1-0.2l-0.3-0.7l-0.1-0.2
            l-0.1-0.1l-0.3-0.5l-0.3-0.2l-0.1-0.2l0-0.6l-0.1-0.3l0-0.6l0-0.2l-0.1-0.3l-0.2-0.2l0-0.4l-0.1-1.1l-0.1-0.2l-1,0l-0.1,0.1
            l-0.4,0.1l-0.5,0l-0.4-0.1l-0.4-0.2l-0.1-0.2l0.1-0.8l-0.6-0.6l-0.9-0.7l-0.3-0.9l-0.3-0.6l-1-1.2l-0.2-0.8v-0.6l0.4-0.5l0.5-0.9
            l0.2-0.8l0.1-0.4l0.3-0.6l0.2-0.7l-0.2-0.5l-0.3-0.4l-0.1-0.3l0.3-0.5l0.3-0.3l0.3,0l0.4-0.2l0.3-0.3l0.5-0.1l0.6,0h1.3l0.7-0.1
            l0.2-0.2l0-0.5l0.3-0.4l0.3-0.2l0.1-0.1l0-0.2l-0.1-0.1l-0.1-0.1l-0.4,0l-0.3-0.7l0.2-0.3l0.3-0.6l0.1-0.3l0.4-0.5l0.1,0.2l0.3-0.9
            l0-0.8l0.3-0.7l0.4-0.9l0.7-0.4l4.1,0.4l1.9,0.4l2.4,0.7l0.3,0.8l0-0.8l-0.1-0.8l0.7-0.6l1.5-0.2l2.2,0.3l1.9-0.3l2.5,0l3.9,0.6
            l1.7,0.4l0.7,0.4l0.1,0.7l-0.1,0.9l-0.3,0.9L509.4,235.5z"/>
    </a>
    <a xlink:title="Uruguay" (click)="redirectToSearch(25)" [ngClass]="isAvailable(25)">
        <path id="UY" d="M515.8,567.2l-0.4,0.4l-0.4,0.7l-0.5,1.6l-1.7,2.3l-0.3,1.3l-1.8,1.3l-1.3,1.5l-0.8,0l-0.8,0.6l-4.3,2l-1.6-0.4
            l-1.2,0l-1.1-0.9l-2.4-0.3l-1.5,0.3l-2.1,1l-0.6,0l-0.4-0.1l-1.1-0.4l-0.6-0.8l-3.2-1l-2.6-2.2l-3,0l-2.3,0.3l-0.4-0.3l-0.2-0.6
            l-0.5-0.8l-2-1.9l-1.6-1.9l-0.3-1.9l0.2-2l0.5-2.4l-0.1-0.7l0.6-0.4l0.6-0.1l0.6-0.6l0.5-0.9l0.1-0.7l-0.4-1.3l-0.3-1.8l-0.3-0.9
            l0.6-1.4l0-0.7l-0.4-0.6l-0.1-0.6l0.2-0.6l0-0.6l-0.2-0.6l0.2-0.5l0.6-0.4l0.4-0.6l0.3-0.8l0.1-0.6l0-0.4l-0.2-0.4l-0.4-0.4l0.2-0.7
            l0.7-1.1l0.4-1l0.2-0.9l0-0.7l-0.2-0.5l0.1-0.4l0.4-0.2l0.2-0.6l-0.1-1.4l-0.4-1.1l0.3-0.9l1-1l0.5-0.8l0-0.6l0.3-0.4l0.5,0.7
            l1.4,0.2l1.4,0l0.2-0.2l0.5-1.1l0.7-0.3l0.8-0.1l0.8,0.1l0.9,0.7l2.5,2.4l1.9,1.7l0.6,0.8l0.5,0.6l0.4,0.6l-0.2,1.5l0,0.6l0.1,0.2
            l0.4,0l0.6-0.1l0.5-0.3l0.4-0.5l0.4-0.4l0.3-0.2l0.1-0.3l0.2-0.3l0.2-0.1l0.4,0.2l0.9,0.8l0.7,0.8l0.2,0.4l0.3,0.5l0.3,0.4l0.2,0.4
            l0.7,0.5l0.7,0.3l0.4-0.3l1.1,1.1l2.5,0.9l0.5,0.5l0.4,0.8l0.9,1.2l1.2,1l1,0.4l0.9,0.3l0.5,0.2l0.4,0.4l0.6,0.4l0.4,0.2l0.1,0.4
            l0.4,0.8l0.4,1.1l0.4,1l0.9,1l1,0.7l1.1,0.4l0.6,0.5l0.3,0.5l-0.7,0.8l-0.8,1l-0.7,0.8l-0.7,0.6l-0.2,0.4l-0.2,0.6l0,3.2l-0.1,1.2
            l0,0.3l0.1,0.2l0.4,0.3l0.5,0.3L515.8,567.2z"/>
    </a>
    <a xlink:title="Venezuela" (click)="redirectToSearch(26)" [ngClass]="isAvailable(26)">
        <path id="VE" d="M462,209.7l1.5,2l0,0.2l-0.2,0.2l-0.9,0.5l-0.2,0.2l-0.3,0.9l-1.2,0.5l-0.8,0.6l-0.5,0.5l-0.3,0.2l-1.1,0.1
            l-0.3,0.3l-0.4,1l-0.3,0.4l-0.6,0.5l0,0.3l0.8,1.1l0.1,0.3l-0.2,0.5l0,0.4l0.4,0.4l0.5,0.1l0.5-0.2l0.6,0l0.4,0.1l0.2,0.1l0,0.3
            l-0.2,0.7l-0.3,0.5l-1.5,0.7l-0.7,0.4l-0.4,0.3l-0.8-0.2l-0.4,0l-0.3,0.2l-0.2,0.2l-0.6,0.1l-0.8,0.1l-0.3,0.1l-0.3,0.4l-0.2,0.5
            l0.2,0.6l0.2,0.5l0,0.5l0.2,1.4l-0.2,0.3l-0.5,0.4l-0.6,0.6l-0.7,0.9l0.1,0.3l1.7,1.9l1.7,1.9l1.7,1.9l0.2,0.1l0.3,0.2l0.3,0.7
            l0.2,0.7l0,0.4l-0.2,0.5l-0.4,0.5l-0.5,0.4l-0.7,0.4l-0.6,0.3l-0.5,0.9l-0.3,0.3l-0.3,0.1l-0.5,0.1l-0.9,0l-0.6-0.1l-0.7,0.7
            l-0.9,0.2l-0.6,0.9l-2.2,0.7l-2.1,0.6l-0.6,0.2l-2.1-0.5l-0.5,0.1l-0.6,0.4l-0.5,0.3l-0.4,0l-0.4,0.2L440,247l-0.2,2.2l-0.7,0.6
            l-0.9,0l-0.6-0.7l-0.7-0.6l-1.3-1.3l-0.4-0.2l-0.3,0l-1.2,0.4l-0.6-0.2l-0.5-0.2l-0.8,0.1l-1.4,0l-0.9,0l-0.4-0.4l-0.4-0.7l-0.3-0.3
            l-0.3-0.2l-0.5-0.1l-2.2,0h-0.4l-0.3-0.1l-0.4-0.6l-0.5-0.3l-0.5,0l-0.2,0.4l0.9,1.2l0.3,0.6l0.8,0.9l2.4,1.9l0.4,0.6l0,0.8l0,1.2
            l0.1,1.1l0.6,1.6l0.9,1.6l0.2,1l-0.2,0.8l-0.2,0.4l0,0.2l0.2,0.2l0.8,0.2l1.7,0.1h1l1.6,0.2l0.1,0.6l-0.2,0.9l-0.3,0.5l-0.3,0.2
            l-0.9,0.1l-0.9,0.6l-1.3,0.6l-0.7,0.1l-0.3,0.1l-0.3,0.2l-0.2,0.2l-0.3,1.1l-0.4,1.2l-0.7,0.7l-0.8,0.6l-0.8,0.1l-0.7,0l-0.3,0.2
            l-0.5,0.5l-0.7,0.6l-0.5,0.3l-0.7,0l-0.7,0.3l-0.9,0.5l-0.6,0.4l-0.5,0.7l-0.8,0.7l-0.8,0.5l-0.4,0.6l-0.5,0.8l-0.7,0l-0.1-0.5
            l0.3-0.8l-0.3-0.7l-0.6-0.4l-0.3-0.1l-0.3,0l-0.8,0.3l-0.9,0.6l-0.6,0.4l-0.5,0.2l-1.1,0.2l-0.9,0.1l-0.4-0.1l-0.7-0.4l-1.5-1.4
            l-2.1-1.9l-0.2-0.5l0.1-0.5l-0.4-0.8l-0.2-0.9l-0.2-0.3l-0.1-0.6l-0.4-1.1l-0.4-0.9l-0.3-0.5l0.1-0.4l-0.1-0.4l-0.3-0.3l-0.4-1.1
            l0.1-0.5l-0.1-0.5l-0.3-0.3l-0.5-0.3l-0.6-0.7l-0.8-0.7l-0.4-0.3l-0.3-0.1l-0.2-0.6l-0.2-0.2l-0.4-0.1l-0.8-0.3l-0.7,0.3l0-0.5
            l0.2-0.3l2.6-2.4l1.3-1.1l0.1-0.2l0.1-0.3l0.1-0.3l-0.3-0.4l-1.2-1.8l-0.4-0.3l-0.4-0.3l-0.5-0.8l-0.6-1.8l-0.4-0.9l-0.1-0.7l0-0.8
            l-0.2-0.6l-0.3-0.4v-1.3l0.3-2.1l0.1-1.6l-0.2-1.1l0.3-0.8l0.8-0.6l0.4-0.9l0.1-1.2l0.5-1l0.8-0.8l0.3-0.8l-0.3-0.8l-0.1-0.5
            l-0.7-0.5l-1.3-0.3l-1.1,0l-0.6,0.4l-1.7,0.4l-2.7,0.3h-2.1l-1.6-0.3l-1.2,0.1l-0.8,0.6l-0.6,0.1l-0.3-0.3l-0.4-0.1l-0.6,0.2
            l-0.1-0.1l-1.3-1.5l-1.2-1.4l-1.3-1.7l-1.5-1.9l-0.3-0.1l-0.5,0l-0.6,0l-1-0.2l-0.7-0.3l-0.5-0.3l-1-0.3l-0.7-0.1l-0.6,0.1l-1.6,0.7
            l-0.9,0.1l-0.7,0l-1.9-0.3l-1.3-0.1l-1.5,0.2l-0.7,0.1L365,222l-0.6-0.5l-0.6-1.2l-0.4-1l-0.7-0.2l-0.8-0.2l-0.4-0.3l-0.2-0.6
            l-0.1-0.7l0.1-1.2l0.1-0.4l0.1-1.3l0.4-0.7l0.3-0.3l-0.1-0.5l-0.2-1.1l-0.2-0.8l-0.9-0.9l-1.1-1.1l-0.5-1.8l-0.6-2.1l-0.4-0.2
            l-0.4,0.1l-0.4-0.1l-0.4-0.9l-0.4-0.2l-0.6,0.3l-0.5,0.2l-1.2,0.2l-0.2-0.2l0.1-0.3l0.5-0.8l0.6-1l0.7-0.9l0.6-0.9l0.5-1l0.3-2
            l0.2-1.4l0.6-2.4l1.1-1.9l0.4-0.9l1-1.2l0.4-0.6l0.6-0.5l1.6-0.7l1.9-3.3l0.4-0.5l1.9-0.5l1.5-0.4l1.1-0.4l0.4-0.2l0.3-0.1l-0.2,0.4
            l-0.5,0.5l-0.6,0.3l-3.1,0.8l-0.3,0.2l-0.4,0.3l0,0.7l0.1,0.6l0.9,1.8l0.4,0.5l1.2,1l-0.3,0.1l-0.4,0l0.3,1.3l0.7,0.9l0,0.6
            l-0.6,1.7l-1,1l-0.7,1.2l-0.6,0.5l-1.3,2.4l1,1.4l0.1,0.7l0.8,1l0.5,0.3l0.4,0.4l-0.2,0.7l0.3,0.9l0.4,0.5l0.5,0.2h0.7l1.9-0.6
            l0.5-0.3l0.3-0.5l1-1l0.1-1.3l0.2-1.6l-0.2-1l-1-1.5l-0.4-1.1l-1-1l-0.6-1.7l-0.3-0.5l-0.2-0.7l-0.2-1.3l0.7-0.5l-0.1-1.1l1.7-0.3
            l3.6-1.7l2.2-0.4l2.5-0.9l0.6-0.5l0.5-0.8l0.4-0.1l1.3,0.7l0.7-0.2l0.3-0.6l-0.4-1.1h-0.8l-2.3,0.4l-0.2-0.5v-0.4l-0.5-1.3l0.3-1
            l0.3-0.8l0.7-0.3l1-0.3l0.7,0.5l0.4,0.5l0.2,0.5l0.2,1.3l0.4,1.3l0.4,0.9l0.7,0.7l0.5,0l0.4-0.1l2.4-0.2l1.4,0.5l1.8,0.2l1.7,1
            l1.8,1.2l0.4,0.9l0.1,0.8l0.4,0.6l-0.4,0.6l0.2,1l0.5,1l0.8,0.6l2.2,0.2l2.3-0.4l3.6-0.4l1.2-0.3l6-0.2l1.1,0.5l0.1,0.5l0,0.4
            l1.9,1.8l1.6,0.2l1.3,0.6l1.4,0.3l1.5,0.4l0.9-0.1l0.6-0.1l0.8,0l5.4-2.9l2.9,0.1l0.4-0.2l0.4-0.3l-1.1-0.4l-2.4-0.2l-0.7,0.3
            l-0.4-0.8l0.8,0l2.7-0.3l3,0.2l2.5-0.5l1.2-0.1l0.7,0.1l2-0.3l3.7,0.4l2.9-0.3l-0.3,0.5l-1,0.3l-1.6,0.1l-1.2,0.7l-2.5-0.1l-1.8,0.3
            l0.6,0.2v0.7l0.2,0.1l0.3,0l0.6,0.5l0.2,0.4l0.2,0.7l-0.3,0.8l-0.4,0.4l0.7-0.1l0.4-0.4l-0.1-0.4l0.1-0.4l0.4,0.1l0.3,0.2l0.9,2.1
            l0.7,1.1l0.2,0l0.2,0l0.2-0.2l0.3-0.5l0.2,0.3l0.1,0.1l0.1,0l-0.1-0.5l0.2-0.6l-0.1-0.2l0.3,0l0.3,0.1l0.5,0.2l0.9,0.7l0.6,0.7
            l0,0.4l0.2,0.2l0.4,0.2l0.2,0.4l0-0.6l-0.2-0.4l-0.1-0.5l1.1,0l0.3-0.6l0.6,0.4l1.6,1.7l0.6,0.3l1.8,0.3l1.1,0.8l0.7,0.8l-0.4,0.8
            l-1.1,0.4l-0.4,0.5l-0.2,0.5l0,0.5l-0.3,0.6l0,0.2l-0.2,0.8l-0.4,1l-0.6,1l-3,0l0.7,0.4l0.7,0.3l1.1,0.8l0.9-0.6l1.3,0l1.4-0.7
            l0.5-0.1l2.6,0.4l0.6-0.5l0.5-0.1l1.4,0.1L462,209.7L462,209.7z M431,188.6l0.3,1.1l-0.1,0.2l-0.7,0.7l-0.6,0.1l-0.5,0l-0.4-0.1
            l-0.5-0.5l-0.5,0.1l-1.2-0.2l-0.3-0.2l0.4-0.6l0.8-0.2l0.3,0l0.2,0.4l0.6,0.3l0.7,0l0.2-0.5l0.9-0.8L431,188.6L431,188.6z
             M420,190.5l-0.4,0.2l-0.8-0.2l-0.4-0.3l0.3-0.3h0.7l0.6,0.4L420,190.5z M455.5,204.9l-1,0.3l0-0.2l0.3-0.4l0.5-0.1l0.2-0.1l0.3-0.1
            l0.2,0l0.2,0.1l-0.4,0.2L455.5,204.9L455.5,204.9z M454.1,207.1l-0.5,0.2l-0.1-0.8l0.2-0.2l0.9-0.7l0.2-0.1l0.2,0.1l0,0.2l0.4,0.3
            l-0.1,0.4l-0.4,0.4L454.1,207.1L454.1,207.1z"/>
    </a>
    </svg>
</div>
