
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'date'
})
export class AlteredDatePipe implements PipeTransform {
    transform(date: string): string {
        const parsedDate = new Date(date)
        return `${parsedDate.getDate()} de ${Months[parsedDate.getMonth()]} del ${parsedDate.getFullYear()}`
    }
}

enum Months {
    ENERO,
    FEBRERO,
    MARZO,
    ABRIL,
    MAYO,
    JUNIO,
    JULIO,
    AGOSTO,
    SEPTIEMBRE,
    OCTUBRE,
    NOVIEMBRE,
    DICIEMBRE
}
