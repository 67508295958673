<main class="container col-md-6 align-items-center justify-content-center pt-4">
    <h1 class="text-center">Actualizar informacion de usuario</h1>
        <form [formGroup]="form">
          <div class="row">
            <mat-form-field class="col-12 col-md-6 pb-2" appearance="outline">
              <mat-label>Nombre</mat-label>
              <input matInput type="text" name="name" formControlName="firstName">
                <mat-error>El nombre no es valido</mat-error>
            </mat-form-field>
            <mat-form-field class="col-12 col-md-6 pb-2" appearance="outline">
              <mat-label>Apellido</mat-label>
              <input matInput type="text" name="lastName" formControlName="lastName">
              <mat-error>El apellido no es valido</mat-error>
            </mat-form-field>
            <mat-form-field class="col-12 w-100 pb-2" appearance="outline">
              <mat-label>Email</mat-label>
              <input matInput type="text" name="user" formControlName="email" [disabled]="true">
              <mat-error>El email no es valido</mat-error>
            </mat-form-field>

          </div>
          <div class="row py-3">
            <a class="col-6 pb-3" (click)="toggleEditPassword()">
              Actualizar contraseña
            </a>
            <ng-container *ngIf="isEditingPassword">
              <mat-form-field class="col-12 w-100 pb-2" appearance="outline">
                <mat-label>Contraseña</mat-label>
                <input matInput type="password" name="user" formControlName="password" autocomplete="new-password">
                <mat-error>La contraseña no es valida</mat-error>
              </mat-form-field>
              <mat-form-field class="col-12 w-100 pb-2" appearance="outline">
                <mat-label>Repetir Contraseña</mat-label>
                <input matInput type="password" name="user" formControlName="repeatPassword" autocomplete="new-password">
                <mat-error>Las contraseñas no coinciden</mat-error>
              </mat-form-field>
            </ng-container>
          </div>
          <div class="row justify-content-center">
            <button mat-raised-button color="accent" class="w-50" (click)="submit()" [disabled]='form.invalid'><div *ngIf="!isSubmiting">Actualizar Informacion</div><div *ngIf="isSubmiting" class="spinner-container"><mat-spinner diameter="30"></mat-spinner></div></button>
          </div>
        </form>
</main>
