<div class="container">
  <div class="row my-5 w-100 h-100 align-items-center justify-content-between">
    <div class="col-lg-6 h-100">
      <img src="../../../assets/images/logo_dashboard.png" class="logo pb-2" />
      <p class="about-text">
        Directorio Legislativo es una organización de la sociedad civil con sede
        en Argentina y Estados Unidos, que trabaja hace más de quince años para
        fortalecer las instituciones democráticas en América Latina y el Caribe.
      </p>
    </div>
    <div class="col-lg-6">
      <div class="row justify-content-center social-footer">
        <button mat-icon-button>
          <a href="https://directoriolegislativo.org/" target="_blank">
            <fa-icon [icon]="faLink"></fa-icon>
          </a>
        </button>
        <button mat-icon-button>
          <a href="https://x.com/DireLegislativo" target="_blank">
            <fa-icon [icon]="faX"></fa-icon>
          </a>
        </button>
        <button mat-icon-button>
          <a
            href="https://www.linkedin.com/company/fundaci-n-directorio-legislativo/"
            target="_blank"
          >
            <fa-icon [icon]="faLinkedinIn"></fa-icon>
          </a>
        </button>
        <button mat-icon-button>
          <a href="https://www.instagram.com/direlegislativo/" target="_blank">
            <fa-icon [icon]="faInstagram"></fa-icon>
          </a>
        </button>
        <button mat-icon-button>
          <a
            href="https://www.tiktok.com/@directoriolegislativo"
            target="_blank"
          >
            <fa-icon [icon]="faTikTok"></fa-icon>
          </a>
        </button>
      </div>
      <div class="row justify-content-center mt-5">
        <div class="phone-container">
          <div class="row">
            <p>BUENOS AIRES (ARGENTINA)</p>
          </div>
          <p>+54 11 5218 - 4847</p>
        </div>
        <div class="phone-container">
          <div class="row">
            <p>WASHINGTON DC (USA)</p>
          </div>
          <p>+1(786) 828-0675</p>
        </div>
      </div>
    </div>
  </div>
  <hr />
  <div class="row my-3">
    <span style="text-align: center">© Directorio Legislativo 2024</span>
  </div>
</div>
