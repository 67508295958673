import { ApiService } from "./api.service"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { HttpParams } from "@angular/common/http"
import { RegulationType } from "../enums/regulation-type.enum"


@Injectable({
    providedIn: "root"
})
export class MovementsService{
    constructor(private apiService: ApiService) { }

    private url = "Movimientos"

    fetchById(typeId: RegulationType, id: string): Observable<any> {
        let params = new HttpParams().set("entidadId", id).set("entidadTipoId", typeId)
        return this.apiService.get(this.url, { params })
    }


}
