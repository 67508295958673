import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import {
  faInstagram,
  faLinkedinIn,
  faTiktok,
  faXTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { faLink } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('loading', [
      state(
        'loading',
        style({
          opacity: '*',
          visibility: '*',
        })
      ),
      state(
        'loaded',
        style({
          opacity: 0,
          visibility: 'hidden',
        })
      ),
      transition('loading => loaded', [animate('1000ms ease-in')]),
    ]),
    trigger('searchbarVisible', [
      // ...
      state(
        'open',
        style({
          width: '100%',
          opacity: 1,
        })
      ),
      state(
        'closed',
        style({
          width: '0px',
          opacity: 0,
        })
      ),
      transition('open => closed', [animate('0.5s')]),
      transition('closed => open', [animate('0.5s')]),
    ]),
  ],
})
export class HeaderComponent {
  @ViewChild('sidenav') sidenav: MatSidenav | undefined;

  faLink = faLink;
  faInstagram = faInstagram;
  faTikTok = faTiktok;
  faXTwitter = faXTwitter;
  faLinkedinIn = faLinkedinIn;
  isSearchbarVisible = false;
  searchbar = '';
  user = JSON.parse(localStorage.getItem('user') || '{}');
  route: string = '';

  constructor(
    @Inject(DOCUMENT) public document: Document,
    private router: Router
  ) {
    this.route = this.router.url;
  }

  navigate(route: string, params?: { [key: string]: string }) {
    this.sidenav?.toggle();
    if (this.route === '/csg') {
      this.router.navigate([`/csg${route}`], { queryParams: params });
    } else {
      this.router.navigate([route], { queryParams: params });
    }
  }

  toggleSearchbar() {
    if (this.searchbar === '') {
      this.isSearchbarVisible = !this.isSearchbarVisible;
    } else {
      this.router.navigate(['/search', { searchbar: this.searchbar }]);
      this.searchbar = '';
      this.isSearchbarVisible = false;
    }
  }

  logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('access_token');
    this.router.navigate(['/login']);
  }
}
