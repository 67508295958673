<main class="position-relative w-100 h-100 px-xxl-5">

  <div class="row gx-5 justify-content-center justify-content-lg-between h-100 align-items-center px-2 px-lg-5">
    <div class="col-4 col-xl-5 background-text d-none d-lg-flex">
      <div class="d-flex flex-column justify-content-center">
        <h1 class="text-white fw-semibold">
            Fortalecemos las democracias de America Latina
        </h1>
        <h3 class="text-white fs-3">
          Directorio Legislativo is a civil society organization based in Argentina and the United States that has been working for more than ten years to strengthen democratic institutions in Latin America and the Caribbean.
        </h3>
      </div>
    </div>
    <mat-card class="col-12 col-lg-6 py-5 px-5 mx-lg-5 d-flex flex-column">
      <mat-card-header class="d-flex flex-column">
        <mat-card-title>
          <h1 class="fw-semibold fs-1 text-center pb-4">
            Iniciar sesión
          </h1>
        </mat-card-title>
        <div class="d-flex justify-content-center">
          <!-- <button mat-raised-button class="w-100 google-button">
            <div class="d-flex gap-3 justify-content-center">
              <div class="logo">
                <svg width="100%" height="100%" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0)"><path d="M17.6429 9.2067C17.6429 8.59491 17.5933 7.9798 17.4875 7.37793H8.99838V10.8437H13.8597C13.658 11.9614 13.0098 12.9502 12.0607 13.5786V15.8273H14.9609C16.664 14.2598 17.6429 11.9449 17.6429 9.2067Z" fill="#4285F4"></path><path d="M8.99832 18C11.4257 18 13.4727 17.203 14.9641 15.8273L12.0639 13.5786C11.257 14.1275 10.2153 14.4384 9.00162 14.4384C6.65365 14.4384 4.66284 12.8543 3.94853 10.7246H0.955692V13.0428C2.48353 16.0819 5.59541 18 8.99832 18Z" fill="#34A853"></path><path d="M3.94531 10.7246C3.56831 9.60685 3.56831 8.39649 3.94531 7.27872V4.96051H0.955782C-0.32072 7.5036 -0.32072 10.4997 0.955782 13.0428L3.94531 10.7246Z" fill="#FBBC04"></path><path d="M8.99832 3.56164C10.2814 3.54179 11.5216 4.02462 12.4508 4.91089L15.0204 2.34135C13.3933 0.813518 11.2338 -0.0264599 8.99832 -3.85195e-06C5.59541 -3.85195e-06 2.48353 1.91806 0.955692 4.9605L3.94522 7.2787C4.65623 5.14569 6.65035 3.56164 8.99832 3.56164Z" fill="#EA4335"></path></g><defs><clipPath id="clip0"><rect width="17.6428" height="18" fill="white"></rect></clipPath></defs></svg>
              </div>
              Iniciar sesión con Google
            </div>
          </button> -->
        </div>
      </mat-card-header>
      <!-- <div class="row align-items-center">
        <mat-divider class="col-5"></mat-divider>
        <div class="col-2 text-center my-4">
          <h3 class="m-0">o</h3>
        </div>
        <mat-divider class="col-5"></mat-divider>
      </div> -->
      <mat-card-content>
        <form [formGroup]="form">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput type="text" name="user" formControlName="email">
          </mat-form-field>
          <div>
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Contraseña</mat-label>
              <input matInput type="password" name="password" formControlName="password">
            </mat-form-field>
            <div class="d-flex justify-content-end pb-3">
              <a routerLink="/forgot-password" class="text-decoration-none">
                ¿Olvidaste tu contraseña?
              </a>
            </div>
          </div>
          <div class="d-flex justify-content-center pb-2">
            <mat-error *ngIf="form.hasError('invalidCredentials')">
              El email o la contraseña son incorrectos
            </mat-error>
          </div>
          <div class="d-flex justify-content-center">
            <button mat-raised-button color="accent" class="w-100" (click)="submit()" [disabled]='form.invalid'><span *ngIf="!isSubmiting">Iniciar sesión</span><div *ngIf="isSubmiting" class="spinner-container"><mat-spinner diameter="30"></mat-spinner></div></button>
          </div>
        </form>
        <div class="mt-4">
          ¿No tenes una cuenta?
          <a href="mailto:pgarcia@directoriolegislativo.org" class="text-decoration-none">
            Conoce como podes acceder
          </a>
        </div>
      </mat-card-content>
    </mat-card>
  </div>


</main>
