import { Component, Input, OnInit } from '@angular/core';
import { RegulationType } from 'src/app/core/enums/regulation-type.enum';
import { Regulacion } from 'src/app/core/models/regulacion.model';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  @Input() related: Boolean = false
  @Input() news: Regulacion[] = []
  visibleNews = this.news.slice(0, 3)

  constructor() { }

  ngOnInit(): void {
    this.visibleNews = this.news.slice(0, 3)
  }

  get newsType() {
    return RegulationType.NOTAS
  }
}
