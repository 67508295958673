<div class="container pt-3">
    <section class="row first-section">
        <div class="col-lg-5 map-container mb-5">
            <h3><span class="color-span">Paises</span> en monitoreo</h3>
            <div class="row">
                <app-map  style="padding: 0px"></app-map>
            </div>
        </div>
        <div class="col-lg-7 regulations-container mb-3">
            <h3><span class="color-span">Regulaciones</span> en monitoreo</h3>
            <app-regulation-list
              [(queryParams)]="regulationsQueryParams"
            ></app-regulation-list>
        </div>
    </section>
</div>
<div class="news-color-wrapper" *ngIf="news.length > 0">
    <app-news [news]="news"></app-news>
</div>
