import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/core/services/login.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {

  submitted = false;

  form = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
  })

  constructor(
    private loginService: LoginService,
    private formBuilder: FormBuilder,
    private router: Router,
  ) { }

  submit() {
    if (this.form.invalid) {
      return
    }
    this.loginService.forgotPassword(this.form.value.email!).subscribe(() => {
    this.submitted = true;
    }, (error) => {
      this.form.setErrors({
        invalidCredentials: true
      })
    })
  }

}
