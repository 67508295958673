<main class="position-relative w-100 h-100 px-xxl-5">

  <div class="row gx-5 justify-content-center justify-content-lg-between h-100 align-items-center px-2 px-lg-5">
    <div class="col-4 col-xl-5 background-text d-none d-lg-flex">
      <div class="d-flex flex-column justify-content-center">
        <h1 class="text-white fw-semibold">
            Fortalecemos las democracias de America Latina
        </h1>
        <h3 class="text-white fs-3">
          Directorio Legislativo is a civil society organization based in Argentina and the United States that has been working for more than ten years to strengthen democratic institutions in Latin America and the Caribbean.
        </h3>
      </div>
    </div>
    <mat-card class="col-12 col-lg-6 py-5 px-5 mx-lg-5 d-flex flex-column">
      <mat-card-header class="d-flex flex-column">
        <mat-card-title>
          <h1 class="fw-semibold fs-1 text-center pb-4">
            Restaurar contraseña
          </h1>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="form" class="row">
            <mat-form-field class="col-12 w-100 pb-2" appearance="outline">
              <mat-label>Nueva Contraseña</mat-label>
              <input matInput type="password" name="password" formControlName="password" autocomplete="new-password">
              <mat-error class="mt-4">
                La contraseña debe tener al menos 8 caracteres, una mayúscula, una minúscula y un número
              </mat-error>
            </mat-form-field>
            <mat-form-field class="w-100" [ngClass]="this.form.get('password')?.invalid && this.form.get('password')?.touched ? 'mt-5' : ''" appearance="outline">
              <mat-label>Repetir Contraseña</mat-label>
              <input matInput type="password" name="password" formControlName="repeatPassword" autocomplete="new-password">
              <mat-error class="mt-4">
                Las contraseñas no coinciden
              </mat-error>
            </mat-form-field>
          <div class="d-flex justify-content-center mt-4">
            <mat-error *ngIf="form.hasError('invalidCredentials')">
              El token es inválido, por favor solicite un nuevo email
            </mat-error>
          </div>
          <div class="d-flex justify-content-center">
            <button mat-raised-button color="accent" class="w-100" (click)="submit()" [disabled]='form.invalid'>
              Cambiar contraseña
            </button>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>


</main>
