import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { fromEvent } from 'rxjs';
import { debounceTime, pluck } from 'rxjs/operators';
import { RegulationListComponent } from 'src/app/components/regulation-list/regulation-list.component';
import { Level, Levels } from 'src/app/core/enums/levels.enum';
import { RegulationType } from 'src/app/core/enums/regulation-type.enum';
import { Client } from 'src/app/core/models/client.model';
import { EntityQueryParams } from 'src/app/core/models/query-params.model';
import { Regulacion } from 'src/app/core/models/regulacion.model';
import { Theme } from 'src/app/core/models/theme.model';
import { ApiService } from 'src/app/core/services/api.service';
import { FiltersService } from 'src/app/core/services/filters.service';

interface Filter {
  id: number;
  name: string;
  selected: boolean;
  country?: number;
}
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  faFilter = faFilter;
  regulaciones: Regulacion[] = [];
  sentRegulations: Regulacion[] = [];
  client: Client;
  regulationsQueryParams: EntityQueryParams = new EntityQueryParams();

  // filter values
  orderBy = 'date';

  themes: Filter[] = [];
  authors: Filter[] = [];
  visibleAuthors: Filter[] = [];
  countries: Filter[] = [];
  levels = Levels;
  impacto?: Level;
  probabilidad?: Level;
  loading = true;
  defaultRegulation: RegulationType;
  searchbar = '';

  loadingPaises = true;
  loadingTemas = true;
  loadingAutores = true;

  @ViewChild('regulationList')
  private regulationList: RegulationListComponent;

  @ViewChild('search', { static: false }) searchInput: ElementRef;

  constructor(
    private filtersService: FiltersService,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.regulationsQueryParams.pageSize = 20;
    this.regulationsQueryParams.sortBy = 'FechaUltimaModificacion';
    if (this.router.url.includes('csg')) {
      this.regulationsQueryParams.csg = true;
    }
    if (this.route.snapshot.queryParams.country) {
      const country = this.route.snapshot.queryParams.country;
      if (typeof country === 'string') {
        this.regulationsQueryParams.paises = [Number(country)];
      } else {
        this.regulationsQueryParams.paises = country.map((c: string) =>
          Number(c)
        );
      }
    }

    if (this.route.snapshot.queryParams.regulationType) {
      const regulationType = Number(
        this.route.snapshot.queryParams.regulationType
      );
      this.defaultRegulation = regulationType as RegulationType;
    }

    if (this.route.snapshot.queryParams.theme) {
      const theme = this.route.snapshot.queryParams.theme;
      if (typeof theme === 'string') {
        this.regulationsQueryParams.temas = [Number(theme)];
      } else {
        this.regulationsQueryParams.temas = theme.map((t: string) => Number(t));
      }
    }

    if (this.route.snapshot.queryParams.author) {
      const author = this.route.snapshot.queryParams.author;
      if (typeof author === 'string') {
        this.regulationsQueryParams.autores = [Number(author)];
      } else {
        this.regulationsQueryParams.autores = author.map((a: string) =>
          Number(a)
        );
      }
    }

    if (this.route.snapshot.queryParams.impacto) {
      this.regulationsQueryParams.impacto = Number(
        this.route.snapshot.queryParams.impacto
      );
      this.impacto =
        this.regulationsQueryParams.impacto === 1
          ? 'Alta'
          : this.regulationsQueryParams.impacto === 2
          ? 'Media'
          : this.regulationsQueryParams.impacto === 3
          ? 'Baja'
          : undefined;
    }

    if (this.route.snapshot.queryParams.probabilidad) {
      this.regulationsQueryParams.probabilidadSancion = Number(
        this.route.snapshot.queryParams.probabilidad
      );
      this.probabilidad =
        this.regulationsQueryParams.probabilidadSancion === 1
          ? 'Alta'
          : this.regulationsQueryParams.probabilidadSancion === 2
          ? 'Media'
          : this.regulationsQueryParams.probabilidadSancion === 3
          ? 'Baja'
          : undefined;
    }

    this.searchbar = this.route.snapshot.queryParamMap.get('searchbar') ?? '';
    if (this.searchbar) this.regulationsQueryParams.keyword = this.searchbar;
  }

  ngOnInit(): void {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    if (user.clienteId) {
      this.apiService
        .get('Clientes/' + user?.clienteId)
        .subscribe((res: any) => {
          this.client = res;
          this.themes = this.client.temasRelacionados.map((theme) => ({
            id: theme.id,
            name: theme.descripcionTema,
            selected: this.regulationsQueryParams.temas
              ? this.regulationsQueryParams.temas.includes(theme.id)
              : false,
          }));
          this.loadingTemas = false;
        });
      this.apiService.get('Usuarios/' + user?.userId).subscribe((res: any) => {
        const servicioDashboard = res.serviciosContratados.find(
          (servicio: any) => servicio.servicioId === 2
        );
        this.countries = servicioDashboard.paises.map((country: any) => ({
          id: country.id,
          name: country.nombre,
          selected: this.regulationsQueryParams.paises
            ? this.regulationsQueryParams.paises.includes(country.id)
            : false,
        }));
        this.loadingPaises = false;
      });
    } else {
      this.filtersService.fetchThemes().subscribe((res) => {
        this.themes = res.map((theme: Theme) => ({
          id: theme.id,
          name: theme.descripcionTema,
          selected: this.regulationsQueryParams.temas
            ? this.regulationsQueryParams.temas.includes(theme.id)
            : false,
        }));
        this.loadingTemas = false;
      });
      this.filtersService.fetchCountries().subscribe((countries) => {
        this.countries = countries.map((country) => ({
          id: country.id,
          name: country.name,
          selected: this.regulationsQueryParams.paises
            ? this.regulationsQueryParams.paises.includes(country.id)
            : false,
        }));
        this.loadingPaises = false;
      });
    }
    this.filtersService.fetchAuthors().subscribe((authors) => {
      this.authors = authors.map((author) => ({
        id: author.id,
        name: `${author.nombreApellido} (${author.pais.name})`,
        selected: this.regulationsQueryParams.autores
          ? this.regulationsQueryParams.autores.includes(author.id)
          : false,
        country: author.paisId
      }));

      this.visibleAuthors = (this.regulationsQueryParams.paises ?? []).length > 0 ? this.authors.filter((author) =>
        this.regulationsQueryParams.paises!.some((country) => country === author.country)
      ) : this.authors;
      this.loadingAutores = false;
    });
  }

  ngAfterViewInit() {
    fromEvent(this.searchInput.nativeElement, 'input')
      .pipe(pluck('target', 'value'), debounceTime(700))
      .subscribe(() => {
        this.regulationsQueryParams.keyword = this.searchbar;
        this.router.navigate([], {
          queryParams: { searchbar: this.searchbar },
          queryParamsHandling: 'merge',
        });
        this.regulationList.loadRegulations();
      });
  }

  sort() {
    if (this.orderBy === 'date') {
      this.regulationsQueryParams.sortBy = 'FechaIngreso';
    } else {
      this.regulationsQueryParams.sortBy = 'FechaUltimaModificacion';
    }
    this.regulationList.loadRegulations();
  }

  filterByCountries() {
    const selectedCountries = this.countries.filter(
      (country) => country.selected
    );
    this.router.navigate([], {
      queryParams: { country: selectedCountries.map((country) => country.id) },
      queryParamsHandling: 'merge',
    });
    if (selectedCountries.length > 0) {
      this.regulationsQueryParams.paises = selectedCountries.map(
        (country) => country.id
      );
      this.visibleAuthors = this.authors.filter((author) =>
        selectedCountries.some((country) => country.id === author.country)
      );
    } else {
      this.regulationsQueryParams.paises = [];
      this.visibleAuthors = this.authors;
    }
    this.regulationList.loadRegulations();
  }

  filterByThemes() {
    const selectedThemes = this.themes.filter((theme) => theme.selected);
    this.router.navigate([], {
      queryParams: { theme: selectedThemes.map((theme) => theme.id) },
      queryParamsHandling: 'merge',
    });
    if (selectedThemes.length > 0) {
      this.regulationsQueryParams.temas = selectedThemes.map(
        (theme) => theme.id
      );
    } else {
      this.regulationsQueryParams.temas = [];
    }
    this.regulationList.loadRegulations();
  }

  filterByAuthors() {
    const selectedAuthors = this.authors.filter((author) => author.selected);
    this.router.navigate([], {
      queryParams: { author: selectedAuthors.map((author) => author.id) },
      queryParamsHandling: 'merge',
    });
    if (selectedAuthors.length > 0) {
      this.regulationsQueryParams.autores = selectedAuthors.map(
        (author) => author.id
      );
    } else {
      this.regulationsQueryParams.autores = [];
    }
    this.regulationList.loadRegulations();
  }

  filterByImpacto() {
    const impacto =
      this.impacto === 'Alta'
        ? 1
        : this.impacto === 'Media'
        ? 2
        : this.impacto === 'Baja'
        ? 3
        : undefined;
    this.router.navigate([], {
      queryParams: { impacto },
      queryParamsHandling: 'merge',
    });
    this.regulationsQueryParams.impacto = impacto;
    this.regulationList.loadRegulations();
  }

  filterByProbabilidad() {
    const probabilidad =
      this.probabilidad === 'Alta'
        ? 1
        : this.probabilidad === 'Media'
        ? 2
        : this.probabilidad === 'Baja'
        ? 3
        : undefined;
    this.router.navigate([], {
      queryParams: { probabilidad },
      queryParamsHandling: 'merge',
    });
    this.regulationsQueryParams.probabilidadSancion = probabilidad;
    this.regulationList.loadRegulations();
  }
}
