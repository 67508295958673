import { HttpClient } from "@angular/common/http"
import { ApiService } from "./api.service"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { map, switchMap } from "rxjs/operators"
import { environment } from "src/environments/environment"


@Injectable({
    providedIn: "root"
})
export class LoginService {
    constructor(
      private httpClient: HttpClient,
      ) { }

    private url = environment.backendUrl + "api/Auth"

    login(email: string, password: string): Observable<IProfile> {
      localStorage.removeItem('user');
      localStorage.removeItem('access_token');
        return this.httpClient.post<IDashLogin>(this.url, { email, password }).pipe(
          switchMap((response) => {
            localStorage.setItem('access_token', response.access_token);
            return this.getProfile().pipe(
              map((profile) => {
                localStorage.setItem('user', JSON.stringify({ ...profile, clienteId: response.clienteId }));
                return { ...profile, clienteId: response.clienteId }
              })
            );
          })
        );
    }

    getProfile(): Observable<IProfile> {
      return this.httpClient.get<IProfile>(`${environment.backendUrl}api/Usuarios/Profile`)
    }

    forgotPassword(email: string): Observable<any> {
      return this.httpClient.post(`${environment.authUrl}auth/request-reset-password`, {
        email,
        redirectUrl: `https://d3.directoriolegislativo.org/#/reset-password`
      });
    }

    resetPassword(token: string, password: string): Observable<any> {
      return this.httpClient.post(`${environment.authUrl}auth/reset-password`, {
        token,
        password
      });
    }


}

interface IDashLogin {
  access_token: string;
  clienteId: number;
}

interface IProfile {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
}
