
  <div
  [ngClass]="{'col-md-6 filter-container pt-2 pb-4': mobile, 'filter-container py-4': !mobile}"
  >
  <h4 class="filter-title pb-3">
    {{ title }}
  </h4>
    <ng-container *ngIf="loading; else loaded">
      <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
    </ng-container>
    <ng-template #loaded>
      <div>
        <mat-form-field appearance="outline" class="w-100">
            <input matInput placeholder="Buscar..." [(ngModel)]="searchbar" (ngModelChange)="searchbarChanged()">
            <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
      <div *ngIf="filteredOptions.length === 0" class="info-content pb-2">
        No se encontraron resultados
      </div>
      <div class="scrollable filter-container">
        <ng-container *ngFor="let option of filteredOptions">
          <mat-checkbox class="info-content pb-2"
          color="primary"
          [(ngModel)]="option.selected"
          (ngModelChange)="filter()"
          >
          {{option.name}}
          </mat-checkbox>
        </ng-container>
      </div>
      <div *ngIf="filteredOptions.length < options.length && !searchbar">
        <p class="info-content pb-2 clickable" (click)="filteredOptions = options">
          Ver todos
        </p>
      </div>
      <div *ngIf="filteredOptions.length === options.length && options.length > 25">
        <p class="info-content pb-2 clickable" (click)="filteredOptions = options.slice(0, 25)">
          Ver menos
        </p>
      </div>
    </ng-template>
  </div>

