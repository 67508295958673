import { RegulationType } from "../enums/regulation-type.enum";
import { Regulacion } from "../models/regulacion.model";

export function getData(news: any): Regulacion {
  return {
    ...news,
    type: RegulationType.NOTAS,
    fechaIngreso: news.fecha,
  }
}
