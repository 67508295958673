import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/core/services/api.service';
import { LoginService } from 'src/app/core/services/login.service';

const USERS_URL = 'Usuarios';
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {
  isEditingPassword = false;
  parsedUser: any;
  isSubmiting: boolean = false;

  form = this.formBuilder.group({
    id: [''],
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    password: ['', []],
    repeatPassword: ['', []],
  });

  constructor(
    private loginService: LoginService,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    const user = localStorage.getItem('user');
    if (user) {
      this.parsedUser = JSON.parse(user);
      this.form.patchValue(this.parsedUser);
      this.form.get('id')?.setValue(this.parsedUser.userId);
    }
  }

  submit() {
    this.isSubmiting = true;
    if (this.form.invalid) {
      this.isSubmiting = false;
      this.form.markAllAsTouched();
      return;
    }
    // REMOVE EMPTY VALUES
    if (!this.form.value.password) {
      delete this.form.value.password;
      delete this.form.value.repeatPassword;
    }
    this.apiService
      .put(USERS_URL + '/' + this.form.value.id, {
        nombre: this.form.value.firstName,
        apellido: this.form.value.lastName,
        ...this.form.value,
      })
      .subscribe((user: any) => {
        this.isSubmiting = false;
        const storedUserString = localStorage.getItem('user');
        if (storedUserString) {
          const storedUser = JSON.parse(storedUserString);

          if (storedUser) {
            storedUser.firstName = user.nombre;
            storedUser.lastName = user.apellido;
            storedUser.email = user.email;

            localStorage.setItem('user', JSON.stringify(storedUser));
          }
        }

        this.router.navigate(['/']);
      });
  }

  toggleEditPassword() {
    const pwControl = this.form.get('password');
    const rpControl = this.form.get('repeatPassword');
    if (this.isEditingPassword) {
      pwControl?.setValue('');
      rpControl?.setValue('');
      pwControl?.removeValidators([Validators.required]);
      pwControl?.updateValueAndValidity();
      rpControl?.removeValidators([Validators.required]);
      rpControl?.updateValueAndValidity();
      this.isEditingPassword = false;
    } else {
      pwControl?.setValidators([
        Validators.minLength(6),
        Validators.maxLength(20),
        Validators.required,
      ]);
      pwControl?.updateValueAndValidity();
      rpControl?.setValidators([this.checkPassword, Validators.required]);
      rpControl?.updateValueAndValidity();
      this.isEditingPassword = true;
    }
  }

  checkPassword(control: AbstractControl): ValidationErrors | null {
    if (control.parent) {
      const password = control.parent.get('password');
      if (password) {
        return password.value === control.value ? null : { notEqual: true };
      }
    }
    return null;
  }
}
