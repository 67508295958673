
export interface SubtypeWhitelist {
  title: string;
  values: string[];
}

export const anteproyectosStates = [
  {
    title: "En elaboración",
    values: ["En consulta pública", "En proceso de elaboración"],
  },
  {
    title: "En condiciones de ser publicado",
    values: ["En condiciones de publicarse en el Boletín Oficial", "En condiciones de ser enviado al Congreso"],
  },
];

export const proyectosStates = [
  {
    title: "En debate",
    values: [
      "En consulta pública",
      "Sin comisión asignada en cámara de origen",
      "Sin comisión asignada en cámara revisora",
      "Sin tratamiento en comisión en cámara de origen",
      "Sin tratamiento en comisión en cámara revisora",
      "En estudio en comisión de cámara revisora",
      "En estudio en comisión de cámara origen",
      "En condiciones de ser tratado en el recinto de la Cámara de Origen",
      "En condiciones de ser tratado en el recinto de la Cámara Revisora",
      "Rechazado en el recinto de la Cámara de Origen",
      "Rechazado en el recinto de la Cámara Revisora",
      "Devuelto a comisión de Cámara de Origen",
      "Devuelto a comisión de Cámara Revisora",
      "En condiciones de ser tratado en el recinto",
      "Devuelto a Comisión",
      "Con media sanción",
    ],
  },
  {
    title: "Archivado",
    values: ["Archivado", "Inconstitucional", "Retirado por el autor",],
  },
  {
    title: "Sancionado",
    values: ["Sancionado"],
  },
];
