import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { MapComponent } from './components/map/map.component';
import { NewsComponent } from './components/news/news.component';

import { HomeComponent } from './views/home/home.component';
import { ShowComponent } from './views/show/show.component';
import { AlteredDatePipe } from './core/pipes/date.pipe';
import { LoginComponent } from './views/login/login.component';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { UserComponent } from './views/user/user.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { SearchModule } from './views/search/search.module';
import { SharedModule } from './shared.module';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    LoginComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    FooterComponent,
    MapComponent,
    ShowComponent,
    UserComponent,
    NewsComponent,
    AlteredDatePipe
  ],
  imports: [
    SharedModule,
    SearchModule,
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
