import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { getData } from '../adapter/news.adapter';
import { RegulationType } from '../enums/regulation-type.enum';
import { EntityQueryParams } from '../models/query-params.model';
import { RegulacionData } from '../models/regulacion-data.model';
import { Regulacion } from '../models/regulacion.model';
import { Subtype } from '../models/subtype.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class RegulationsService {
  anteproyectosUrl = 'AnteProyectos/';
  proyectosUrl = 'Proyectos/';
  normativasUrl = 'Normativas/';
  leyesUrl = 'Leyes/';
  notasUrl = 'notas/';

  constructor(private apiService: ApiService) {}

  fetchById(
    regulationType: RegulationType,
    id: string
  ): Observable<Regulacion> {
    switch (regulationType.toString()) {
      case RegulationType.ANTEPROYECTOS.toString():
        return this.apiService.get(this.anteproyectosUrl + id);
      case RegulationType.PROYECTOS.toString():
        return this.apiService.get(this.proyectosUrl + id);
      case RegulationType.LEYES.toString():
        return this.apiService.get(this.leyesUrl + id);
      case RegulationType.NORMATIVAS.toString():
        return this.apiService.get(this.normativasUrl + id);
      case RegulationType.NOTAS.toString():
        return this.apiService
          .get(this.notasUrl + id)
          .pipe(map((nota) => getData(nota)));
      default:
        return this.apiService.get(this.anteproyectosUrl + id);
    }
  }

  fetchAllByType(regulationType: RegulationType): Observable<Regulacion[]> {
    switch (regulationType) {
      case RegulationType.ANTEPROYECTOS:
        return this.apiService.get(this.anteproyectosUrl);
      case RegulationType.PROYECTOS:
        return this.apiService.get(this.proyectosUrl);
      case RegulationType.LEYES:
        return this.apiService.get(this.leyesUrl);
      case RegulationType.NORMATIVAS:
        return this.apiService.get(this.normativasUrl);
      case RegulationType.NOTAS:
        return this.apiService.get(this.notasUrl);
      default:
        return this.apiService.get(this.anteproyectosUrl);
    }
  }

  fetchAll(query?: EntityQueryParams) {
    let params = new HttpParams();
    if (query) {
      params = params.append('sortBy', query.sortBy ?? 'CreatedAt');
      params = params.append('sortOrder', query.sortOrder ?? 'desc');
      params = params.append('pageSize', query.pageSize ?? 5);
      params = params.append('pageNumber', query.pageNumber ?? 1);
      params = params.append('csg', query.csg ?? false);
      for (const pais of query.paises ?? []) {
        params = params.append('paises', pais.toString());
      }
      for (const autor of query.autores ?? []) {
        params = params.append('autores', autor.toString());
      }
      for (const tema of query.temas ?? []) {
        params = params.append('temas', tema.toString());
      }
      for (const subtype of query.subtypes ?? []) {
        params = params.append('EstadosTipos', subtype.toString());
      }
      if (query.impacto) params = params.append('impactoTipoId', query.impacto);
      if (query.probabilidadSancion)
        params = params.append(
          'probabilidadSancionId',
          query.probabilidadSancion
        );
      if (query.title) params = params.append('Titulo', query.title);
      if (query.keyword) params = params.append('KeyWord', query.keyword);
    }
    let anteproyectos: Observable<RegulacionData> = of({} as RegulacionData);
    if (query?.types?.includes(RegulationType.ANTEPROYECTOS)) {
      anteproyectos = this.apiService
        .get<RegulacionData>(this.anteproyectosUrl.replace('/', ''), { params })
        .pipe(
          map((anteproyectos) => {
            if (anteproyectos) {
              anteproyectos.items = anteproyectos.items.map((anteproyecto) => {
                anteproyecto.type = RegulationType.ANTEPROYECTOS;
                return anteproyecto;
              });
            }
            return anteproyectos ?? { items: [], totalCount: 0 };
          })
        );
    }

    let proyectos: Observable<RegulacionData> = of({} as RegulacionData);
    if (query?.types?.includes(RegulationType.PROYECTOS)) {
      proyectos = this.apiService
        .get<RegulacionData>(this.proyectosUrl.replace('/', ''), { params })
        .pipe(
          map((proyectos) => {
            if (proyectos) {
              proyectos.items = proyectos.items.map((proyecto) => {
                proyecto.type = RegulationType.PROYECTOS;
                return proyecto;
              });
            }
            return proyectos ?? { items: [], totalCount: 0 };
          })
        );
    }

    let leyes: Observable<RegulacionData> = of({} as RegulacionData);
    if (
      query?.types?.includes(RegulationType.LEYES) &&
      (query?.autores == undefined || query.autores.length == 0)
    ) {
      leyes = this.apiService
        .get<RegulacionData>(this.leyesUrl.replace('/', ''), { params })
        .pipe(
          map((leyes) => {
            if (leyes) {
              leyes.items = leyes.items.map((ley) => {
                ley.type = RegulationType.LEYES;
                return ley;
              });
            }
            return leyes ?? { items: [], totalCount: 0 };
          })
        );
    }

    let normativas: Observable<RegulacionData> = of({} as RegulacionData);
    if (
      query?.types?.includes(RegulationType.NORMATIVAS) &&
      (query?.autores == undefined || query.autores.length == 0)
    ) {
      normativas = this.apiService
        .get<RegulacionData>(this.normativasUrl.replace('/', ''), { params })
        .pipe(
          map((normativas) => {
            if (normativas) {
              normativas.items = normativas.items.map((normativa) => {
                normativa.type = RegulationType.NORMATIVAS;
                return normativa;
              });
            }
            return normativas ?? { items: [], totalCount: 0 };
          })
        );
    }

    let notas: Observable<RegulacionData> = of({} as RegulacionData);
    if (
      query?.types?.includes(RegulationType.NOTAS) &&
      (query?.autores == undefined || query.autores.length == 0)
    ) {
      notas = this.apiService
        .get<RegulacionData>(this.notasUrl.replace('/', ''), { params })
        .pipe(
          map((notas) => {
            if (notas) {
              notas.items = notas.items.map((nota) => {
                nota.type = RegulationType.NOTAS;
                return nota;
              });
            }
            return notas ?? { items: [], totalCount: 0 };
          })
        );
    }
    return forkJoin([anteproyectos, proyectos, leyes, normativas, notas]).pipe(
      map((results: RegulacionData[]) => {
        const count = results.reduce(
          (acc, val) => acc + (val.totalCount ? val.totalCount : 0),
          0
        );
        const items = results.reduce((acc: Regulacion[], val) => {
          if (val.items) {
            return acc.concat(val.items);
          }
          return acc;
        }, []);
        return { items, totalCount: count };
      })
    );
  }

  fetchEstadosTipos(regulationType: RegulationType) {
    let queryParam = new HttpParams();
    switch (regulationType) {
      case RegulationType.ANTEPROYECTOS:
        queryParam = queryParam.append('isAnteProyecto', 'true');
        break;
      case RegulationType.PROYECTOS:
        queryParam = queryParam.append('isProyecto', 'true');
        break;
      case RegulationType.LEYES:
        queryParam = queryParam.append('isLey', 'true');
        break;
      case RegulationType.NORMATIVAS:
        queryParam = queryParam.append('isNormativa', 'true');
        break;
      case RegulationType.NOTAS:
        queryParam = queryParam.append('isNota', 'true');
        break;
    }
    return this.apiService.get<Subtype[]>('EstadosTipos', {
      params: queryParam,
    });
  }
}
