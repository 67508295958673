<ng-container *ngIf="regulation">
  <div class="container">
    <div class="row py-4">
      <div class="col-lg-9 pb-5 pr-1">
        <h3 class="link">{{ type }}</h3>
        <span class="date">
          {{ regulation.fechaIngreso | date }}
        </span>
        <h3 class="link">{{ regulation.pais }}</h3>
        <h1 class="title">{{ regulation.titulo }}</h1>
        <div class="content" [innerHtml]="regulation.sintesis"></div>
        <div class="content" [innerHtml]="regulation.contenido"></div>
        <div *ngIf="regulation?.expedienteNumero">
          <div class="row pt-3">
            <h5 class="info-content p-0">Expediente</h5>
          </div>
          <div class="row pt-2">
            <a
              *ngIf="regulation?.expedienteUrl"
              mat-button
              class="link-button"
              target="_blank"
              [href]="regulation.expedienteUrl"
            >
              <mat-icon>link</mat-icon>
              {{ regulation.expedienteNumero }}
            </a>
            <a
              *ngIf="!regulation?.expedienteUrl"
              mat-button
              class="link-button"
              target="_blank"
            >
              {{ regulation.expedienteNumero }}
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="info-container" *ngIf="regulation.temas.length > 0">
          <span class="info-title">TEMAS RELACIONADOS</span>
          <div
            class="row pt-2"
            *ngFor="let tema of filterThemesByRelated(regulation?.temas || [])"
          >
            <a routerLink="/search" [queryParams]="{ theme: tema.id }" mat-button class="theme-button">{{ tema.descripcionTema }}</a>
          </div>
          <hr class="button-hr" />
        </div>
        <div class="info-container" *ngIf="regulation.origen">
          <span class="info-title">ORIGEN</span>
          <h5 class="info-content py-2 pb-0">{{ regulation.origen }}</h5>
          <hr />
        </div>
        <!-- solo si es proyecto de ley -->
        <div class="info-container" *ngIf="regulation.comisiones?.length > 0">
          <span class="info-title">COMISIONES</span>
          <h5
            class="info-content py-2 pb-0"
            *ngFor="let comision of regulation?.comisiones"
          >
            {{ comision.descripcionComision }}
          </h5>
          <hr />
        </div>
        <div class="info-container" *ngIf="regulation.autores">
          <span class="info-title">AUTOR</span>
          <div *ngFor="let autor of regulation.autores">
            <h5 class="info-content py-2 pb-0">
              {{ autor.nombreApellido }}
              <ng-container *ngIf="autor.bloque && autor.posicion == null">
                ({{ autor.bloque.descripcionBloque }})
              </ng-container>
              <ng-container *ngIf="autor.bloque && autor.posicion !== null">
                ({{ autor.bloque.descripcionBloque }} -
                {{ autor.posicion === 0 ? "Oficialismo" : "Oposición" }})
              </ng-container>
              <ng-container *ngIf="!autor.bloque && autor.posicion !== null">
                ({{ autor.posicion === 0 ? "Oficialismo" : "Oposición" }})
              </ng-container>
            </h5>
          </div>
          <hr />
        </div>
        <div class="info-container" *ngIf="regulation.estado">
          <span class="info-title">ESTADO</span>
          <h5 class="info-content py-2 pb-0">{{ regulation.estado }}</h5>
          <hr />
        </div>
        <div class="info-container" *ngIf="regulation?.impacto">
          <span class="info-title">IMPACTO EN EL SECTOR</span>
          <h5 class="info-content py-2 pb-0" [class]="regulation.impacto">
            {{ regulation.impacto }}
          </h5>
          <hr />
        </div>
        <div class="info-container" *ngIf="regulation?.probabilidadSancion">
          <span class="info-title">PROBABILIDAD DE SANCIÓN</span>
          <h5
            class="info-content py-2 pb-0"
            [class]="regulation.probabilidadSancion"
          >
            {{ regulation.probabilidadSancion }}
          </h5>
          <hr />
        </div>
        <div
          class="info-container"
          *ngIf="
            regulation.apRelacionados && regulation.apRelacionados.length > 0
          "
        >
          <span class="info-title">ANTEPROYECTOS RELACIONADOS</span>
          <div
            class="row pt-2"
            *ngFor="let anteproyecto of regulation.apRelacionados"
          >
            <a
              mat-button
              class="theme-button"
              [routerLink]="['/show', 0, anteproyecto.id]"
              >{{ anteproyecto?.titulo }}</a
            >
          </div>
          <hr class="button-hr" />
        </div>
        <div
          class="info-container"
          *ngIf="
            regulation.proyectosRelacionados &&
            regulation.proyectosRelacionados.length > 0
          "
        >
          <span class="info-title">PROYECTOS RELACIONADOS</span>
          <div
            class="row pt-2"
            *ngFor="let proyecto of regulation.proyectosRelacionados"
          >
            <a
              mat-button
              class="theme-button"
              [routerLink]="['/show', 1, proyecto.id]"
              >{{ proyecto.titulo }}</a
            >
          </div>
          <hr class="button-hr" />
        </div>
        <div
          class="info-container"
          *ngIf="
            regulation.leyesRelacionadas &&
            regulation.leyesRelacionadas.length > 0
          "
        >
          <span class="info-title">LEYES RELACIONADAS</span>
          <div
            class="row pt-2"
            *ngFor="let ley of regulation.leyesRelacionadas"
          >
            <a
              mat-button
              class="theme-button"
              [routerLink]="['/show', 3, ley.id]"
              >{{ ley.titulo }}</a
            >
          </div>
          <hr class="button-hr" />
        </div>
        <div
          class="info-container"
          *ngIf="
            regulation.normativasRelacionadas &&
            regulation.normativasRelacionadas.length > 0
          "
        >
          <span class="info-title">NORMATIVAS RELACIONADAS</span>
          <div
            class="row pt-2"
            *ngFor="let normativa of regulation.normativasRelacionadas"
          >
            <a
              mat-button
              class="theme-button"
              [routerLink]="['/show', 2, normativa.id]"
              >{{ normativa.titulo }}</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="py-5">
      <mat-stepper orientation="vertical" #stepper>
        <mat-step [label]="movement.estado" *ngFor="let movement of movements">
          <ng-template matStepLabel>
            <p class="date">{{ movement.fechaMovimiento | date }}</p>
            <span class="movement-title">{{ movement.estado }}</span>
          </ng-template>
          <div class="pt-4">
            <p class="movement-description">{{ movement.descripcion }}</p>
            <a
              mat-button
              class="theme-button"
              target="_blank"
              *ngIf="movement.link"
              [href]="movement.link"
              >Ver más</a
            >
          </div>
        </mat-step>
      </mat-stepper>
    </div>
  </div>

  <app-news
    *ngIf="
      regulation.notasRelacionadas && regulation.notasRelacionadas.length > 0
    "
    [related]="true"
    [news]="regulation.notasRelacionadas"
  ></app-news>
</ng-container>
