import { RegulationType } from '../enums/regulation-type.enum';

export class EntityQueryParams {
  title?: string;
  paises?: number[];
  autores?: number[];
  temas?: number[];
  impacto?: number;
  probabilidadSancion?: number;
  sortBy?: string;
  sortOrder?: 'asc' | 'desc';
  keyword?: string;
  pageSize: number;
  pageNumber: number;
  types: RegulationType[];
  subtypes?: number[];
  csg?: boolean;

  constructor() {
    this.pageNumber = 1;
    this.pageSize = 5;
    this.sortBy = 'CreatedAt';
    this.types = [
      RegulationType.ANTEPROYECTOS,
      RegulationType.PROYECTOS,
      RegulationType.LEYES,
      RegulationType.NORMATIVAS,
      RegulationType.NOTAS,
    ];
  }
}
